import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { Observable } from 'rxjs';
import { hasDescUrba } from 'src/app/core/store/order/order.selectors';
import * as PRODUCTS from '../../core/data/products';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-desc-urba-checkbox',
  templateUrl: './desc-urba-checkbox.component.html',
  styleUrls: ['./desc-urba-checkbox.component.scss']
})
export class DescUrbaCheckboxComponent implements OnInit {
  hasDescUrba$: Observable<boolean>;

  constructor(private dataService: DataService, private store: Store<AppState>) {}

  ngOnInit() {
    this.hasDescUrba$ = this.store.select(hasDescUrba);
  }

  descUrba() {
    this.dataService.setService(PRODUCTS.DESCRIPTIF_URBA);
  }
}
