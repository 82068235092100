<div *ngIf="style === 'success'" class="container success">
  <img src="../../../assets/images/dialog-success-icon.svg" alt="" />
  <h5>
    Félicitations !
  </h5>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua. <b>Ut enim ad minim veniam</b>, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
  <button class="btn">Continuer</button>
</div>

<div *ngIf="style === 'error'" class="container error">
  <img src="../../../assets/images/dialog-error-icon.svg" alt="" />
  <h5>
    {{ 'default-dialog.dialog-title' | translate }}
  </h5>
  <p>
    {{ 'default-dialog.dialog-content' | translate }}
  </p>
  <p>
    <strong>{{ 'default-dialog.dialog-content-end' | translate }}</strong>
  </p>
  <button (click)="toOrder()" class="btn">{{ 'default-dialog.continue-btn' | translate }}</button>
</div>

<div *ngIf="style === 'info'" class="container info">
  <img src="../../../assets/images/dialog-info-icon.svg" alt="" />
  <h5>
    Le saviez-vous ?
  </h5>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua. <b>Ut enim ad minim veniam</b>, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
  <button class="btn">Continuer</button>
</div>

<div *ngIf="style === 'warning'" class="container warning">
  <img src="../../../assets/images/dialog-warning-icon.svg" alt="" />
  <h5>
    Attention
  </h5>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua. <b>Ut enim ad minim veniam</b>, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
  <button class="btn">Continuer</button>
</div>
