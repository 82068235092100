import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-congrats-dialog',
  templateUrl: './congrats-dialog.component.html',
  styleUrls: ['./congrats-dialog.component.scss']
})
export class CongratsDialogComponent implements OnInit {
  onlinePayment: boolean;
  orderid: string;
  dialog: MatDialog;
  agenda = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { onlinePayment: boolean; orderid: string; dialog: MatDialog; displayAgenda: boolean },
    private router: Router
  ) {}

  ngOnInit() {
    this.onlinePayment = this.data.onlinePayment;
    this.dialog = this.data.dialog;
    this.orderid = this.data.orderid;
    this.agenda = this.data.displayAgenda;
  }

  toAgenda() {
    this.dialog.closeAll();
    this.router.navigate(['/checkout/agenda', this.orderid]);
  }

  toCheckout() {
    this.dialog.closeAll();
    this.router.navigate(['/checkout', this.orderid]);
  }

  toClientArea() {
    this.dialog.closeAll();
    this.router.navigate(['/customer']);
  }

  closeModal() {
    this.dialog.closeAll();
    this.router.navigate(['']);
  }
}
