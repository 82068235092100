export const LegalForm = [
  {
    name:"Other",
    value:"Other"
  },
  {
    name:"SA",
    value:"SA"
  },
  {
    name:"SRL",
    value:"SRL"
  },
  {
    name:"SPRLU",
    value:"SPRLU"
  },
  {
    name:"SCRL",
    value:"SCRL"
  },
  {
    name:"SCRI",
    value:"SCRI"
  },
  {
    name:"SNC",
    value:"SNC"
  },
  {
    name:"SCS",
    value:"SCS"
  },
  {
    name:"SCA",
    value:"SCA"
  },
  {
    name:"ASBL",
    value:"ASBL"
  },
  {
    name:"AISBL",
    value:"AISBL"
  },
  {
    name:"GIE",
    value:"GIE"
  },
  {
    name:"PersonPhysic",
    value:"PersonPhysic"
  },
  {
    name:"SARL",
    value:"SARL"
  },
]


