import { UserType } from '../models/user';

export const newUserTypes = [
  {
    id: UserType.Particulier,
    txt: 'Particulier',
    fileName: 'user-particulier'
  },
  {
    id: UserType.Agence,
    txt: 'Agence',
    fileName: 'user-agence'
  },
  {
    id: UserType.Notaire,
    txt: 'Notaire',
    fileName: 'user-notaire'
  },
  {
    id: UserType.Installateur,
    txt: 'Installateur',
    fileName: 'user-installateur'
  }
];

export const userTypePartAndPro = [
  {
    id: -1,
    txt: 'Sélectionnez un type de client',
    fileName: 'user-type'
  },
  {
    id: UserType.Particulier,
    txt: 'New',
    fileName: 'user-type'
  },
  {
    id: UserType.Notaire,
    txt: 'Already client',
    fileName: 'user-particulier'
  },
  {
    id: UserType.Agence,
    txt: 'PRO',
    fileName: 'user-agence'
  },
  /* {
    id: UserType.Installateur,
    txt: 'Installateur',
    fileName: 'user-installateur'
  } */
];

export const userTypePartAndNotary = [
  {
    id: -1,
    txt: 'Sélectionnez un type de client',
    fileName: 'user-type'
  },
  {
    id: UserType.Particulier,
    txt: 'Particulier',
    fileName: 'user-particulier'
  },
  {
    id: UserType.Notaire,
    txt: 'Notaire',
    fileName: 'user-professionnel'
  }
];

export const userTypePartAndNotaryLiege = [
  {
    id: -1,
    txt: 'Sélectionnez un type de client',
    fileName: 'user-type'
  },
  {
    id: UserType.Notaire,
    txt: 'Notaire',
    fileName: 'user-professionnel'
  }
];