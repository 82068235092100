<div class="white" *ngIf="profile">
  <ng-template class="profileRep italic" #empty>
    <span class="profileRep italic">{{ 'profile.nc' | translate }}</span>
  </ng-template>
  <div class="row spaceRow">
    <span class="col-md-6 col-12 mainTitle"> {{ 'profile.companyDetails' | translate }} </span>
    <div class="col-md-6 col-12 edit">
      <button (click)="editInfo()" class="btn btn-secondary">{{ 'profile.edit' | translate }}</button>
    </div>
  </div>
  <div class="row spaceRow pr">
    <div class="col-lg-3 col-md-6 col-12 pb-15">
      <span class="profileSubtitle">{{ 'profile.form' | translate }}</span>
      <br /><span *ngIf="profile.LegalForm; else empty" class="profileRep"> {{ profile.LegalForm }}</span>
    </div>
    <div class="col-lg-3 col-md-6 col-12 pb-15 ">
      <span class="profileSubtitle">{{ 'profile.companyName' | translate }}</span
      ><br />
      <span *ngIf="profile.CommercialName; else empty" class="profileRep">{{ profile.CommercialName }} </span>
    </div>
    <div class="col-lg-3 col-md-6 col-12 pb-15">
      <span class="profileSubtitle">{{ 'profile.companyTVA' | translate }}</span
      ><br />
      <span *ngIf="profile.VatNumber; else empty" class="profileRep">{{ profile.VatNumber }} </span>
    </div>
    <div class="col-lg-3 col-md-6 col-12 pb-15">
      <span class="profileSubtitle">{{ 'profile.companyWebsite' | translate }}</span
      ><br />
      <span *ngIf="profile.Website; else empty" class="profileRep">{{ profile.Website }}</span>
    </div>

    <div class="col-lg-3 col-md-6 col-12 pt-15">
      <span class="profileSubtitle">{{ 'profile.companyEmail' | translate }}</span
      ><br />
      <span *ngIf="profile.MainUser.Email; else empty" style="word-break:break-word;"   class="profileRep">{{ profile.MainUser.Email }}</span>
    </div>
    <div class="col-lg-3 col-md-6 col-12 pt-15">
      <span class="profileSubtitle">{{ 'profile.companyPhone' | translate }}</span
      ><br />
      <span *ngIf="profile.MainUser.TelNumber; else empty" class="profileRep">{{ profile.MainUser.TelNumber }}</span>
    </div>
    <div class="col-lg-3 col-md-6 col-12 pt-15">
      <span class="profileSubtitle">{{ 'profile.companyGsm' | translate }}</span
      ><br />
      <span *ngIf="profile.MainUser.PhoneNumber; else empty" class="profileRep">{{
        profile.MainUser.PhoneNumber
      }}</span>
    </div>
  </div>
</div>
