<div *ngIf="profile" class="profile-particulier">
  <div class="content">
    <app-profile-pro-main [profile]="profile"></app-profile-pro-main>
    <app-add-adresses [profile]="profile"></app-add-adresses>
    <app-profile-pro-contacts [profile]="profile"></app-profile-pro-contacts>
    <!-- <app-profile-others [profile]="profile"></app-profile-others> -->
    <app-profile-password></app-profile-password>
    <app-profile-status *ngIf="profile.Status" [profile]="profile"></app-profile-status>
  </div>
</div>
