export enum OrderStatus {
  All = 0,
  Attente = 1,
  Planifier = 2,
  Execute = 3,
  Annule = 4,
  Suspens = 5,
  AttenteFichiers = 6,
  AnnuleFacture = 7,
  Paid = 8,
  NotPaid = 9,
  BDCSigned = 10,
  BdcNotSigned = 30,
  OngoingOrders = 31,
  AllMissingFiles = 32,
  ExecutePastMonth = 33,
  NotPaidWithInvoice = 34,
  NotPaidWithCallback = 35,
  AllThisYear = 36,
  NewOrderPastMonth = 37,
  LatestOrders = 38
}
