export const headerMenu = {
  ['fr']: [
    {
      text: 'Contrôle électrique',
      link: 'https://www.certinergie.be/fr/controle-electrique/'
    },
    {
      text: 'PEB',
      link: 'https://www.certinergie.be/fr/certification-peb/'
    },
    {
      text: 'Citerne',
      link: 'https://www.certinergie.be/fr/controle-citerne-mazout/'
    },
    {
      text: 'Gaz',
      link: 'https://www.certinergie.be/fr/controle-gaz/'
    },
    {
      text: 'Offres combinées',
      link: 'https://www.certinergie.be/fr/offre-combinee-peb-electricite/'
    },
    {
      text: 'Audit',
      link: 'https://www.certinergie.be/fr/audit-logement-wallonie/'
    },
    {
      text: 'Tous nos services',
      link: 'https://www.certinergie.be/fr/controles-legaux-et-services/'
    },
    {
      text: 'FAQ',
      link: 'https://www.certinergie.be/fr/faq/peb/'
    }
  ],
  ['nl']: [
    {
      text: 'EPC certificaat',
      link: 'https://www.certinergie.be/nl/epc-certificaat/'
    },
    {
      text: 'Elektrische keuring',
      link: 'https://www.certinergie.be/nl/elektrische-keuring/'
    },
    {
      text: 'Combi - keuring',
      link: 'https://www.certinergie.be/nl/combi-keuring/'
    },
    {
      text: 'Stookolietank keuring',
      link: 'https://www.certinergie.be/nl/stookoliekeuring/'
    },
    {
      text: 'FAQ',
      link: 'https://www.certinergie.be/nl/veelgestelde-vragen/epc/'
    }
  ],
  ['en']: [
    {
      text: 'Energy Performance Certificate',
      link: 'https://www.certinergie.be/en/energy-performance-certificate/'
    },
    {
      text: 'Electricity inspection',
      link: 'https://www.certinergie.be/en/electricity-inspection/'
    },
    {
      text: 'Fuel oil tank check',
      link: 'https://www.certinergie.be/en/fuel-oil-tank-check/'
    }
  ],
  ['de']: [
    {
      text: 'Energiepass',
      link: 'https://www.certinergie.be/de/energiepass/'
    },
    {
      text: 'Prüfung Elektoinstallation',
      link: 'https://www.certinergie.be/de/prufung-elektoinstallation/'
    }
  ],
  ['fr-desktop']: [
    {
      text: 'Contrôle électrique',
      link: 'https://www.certinergie.be/fr/controle-electrique/'
    },
    {
      text: 'PEB',
      link: 'https://www.certinergie.be/fr/certification-peb/'
    },
    {
      text: 'Citerne',
      link: 'https://www.certinergie.be/fr/controle-citerne-mazout/'
    },
    {
      text: 'Gaz',
      link: 'https://www.certinergie.be/fr/controle-gaz/'
    },
    {
      text: 'Offres combinées',
      link: 'https://www.certinergie.be/fr/offre-combinee-peb-electricite/'
    },
    {
      text: 'Audit',
      link: 'https://www.certinergie.be/fr/audit-logement-wallonie/'
    },
    {
      text: 'Tous nos services',
      link: 'https://www.certinergie.be/fr/controles-legaux-et-services/'
    },
    {
      text: 'FAQ',
      link: 'https://www.certinergie.be/fr/faq/peb/'
    }
  ],
  ['nl-desktop']: [
    {
      text: 'EPC certificaat',
      link: 'https://www.certinergie.be/nl/epc-certificaat/'
    },
    {
      text: 'Elektrische keuring',
      link: 'https://www.certinergie.be/nl/elektrische-keuring/'
    },
    {
      text: 'Combi - keuring',
      link: 'https://www.certinergie.be/nl/combi-keuring/'
    },
    {
      text: 'Stookolietank keuring',
      link: 'https://www.certinergie.be/nl/stookoliekeuring/'
    },
    {
      text: 'FAQ',
      link: 'https://www.certinergie.be/nl/veelgestelde-vragen/epc/'
    }
  ],
  ['en-desktop']: [
    {
      text: 'Energy Performance Certificate',
      link: 'https://www.certinergie.be/en/energy-performance-certificate/'
    },
    {
      text: 'Electricity inspection',
      link: 'https://www.certinergie.be/en/electricity-inspection/'
    },
    {
      text: 'Fuel oil tank check',
      link: 'https://www.certinergie.be/en/fuel-oil-tank-check/'
    }
  ],
  ['de-desktop']: [
    {
      text: 'Energiepass',
      link: 'https://www.certinergie.be/de/energiepass/'
    },
    {
      text: 'Prüfung Elektoinstallation',
      link: 'https://www.certinergie.be/de/prufung-elektoinstallation/'
    }
  ]
};

export const contactLinks = {
  ['fr']: 'https://www.certinergie.be/fr/contact/',
  ['nl']: 'https://www.certinergie.be/nl/contact-en-afspraak/',
  ['en']: 'https://www.certinergie.be/en/contact-and-appointment/',
  ['de']: 'https://www.certinergie.be/en/contact-and-appointment/'
};
