import { Component, OnInit , Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {AddAdressDialogComponent} from "../add-adress-dialog/add-adress-dialog.component"
import { DataService } from '../../../core/services/data.service';
import {DeleteAdressComponent} from '../delete-adress/delete-adress.component'
import {EditAdressDialogComponent} from '../edit-adress-dialog/edit-adress-dialog.component'

@Component({
  selector: 'app-add-adresses',
  templateUrl: './add-adresses.component.html',
  styleUrls: ['./add-adresses.component.scss']
})
export class AddAdressesComponent implements OnInit {
  constructor(public dialog: MatDialog,private dataService: DataService) {}
  @Input() profile;
  ngOnInit(): void {
  }

  addNewAdress() {
    this.dialog.open(AddAdressDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog }
    });
  }
  editAdress(adress , id) {
    this.dialog.open(EditAdressDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog , profile : this.profile , adress : adress , id : id}
    });
  }
  deleteAdress(id){
    this.dialog.open(DeleteAdressComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog , id : id}
    });
}
}
