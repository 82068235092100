export const projectID: 'default' | 'greenfish' | 'hainaut' | 'namur' | 'nvn' | 'liege' = 'nvn';

export const hasImmoAccessProject = false;
export const hasClientTypeChoice = true;
export const hasAccessAllPack = false;

export const displayHeaderMenu = false;
export const displayHelpSection = false;

export const step1TitleImmo = true;
export const step1SubTitle = true;
export const step2HideDateButoir = false;
export const headerShowPhone = false;
export const showBigFooter = false;
export const showFinalOptions = false;
export const step4ShowByMail = true;

export const displayRegionSelection = false;
export const defaultRegion: -1 | 0 | 1 | 2 = 1;

export const filterNewUserTypes = [1689, 1691];

export const getPricesID = 6;

export const projectIDs = {
  packRoleID: '841152d8-f7dd-42d1-a73c-12ce919b2b48'
};

export const forceConnection = true;

export const forceInvoiceToPro = true;
export const hasSplitInvoice = false;
export const themeColor = '#91b7cb';
export const displayAskForExpertise = false;
