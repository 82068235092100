import { Component, OnInit, Inject } from '@angular/core';
import { Print } from 'src/app/core/models/print';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { SetParutions } from 'src/app/core/store/order';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { getParutions } from 'src/app/core/store/order/order.selectors';
import * as PRODUCTS from '../../core/data/products';
import { ProductDB } from 'src/app/core/models/productDB';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-print-fullpress-request-dialog',
  templateUrl: './print-fullpress-request-dialog.component.html',
  styleUrls: ['./print-fullpress-request-dialog.component.scss']
})
export class PrintFullpressRequestDialogComponent implements OnInit {
  currentParu: Print;
  tomorrow = new Date();

  nbOptions = [1, 2, 3, 4, 5, 6, 7, 8];

  fullPressPrint: Print = {
    id: PRODUCTS.FullPress,
    name: 'Forfait Full presse',
    needPhoto: false,
    date: null,
    day: 1,
    price: this.getPubPrice(PRODUCTS.FullPress).price,
    format: '',
    commission: this.getPubPrice(PRODUCTS.FullPress).commission,
    nbOfWeeks: 1
  };

  paruList: Print[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.store.select(getParutions).subscribe(p => {
      this.paruList = p;
    });
  }

  filterOneDay(d: Date) {
    const day = d.getDay();
    return day === 1;
  }

  cancel() {
    const fullPress = this.paruList.find(p => p.id === PRODUCTS.FullPress);
    if (fullPress) {
      const list = [...this.paruList].filter(p => p.id !== PRODUCTS.FullPress);
      this.store.dispatch(new SetParutions(list));
    }
    this.data.dialog.closeAll();
  }

  saveFullPress() {
    if (!!this.fullPressPrint.date) {
      this.paruList = [...this.paruList.filter(p => p.id !== PRODUCTS.FullPress), this.fullPressPrint];
      this.store.dispatch(new SetParutions(this.paruList));
      this.data.dialog.closeAll();
    }
  }

  getPubDB(id: number): ProductDB {
    const product = this.dataService.products.find(pr => pr.ProductType === id);
    return product;
  }

  getPubPrice(id: number) {
    return { price: this.getPubDB(id).Price, commission: this.getPubDB(id).Commission };
  }
}
