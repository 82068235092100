import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  stats$: BehaviorSubject<any>;
  constructor(private dataService: DataService) { }
  language = ""


  ngOnInit(): void {
    this.dataService.getStats();
    this.stats$ = this.dataService.stats$
    this.language = this.dataService.getLangue()
  }
}
