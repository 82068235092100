import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { SetVenteInfo } from 'src/app/core/store/order';
import { VenteInfo } from 'src/app/core/models/venteInfo';
import * as orderSelector from '../../core/store/order/order.selectors';
import { ventePubChoices, venteBidditChoices } from 'src/app/core/models/venteChoices';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-vente-type-info',
  templateUrl: './vente-type-info.component.html',
  styleUrls: ['./vente-type-info.component.scss']
})
export class VenteTypeInfoComponent implements OnInit {
  cadastreFG: FormGroup;
  venteFG: FormGroup;

  $venteInfoSub: Subscription;
  $cadastreSub: Subscription;

  ventePubFG: FormGroup;
  venteGreGreFG: FormGroup;
  venteBidditFG: FormGroup;

  ventePubSub$: Subscription;
  venteGreSub$: Subscription;
  venteBidditSub$: Subscription;

  minDate = new Date();
  comment: string;
  references: string;

  @Input()
  venteType$: Observable<number>;
  venteInfo$: Observable<VenteInfo>;

  venteInfo: VenteInfo;

  ventePubChoices = ventePubChoices;
  venteBidditChoices = venteBidditChoices;

  @ViewChild('flatpickr1', { static: false }) flatpickr1: any;
  @ViewChild('flatpickr2', { static: false }) flatpickr2: any;
  @ViewChild('flatpickr3', { static: false }) flatpickr3: any;
  @ViewChild('flatpickr4', { static: false }) flatpickr4: any;
  @ViewChild('flatpickr5', { static: false }) flatpickr5: any;
  @ViewChild('flatpickr6', { static: false }) flatpickr6: any;

  constructor(private store: Store<AppState>, private _formBuilder: FormBuilder, private currencyPipe: CurrencyPipe) {
    this.venteInfo$ = this.store.select(orderSelector.getVenteInfo);
    this.venteInfo$.subscribe(v => {
      this.venteInfo = v;
      this.comment = this.venteInfo.comment;
      this.references = this.venteInfo.references;
    });
    this.cadastreFG = this._formBuilder.group({
      division: [{ value: this.venteInfo.division, disabled: false }],
      section: [{ value: this.venteInfo.section, disabled: false }],
      numCadastre: [{ value: this.venteInfo.numCadastre, disabled: false }],
      rcni: [{ value: this.venteInfo.rcni, disabled: false }]
    });
    this.venteFG = this._formBuilder.group({
      street: [{ value: this.venteInfo.street, disabled: false }],
      num: [{ value: this.venteInfo.num, disabled: false }],
      city: [{ value: this.venteInfo.city, disabled: false }],
      zip: [{ value: this.venteInfo.zip, disabled: false }]
    });

    this.$cadastreSub = this.cadastreFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = { ...this.venteInfo };
      this.venteInfo.division = values.division;
      this.venteInfo.section = values.section;
      this.venteInfo.numCadastre = values.numCadastre;
      this.venteInfo.rcni = values.rcni;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });
    this.$venteInfoSub = this.venteFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = { ...this.venteInfo };
      this.venteInfo.street = values.street;
      this.venteInfo.num = values.num;
      this.venteInfo.city = values.city;
      this.venteInfo.zip = values.zip;
      /* this.venteInfo.statusVentePub = values.venteStatut;
      this.venteInfo.venteDate = values.venteDate; */

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.ventePubFG = this._formBuilder.group({
      venteStatut: [
        this.ventePubChoices[this.venteInfo.ventePub.venteStatut ? this.venteInfo.ventePub.venteStatut.id : 0]
      ],
      venteDate: [this.venteInfo.ventePub.date_DateTime],
      ventePrice: [this.venteInfo.ventePub.startPrice],
      references: [this.venteInfo.references]
    });

    this.venteGreGreFG = this._formBuilder.group({
      venteOffre: [this.venteInfo.greAgre.askedPrice],
      references: [this.venteInfo.references]
    });

    this.venteBidditFG = this._formBuilder.group({
      venteForced: [],
      venteStatut: [
        this.venteBidditChoices[this.venteInfo.biddit.venteStatut ? this.venteInfo.biddit.venteStatut.id : 0]
      ],
      venteStartDate: [this.venteInfo.biddit.startDate_DateTime],
      venteEndDate: [this.venteInfo.biddit.endDate_DateTime],
      adjuDate: [this.venteInfo.biddit.adjuDate_DateTime],
      infoDate: [this.venteInfo.biddit.infoDate_DateTime],
      ventePrice: [this.venteInfo.biddit.miseAPrix],
      venteMinEnch: [this.venteInfo.biddit.minPrice],
      references: [this.venteInfo.references]
    });
  }

  ngOnInit() {
    this.ventePubSub$ = this.ventePubFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = JSON.parse(JSON.stringify(this.venteInfo));
      this.venteInfo.ventePub.venteStatut = values.venteStatut;
      this.venteInfo.ventePub.date_DateTime = values.venteDate && new Date(values.venteDate);
      this.venteInfo.ventePub.startPrice = values.ventePrice;
      this.venteInfo.references = values.references;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.venteGreSub$ = this.venteGreGreFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = JSON.parse(JSON.stringify(this.venteInfo));
      this.venteInfo.greAgre.askedPrice = values.venteOffre;
      this.venteInfo.references = values.references;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });

    this.venteBidditSub$ = this.venteBidditFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.venteInfo = JSON.parse(JSON.stringify(this.venteInfo));
      this.venteInfo.biddit.venteForced = values.venteForced;
      this.venteInfo.biddit.venteStatut = values.venteStatut;
      this.venteInfo.biddit.venteDate_DateTime = values.venteDate && new Date(values.venteDate);
      this.venteInfo.biddit.startDate_DateTime = values.venteStartDate && new Date(values.venteStartDate);
      this.venteInfo.biddit.endDate_DateTime = values.venteEndDate && new Date(values.venteEndDate);
      this.venteInfo.biddit.adjuDate_DateTime = values.adjuDate && new Date(values.adjuDate);
      this.venteInfo.biddit.infoDate_DateTime = values.infoDate && new Date(values.infoDate);
      this.venteInfo.biddit.miseAPrix = +values.ventePrice;
      this.venteInfo.biddit.minPrice = +values.venteMinEnch;
      this.venteInfo.references = values.references;

      this.store.dispatch(new SetVenteInfo(this.venteInfo));
    });
  }

  Open(id: number) {
    if (id === 1) {
      return this.flatpickr1.nativeElement._flatpickr.open();
    }
    if (id === 2) {
      return this.flatpickr2.nativeElement._flatpickr.open();
    }
    if (id === 3) {
      return this.flatpickr3.nativeElement._flatpickr.open();
    }
    if (id === 4) {
      return this.flatpickr4.nativeElement._flatpickr.open();
    }
    if (id === 5) {
      return this.flatpickr5.nativeElement._flatpickr.open();
    }
    if (id === 6) {
      return this.flatpickr6.nativeElement._flatpickr.open();
    }
  }

  SetComment() {
    const res = { ...this.venteInfo, comment: this.comment };
    this.store.dispatch(new SetVenteInfo(res));
  }

  SetReferences() {
    const res = { ...this.venteInfo, references: this.references };
    this.store.dispatch(new SetVenteInfo(res));
  }

  transformAmount(element) {
    if(!isNaN(element.target.value)) {
      const formattedAmount = this.currencyPipe.transform(element.target.value, '€');
      element.target.value = formattedAmount;
    }
  }
}
