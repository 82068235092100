import { ActionReducerMap, ActionReducer, MetaReducer, Action } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { AppState as State } from './interfaces';

import * as fromOrder from './core/store/order/order.reducer';
import * as fromUI from './core/store/ui/ui.reducer';

const STORE_KEYS_TO_PERSIST = ['order', 'ui'];

export const reducers: ActionReducerMap<State> = {
  order: fromOrder.reducer,
  ui: fromUI.reducer
};

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return localStorageSync({
    keys: STORE_KEYS_TO_PERSIST,
    rehydrate: true
  })(reducer);
}

export const metaReducers: Array<MetaReducer<State, Action>> = [localStorageSyncReducer];
