import { Component, OnInit , Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-profile-upgrade-status',
  templateUrl: './profile-upgrade-status.component.html',
  styleUrls: ['./profile-upgrade-status.component.scss']
})
export class ProfileUpgradeStatusComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }

}
