import { LoggerService } from 'src/app/core/services/logger.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Visit } from 'src/app/core/models/visit';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { SetVisits, SetVisitContact, SetVisitAdValoris } from 'src/app/core/store/order';
import { getVisits, getVisitContact } from 'src/app/core/store/order/order.selectors';
import { debounceTime } from 'rxjs/operators';
import { VisitAdValoris } from 'src/app/core/models/visitAdValoris';
import * as PRODUCTS from 'src/app/core/data/products';
import { projectID } from 'src/app/core/data/theme-config';
import { VENTES } from 'src/app/core/enums/vente.enum';

@Component({
  selector: 'app-visit-request-dialog',
  templateUrl: './visit-request-dialog.component.html',
  styleUrls: ['./visit-request-dialog.component.scss']
})
export class VisitRequestDialogComponent implements OnInit {
  visitEtudeExtended = false;
  visitFix = false;
  visitFree = false;
  visitAdValoris = true;
  showAddVisit = false;
  editing = false;

  currentVisit;
  selectedChoice = 0;
  reason = -1;

  projectID = projectID;
  infoFormGroup: FormGroup;

  gilVPnBid: VisitAdValoris = {
    forfaits: [
      {
        id: PRODUCTS.VisitGil4S,
        description: 'Visites 4 semaines avant la séance de vente',
        active: false,
        devis: false,
        price: 550 * 1.21
      },
      {
        id: PRODUCTS.VisitGil6S,
        description: 'Visites 6 semaines avant la séance de vente',
        active: false,
        devis: false,
        price: 800 * 1.21
      }
    ],
    options: [
      {
        id: PRODUCTS.VisitGil2Times_Bid,
        description: '2 Visites période enchère ',
        active: false,
        devis: false,
        price: 200 * 1.21
      },
      {
        id: PRODUCTS.VisitGilBoundless_Bid,
        description: 'Visites illimitées pendant période d’enchère',
        active: false,
        devis: false,
        price: 400 * 1.21
      }
    ]
  };

  gilGG: VisitAdValoris = {
    forfaits: [
      {
        id: PRODUCTS.VisitGilBoundless_3M,
        description: 'Forfait illimité de visites pendant 3 mois',
        active: false,
        devis: false,
        price: 550 * 1.21
      }
    ],
    options: []
  };

  adValoris: VisitAdValoris = {
    forfaits: [
      {
        id: PRODUCTS.VISITADVALORIS,
        description: 'Ad Valoris',
        active: false,
        devis: false,
        price: 500 * 1.21
      }
    ],
    options: [
      {
        id: PRODUCTS.LOCKSMITH,
        description: 'Ouverture forcée',
        active: false,
        devis: false,
        price: 199 * 1.21
      },
      {
        id: 2,
        description: 'Mise en valeur',
        active: false,
        devis: true,
        price: 0
      }
    ]
  };

  visitList: Visit[] = [];

  options = [
    { hour: 0 },
    { hour: 9 },
    { hour: 10 },
    { hour: 11 },
    { hour: 12 },
    { hour: 13 },
    { hour: 14 },
    { hour: 15 },
    { hour: 16 },
    { hour: 17 }
  ];

  defaultVisit = {
    hour: this.options[0].hour,
    allDay: false,
    date: null
  };

  visitFreeFG: FormGroup;
  tomorrow = new Date();

  visitFreeInfo = {
    lastName: '',
    firstName: '',
    email: '',
    tel: ''
  };

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; reason: number },
    private store: Store<AppState>,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.currentVisit = { ...this.defaultVisit };
    this.store.select(getVisits).subscribe(v => (this.visitList = v));
    this.store.select(getVisitContact).subscribe(v => (this.visitFreeInfo = v));

    this.visitFreeFG = this._formBuilder.group({
      lastName: [{ value: this.visitFreeInfo.lastName, disabled: false }],
      firstName: [{ value: this.visitFreeInfo.firstName, disabled: false }],
      email: [{ value: this.visitFreeInfo.email, disabled: false }],
      tel: [{ value: this.visitFreeInfo.tel, disabled: false }]
    });

    this.visitFreeFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.visitFreeInfo.lastName = values.lastName;
      this.visitFreeInfo.firstName = values.firstName;
      this.visitFreeInfo.email = values.email;
      this.visitFreeInfo.tel = values.tel;
    });

    this.infoFormGroup = this._formBuilder.group({
      instructions: [
        {
          value: '',
          disabled: false
        }
      ]
    });

    this.reason = this.data.reason;
  }

  setHour(index) {
    this.currentVisit.hour = this.options[index].hour;
  }

  displayAddVisit() {
    this.showAddVisit = true;
    this.currentVisit = { ...this.defaultVisit };
  }

  /* addVisit() {
    if (this.currentVisit.date) {
      this.visitList = [...this.visitList];
      this.visitList.push(this.currentVisit);
      this.showAddVisit = false;
    }
  } */

  editVisit(index) {
    this.editing = true;
    this.showAddVisit = true;
    this.currentVisit = this.visitList[index];
    this.selectedChoice = this.options.indexOf(this.options.find(p => p.hour === this.currentVisit.hour));
  }

  saveEditVisit() {
    this.editing = false;
    this.showAddVisit = false;
  }

  deleteVisit(index) {
    this.visitList = [...this.visitList];
    this.visitList.splice(index, 1);
  }

  saveVisits() {
    this.store.dispatch(new SetVisits(this.visitList));
    this.close();
  }

  saveVisitFree() {
    if (
      this.visitFreeInfo.email !== '' &&
      this.visitFreeInfo.firstName !== '' &&
      this.visitFreeInfo.lastName !== '' &&
      this.visitFreeInfo.tel !== ''
    ) {
      this.visitFree = false;
      this.store.dispatch(new SetVisitContact(this.visitFreeInfo));
      this.close();
    }
  }

  AddVisit() {
    const adV = { ...this.adValoris };
    adV.forfaits[0].active = true;
    adV.instructions = this.infoFormGroup.value.instructions;
    this.logger.log(adV);
    this.store.dispatch(new SetVisitAdValoris(adV));
    this.close();
  }

  AddVisitGil() {
    const adV = this.reason === VENTES.GRE_A_GRE ? { ...this.gilGG } : { ...this.gilVPnBid };
    if (this.reason === 1) {
      adV.forfaits[0].active = true;
    }
    adV.instructions = this.infoFormGroup.value.instructions;
    this.logger.log(adV);
    this.store.dispatch(new SetVisitAdValoris(adV));
    this.close();
  }

  close() {
    this.data.dialog.closeAll();
  }

  cancel() {
    const adV = { ...this.adValoris };
    adV.forfaits.map(f => (f.active = false));
    adV.options.map(f => (f.active = false));
    this.store.dispatch(new SetVisitAdValoris(adV));
    this.close();
  }

  cancelGil() {
    const adV = this.reason === 1 ? { ...this.gilGG } : { ...this.gilVPnBid };
    adV.forfaits.map(f => (f.active = false));
    adV.options.map(f => (f.active = false));
    this.store.dispatch(new SetVisitAdValoris(adV));
    this.close();
  }

  forfait1Click() {
    this.gilVPnBid.forfaits[1].active = false;
  }

  forfait2Click() {
    this.gilVPnBid.forfaits[0].active = false;
  }

  option1Click() {
    this.gilVPnBid.options[1].active = false;
  }

  option2Click() {
    this.gilVPnBid.options[0].active = false;
  }

  get totalPrice() {
    let pr = 0;
    if (this.reason === 1) {
      this.gilGG.forfaits.forEach(f => {
        if (f.active) {
          pr += f.price;
        }
      });
      this.gilGG.options.forEach(f => {
        if (f.active) {
          pr += f.price;
        }
      });
    } else {
      this.gilVPnBid.forfaits.forEach(f => {
        if (f.active) {
          pr += f.price;
        }
      });
      this.gilVPnBid.options.forEach(f => {
        if (f.active) {
          pr += f.price;
        }
      });
    }

    return pr > 0 ? pr / 1.21 : 0;
  }
}
