<div class="add-adress">
  <h4 class="modalTitle break">{{'profile.editAdress' | translate}}</h4>
  <span id="close" (click)="this.data.dialog.closeAll()">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3941 3.90878C12.2097 3.72432 11.9106 3.72432 11.7262 3.90878L8.72021 6.91473L5.71427 3.90878C5.52981 3.72433 5.23075 3.72433 5.04629 3.90878L4.37832 4.57676C4.19387 4.76121 4.19387 5.06027 4.37832 5.24473L7.38426 8.25067L4.37846 11.2565C4.19401 11.4409 4.19401 11.74 4.37846 11.9244L5.04644 12.5924C5.23089 12.7769 5.52995 12.7769 5.71441 12.5924L8.72021 9.58661L11.726 12.5924C11.9105 12.7769 12.2095 12.7769 12.394 12.5924L13.062 11.9244C13.2464 11.74 13.2464 11.4409 13.062 11.2565L10.0562 8.25067L13.0621 5.24472C13.2466 5.06027 13.2466 4.7612 13.0621 4.57675L12.3941 3.90878Z" fill="#6C788D"/>
    </svg>
    </span>
  <form class="content" [formGroup]="newAdress">
  <span class="modalSubtitle col-12">{{'profile.adressType' | translate}}</span>
  <mat-form-field appearance="outline" class="col-12 formField">
    <mat-label>{{'profile.adressType' | translate}}</mat-label>
    <mat-select formControlName="Type">
      <mat-option *ngFor="let type of adressType" [value]="type.value">
          {{ 'profile.type'+type.name | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

<!-- ////////// pas de parametre AdressType dans les adresses a rajouter en back-end!!! -->
<div class="row">
  <div class="col-md-6 col-12">
    <span class="modalSubtitle col-12">{{'profile.adressCity' | translate}}</span>
    <mat-form-field appearance="outline" class="col-12 formField">
      <mat-label>{{'profile.adressCity' | translate}}</mat-label>
      <input matInput readonly required  formControlName="City" type="text" />
    </mat-form-field>
  </div>
  <div class="col-md-6 col-12">
    <span class="modalSubtitle col-12 ">{{'profile.adressPostalCode' | translate}}</span>
    <mat-form-field appearance="outline" class="col-12 formField">
      <mat-label>{{'profile.adressPostalCode' | translate}}</mat-label>
      <input matInput readonly required  formControlName="PostalCode" type="text" />
    </mat-form-field>
  </div>
</div>
  <div class="row">
    <div class="col-md-6 col-12">
      <span class="modalSubtitle col-12">{{'profile.adressStreet' | translate}}</span>
      <mat-form-field appearance="outline" class="col-12 formField">
        <mat-label>{{'profile.adressStreet' | translate}}</mat-label>
        <input matInput readonly required formControlName="Street" type="text" />
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <span class="modalSubtitle col-12">{{'profile.adressNumber' | translate}}</span>
      <mat-form-field appearance="outline" class="col-12 formField">
        <mat-label>{{'profile.adressNumber' | translate}}</mat-label>
        <input matInput readonly required formControlName="Num" type="text" />
      </mat-form-field>
    </div>
  </div>

  <span class="modalSubtitle col-md-6 col-12">{{'profile.adressBoxNumber' | translate}}</span>
    <mat-form-field appearance="outline" class="col-12 formField">
      <mat-label>{{'profile.adressBoxNumber' | translate}}</mat-label>
      <input matInput readonly  formControlName="BoxNumber" type="text" />
    </mat-form-field>
  <span class="modalSubtitle col-12">{{'profile.adressName' | translate}}</span>

    <mat-form-field appearance="outline" class=" col-12 formField">
      <mat-label>{{'profile.adressName' | translate}}</mat-label>
      <input matInput required formControlName="Name" type="text" />
    </mat-form-field>
    <div class="row checkbox">
      <div class="col-md-6 col-12 cent">
        <span class="col-9">{{'profile.addressReport' | translate}}</span>
        <mat-checkbox class="col-3" [checked]="!this.data.adress.NotReceiveReport" formControlName="NotReceiveReport" class="example-margin"></mat-checkbox>
      </div>
      <div class="col-md-6 col-12 cent">
        <span style="word-break:break-all"  class="col-9" >{{'profile.addressInvoice' | translate}}</span>
        <mat-checkbox class="col-3" [checked]="!this.data.adress.NotReceiveInvoice" formControlName="NotReceiveInvoice" class="example-margin"></mat-checkbox>
      </div>
    </div>

    <mat-error id="errorMsg" *ngIf="displayError  && newAdress.status!='VALID'">{{this.errorContent}}</mat-error>
    <div class="flex">
    <button class="btn btn-secondary" type="submit" (click)="editAdress()"> {{'profile.editAdress' | translate}}</button>
  </div>
  </form>
  </div>

