<div class="step-content">
  <section>
    <h6>
      {{ 'step3.title' | translate }}
      <app-tooltip [tooltipPosition]="'bottom'" [content]="'step3.title-tooltip' | translate"></app-tooltip>
    </h6>
    <div class="adress-container">
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprio' }"
        (click)="SelectInvoice('toProprio')"
        *ngIf="($isLoggedIn | async) && !forceToPro"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner.firstname }} {{ owner.lastname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ address.street }} {{ address.number }},
            {{ address.zip }} {{ address.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprioAndPro' }"
        (click)="SelectInvoice('toProprioAndPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum.Notaire && !forceToPro"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner-co-notary' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner.firstname }} {{ owner.lastname }} C/o
            {{ user.lastname }}
            {{ user.firstname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user.address.street }} {{ user.address.number }},
            {{ user.address.zip }}
            {{ user.address.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toPro' }"
        (click)="SelectInvoice('toPro')"
        *ngIf="($isLoggedIn | async) && ( userType === UserTypeEnum.Notaire || userType === UserTypeEnum.Installateur ) || forceToPro"
      >
        <header>
          <h6 class="subtitle">{{ (userType === UserTypeEnum.Installateur ? 'step3.to-insta' : 'step3.to-notary') | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ user.lastname }} {{ user.firstname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user.address.street }} {{ user.address.number }},
            {{ user.address.zip }}
            {{ user.address.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprioAndPro' }"
        (click)="SelectInvoice('toProprioAndPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum.Agence"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner-co-agency' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner.firstname }} {{ owner.lastname }} C/o
            {{ user.lastname }}
            {{ user.firstname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user.address.street }} {{ user.address.number }},
            {{ user.address.zip }}
            {{ user.address.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toPro' }"
        (click)="SelectInvoice('toPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum.Agence"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-agency' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ user.lastname }} {{ user.firstname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user.address.street }} {{ user.address.number }},
            {{ user.address.zip }}
            {{ user.address.city }}
          </p>
        </div>
      </button>
      <button
        *ngIf="!($isLoggedIn | async) && !forceToPro"
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprio' }"
        (click)="SelectInvoice('toProprio')"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ userForm.firstname }} {{ userForm.lastname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ address.street }} {{ address.number }},
            {{ address.zip }} {{ address.city }}
          </p>
        </div>
      </button>
      <button
        *ngIf="!forceToPro"
        [ngClass]="{ btn: true, 'show-form': true, selected: invoiceTo == 'toOther' }"
        (click)="SelectInvoice('toOther')"
      >
        <fa-icon icon="user-plus"></fa-icon>
        {{ 'step3.other-address.other-address' | translate }}
      </button>
    </div>
    <form class="other-adress-form" *ngIf="invoiceTo == 'toOther'" [formGroup]="otherDocFormGroup">
      <h6>{{ 'step3.other-address.title' | translate }}</h6>
      <mat-form-field appearance="outline" class="google-adress">
        <mat-label>{{ 'inputs.google-map-search' | translate }}</mat-label>
        <input
          matGoogleMapsAutocomplete
          [country]="['be']"
          [types]="['address']"
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          matInput
          type="text"
          placeholder="{{ 'inputs.google-map-search' | translate }}..."
        />
      </mat-form-field>
      <div class="form-container">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
          <input matInput placeholder="Jane" formControlName="firstName" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
          <input matInput placeholder="Doe" formControlName="lastName" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
          <input matInput placeholder="Rue de l'exemple" formControlName="street" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
          <input matInput placeholder="1" formControlName="number" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
          <input matInput placeholder="Code postal" formControlName="zip" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
          <input matInput placeholder="Exemple ville" formControlName="city" required />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'section-user.disco.tva' | translate }}</mat-label>
          <input matInput placeholder="Exemple tva" formControlName="tva" />
        </mat-form-field>
      </div>
    </form>
    <div class="owner-advice" *ngIf="userType === UserTypeEnum.Agence && invoiceTo == 'toProprio'">
      <fa-icon icon="exclamation-circle"></fa-icon>
      <p class="caption">
        {{ 'step3.owner-advice' | translate }}
      </p>
    </div>
  </section>
  <section>
    <app-split-invoice [showDetails]="false"></app-split-invoice>
  </section>
</div>
<div class="step-actions">
  <button class="btn btn-previous" matStepperPrevious>
    {{ 'navigation.previous' | translate }}
  </button>
  <button class="btn-primary btn-next" matStepperNext>
    {{ 'navigation.next' | translate }}
  </button>
</div>
