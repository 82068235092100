import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss']
})
export class DefaultDialogComponent implements OnInit {
  dialog: MatDialog;
  style: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; style: string },
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.style = this.data.style;
    this.dialog = this.data.dialog;
  }

  toOrder() {
    this.dialog.closeAll();
    this.authService.logout();
    // tslint:disable-next-line: deprecation
    location.reload(true);
  }

  closeModal() {
    this.dialog.closeAll();
    this.router.navigate(['']);
  }
}
