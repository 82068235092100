import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var gtag: Function;

export class PurchaseEventParams {
  constructor(
    public transaction_id: string,
    public affiliation: string,
    public value: number,
    public currency: string,
    public items: Partial<PurchaseEventItemParams>[]
  ) {}
}

export class PurchaseEventItemParams {
  constructor(
    public id: string,
    public name: string,
    public list_name: string,
    public brand: string,
    public category: string,
    public variant: string,
    public list_position: number,
    public quantity: number,
    public price: string
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      try {
        if (typeof gtag === 'function') {
          if (event instanceof NavigationEnd) {
            gtag('config', environment.GA_MEASUREMENT_ID, { page_path: event.urlAfterRedirects });
          }
        }
      } catch (e) {
        // console.log(e);
      }
    });
  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * this.emitEvent('bouton', 'clic', 'étape 4 valider')
   * this.emitEvent('purchase', { event_action: eventAction, event_category: eventCategory, event_label: eventLabel, value: eventValue })
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  emit<T>(eventName: string, eventParameters: T) {
    if (typeof gtag === 'function') {
      gtag('event', eventName, eventParameters);
    }
  }

  emitEvent(eventName: string, eventParameters: { eventCategory: string; eventLabel: string; eventValue?: number }) {
    this.emit(eventName, {
      event_category: eventParameters.eventCategory,
      event_label: eventParameters.eventLabel,
      value: eventParameters.eventValue
    });
  }

  emitPurchase(eventParameters: Partial<PurchaseEventParams>) {
    this.emit('purchase', eventParameters);
  }

  emitClick(eventParameters: { eventCategory: string; eventLabel: string; eventValue?: number }) {
    this.emitEvent('click', eventParameters);
  }

  emitConversion(conversionID: string, value: number, uniqueID: string) {
    this.emit('conversion', { send_to: conversionID, value: value, currency: 'EUR', orderID: uniqueID });
    // (<any>window).dataLayer.push({ orderID: uniqueID });
  }

  emitConversionOrderFinalized(value: number, uniqueID: string) {
    this.emitConversion('867734457/VtmkCLrA4q8BELmn4p0D', value, uniqueID);
  }
}
