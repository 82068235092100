import { ActionTypes, Actions } from './ui.actions';

export interface IUI {
  showpacks: boolean;
  isMobile: boolean;
  isTablette: boolean;
  language: 'fr' | 'nl' | 'de' | 'en';
  step1IsValid: boolean;
  step2IsValid: boolean;
  step3IsValid: boolean;
  step4IsValid: boolean;
  iAgree: boolean;
  packsWithPrices: any;
  packsWithPricesNoFilt: any;
  isOnline: boolean;
  resetStepper: boolean;
  showMoreServices: boolean;
  loginError: boolean;
}

const initialUIState: IUI = {
  showpacks: false,
  isMobile: false,
  isTablette: false,
  language: 'fr',
  step1IsValid: false,
  step2IsValid: false,
  step3IsValid: false,
  step4IsValid: false,
  iAgree: false,
  packsWithPrices: {},
  packsWithPricesNoFilt: {},
  isOnline: navigator.onLine,
  resetStepper: false,
  showMoreServices: false,
  loginError: false
};

export function reducer(state: IUI = initialUIState, action: Actions): IUI {
  switch (action.type) {
    case ActionTypes.SET_SHOWPACKS:
      return { ...state, showpacks: action.payload as boolean };

    case ActionTypes.SET_ISMOBILE:
      return { ...state, isMobile: action.payload as boolean };

    case ActionTypes.SET_ISTABLETTE:
      return { ...state, isTablette: action.payload as boolean };

    case ActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload as 'fr' | 'nl' | 'de' | 'en' };

    case ActionTypes.SET_STEP1VALID:
      return { ...state, step1IsValid: action.payload as boolean };

    case ActionTypes.SET_STEP2VALID:
      return { ...state, step2IsValid: action.payload as boolean };

    case ActionTypes.SET_STEP3VALID:
      return { ...state, step3IsValid: action.payload as boolean };

    case ActionTypes.SET_STEP4VALID:
      return { ...state, step4IsValid: action.payload as boolean };

    case ActionTypes.SET_IAGREE:
      return { ...state, iAgree: !state.iAgree as boolean };

    case ActionTypes.SET_PACKSWITHPRICES:
      return { ...state, packsWithPrices: action.payload };

    case ActionTypes.SET_PACKSWITHPRICES_NOFILT:
      return { ...state, packsWithPricesNoFilt: action.payload };

    case ActionTypes.SET_ONLINE:
      return handleIsOnline(state, action);

    case ActionTypes.SET_RESETSTEPPER:
      return { ...state, resetStepper: action.payload as boolean };

    case ActionTypes.RESET_UI:
      return { ...state, showpacks: false, iAgree: false, packsWithPrices: {} };

    case ActionTypes.SHOWMORESERVICES:
      return { ...state, showMoreServices: action.payload };

    case ActionTypes.SET_LOGIN_ERROR:
      return { ...state, loginError: action.payload };

    default:
      return { ...state };
  }
}

function handleIsOnline(state: IUI, action: Actions): IUI {
  return {
    ...state,
    isOnline: action.payload
  };
}
