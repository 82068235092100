import { Component, OnInit } from '@angular/core';
import { ToggleUrgencePro } from 'src/app/core/store/order';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getUrgencePro } from 'src/app/core/store/order/order.selectors';

@Component({
  selector: 'app-urgence-pro',
  templateUrl: './urgence-pro.component.html',
  styleUrls: ['./urgence-pro.component.scss']
})
export class UrgenceProComponent implements OnInit {
  urgencePro$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.urgencePro$ = this.store.select(getUrgencePro);
  }

  urgencePro(checked: boolean) {
    this.store.dispatch(new ToggleUrgencePro(checked));
  }
}
