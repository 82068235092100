import { createSelector } from '@ngrx/store';

import { AppState } from '../../../interfaces';
import { IUI } from './ui.reducer';

export const getUI = (state: AppState): IUI => state.ui;

// *** PUBLIC API's ***
export const getShowPacks = createSelector(
  getUI,
  (ui: IUI) => ui.showpacks
);
export const getIsMobile = createSelector(
  getUI,
  (ui: IUI) => ui.isMobile
);
export const getIsTablette = createSelector(
  getUI,
  (ui: IUI) => ui.isTablette
);
export const getLanguage = createSelector(
  getUI,
  (ui: IUI) => ui.language
);
export const getStep1IsValid = createSelector(
  getUI,
  (ui: IUI) => ui.step1IsValid
);
export const getStep2IsValid = createSelector(
  getUI,
  (ui: IUI) => ui.step2IsValid
);
export const getStep3IsValid = createSelector(
  getUI,
  (ui: IUI) => ui.step3IsValid
);
export const getStep4IsValid = createSelector(
  getUI,
  (ui: IUI) => ui.step4IsValid
);
export const getIAgree = createSelector(
  getUI,
  (ui: IUI) => ui.iAgree
);
export const getPacksWithPrices = createSelector(
  getUI,
  (ui: IUI) => ui.packsWithPrices
);
export const getPacksWithPricesNoFilt = createSelector(
  getUI,
  (ui: IUI) => ui.packsWithPricesNoFilt
);
export const getOnline = createSelector(
  getUI,
  (ui: IUI) => ui.isOnline
);
export const getResetStepper = createSelector(
  getUI,
  (ui: IUI) => ui.resetStepper
);
export const getShowMoreServices = createSelector(
  getUI,
  (ui: IUI) => ui.showMoreServices
);
export const getLoginError = createSelector(
  getUI,
  (ui: IUI) => ui.loginError
);
