import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comissions-dashboard',
  templateUrl: './comissions-dashboard.component.html',
  styleUrls: ['./comissions-dashboard.component.scss']
})
export class ComissionsDashboardComponent implements OnInit {
  @ViewChild('unPaid', { static: true }) unPaid: ElementRef;
  @ViewChild('comission', { static: true }) comission: ElementRef;
  @ViewChild('segmented', { static: true }) segmented: ElementRef;
  @ViewChild('table', { static: true }) table: ElementRef;
  @Input() comissions;
  activeTab = 'unPaid';
  activeFilter = 'table';
  constructor() { }

  ngOnInit(): void {
  }
  setTab(arg) {
    if (arg !== this.activeTab) {
      if (arg == "unPaid") {
        this[this.activeTab].nativeElement.classList.remove('tab-active')
        this[this.activeTab].nativeElement.classList.remove('green')
        this.activeTab = arg;
        this[arg].nativeElement.classList.add('tab-active');
      } else {
        this[this.activeTab].nativeElement.classList.remove('tab-active')
        this.activeTab = arg;
        this[arg].nativeElement.classList.add('tab-active');
        this[arg].nativeElement.classList.add('green');
      }
    }
  }
  setFilter(arg) {
    if (arg !== this.activeFilter) {
      this[this.activeFilter].nativeElement.classList.remove('filter-active')
      this[this.activeFilter].nativeElement.classList.add('filter-not-active')
      this.activeFilter = arg;
      this[arg].nativeElement.classList.add('filter-active');
      this[arg].nativeElement.classList.remove('filter-not-active');
    }
  }
  print() {
    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    style.appendChild(document.createTextNode(css));


    head.appendChild(style);

    this.setTab('comission')
    setTimeout(() => {
      window.print();
    }, 2000);

  }

}
