export const regions = [
  {
    id: -1,
    name: 'Sélectionnez une région',
    fileName: 'be'
  },
  {
    id: 0,
    name: 'Région wallonne',
    fileName: 'be-wal'
  },
  {
    id: 1,
    name: 'Région bruxelloise',
    fileName: 'be-bxl'
  },
  {
    id: 2,
    name: 'Région flamande',
    fileName: 'be-vl'
  }
];
