import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { services, immoServices } from 'src/app/core/data';
import { LoggerService } from 'src/app/core/services/logger.service';

@Component({
  selector: 'app-summary-confirm-dialog',
  templateUrl: './summary-confirm-dialog.component.html',
  styleUrls: ['./summary-confirm-dialog.component.scss']
})
export class SummaryConfirmDialogComponent implements OnInit {
  assetsFolder = 'assets/images/';
  editOrder = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      regionId: number;
      estateTypeId: number;
      dialog: MatDialog;
      products: number[];
      stepper: MatStepper;
      price: number;
      reduction: number;
    },
    private logger: LoggerService
  ) {}

  ngOnInit() {}

  serviceImgUrl(id: number) {
    let url = this.assetsFolder;

    url += this.getFileName(id);
    url += '-selected.svg';
    return url;
  }

  getFileName(id: number): string {
    id = id === 8 ? 4 : id === 16 ? 15 : id;
    let s: any = services.find(sv => sv.id === id);
    if (s === undefined) {
      s = immoServices.find(serv => serv.id === id);
    }
    return s === undefined ? null : s.fileName;
  }

  getRegionImg(id: number) {
    const url = '../../../assets/images/be-';
    const file = id === 2 ? 'vl' : id === 1 ? 'bxl' : 'wal';
    this.logger.log(file);
    return url + file + '-selected.svg';
  }

  getEstateImg(id: number) {
    const url = '../../../assets/images/type-';
    const file =
      id === 2 ? 'immeuble' : id === 4 || id === 11 || id === 1 || id === 12 || id === 13 ? 'appart' : 'maison';
    return url + file + '-selected.svg';
  }

  backToEditOrder() {
    this.editOrder = true;
    this.data.stepper.previous();
  }

  next() {
    this.data.dialog.closeAll();
  }
}
