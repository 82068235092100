import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-all-notaires',
  templateUrl: './all-notaires.component.html',
  styleUrls: ['./all-notaires.component.scss']
})
export class AllNotairesComponent  {
  @Input() activeFilter;
  constructor(private router: Router) { }

  toggleStats(){
    this.router.navigate(['/stats']);
  }

}
