<div class="white">
  <div class="row spaceRow">
    <span class="col-lg-6 col-md-6 col-12 mainTitle">{{'profile.password' | translate}}</span>
    <div class="col-lg-6 col-md-6 col-12 cent"><button class="btn btn-secondary" (click)="confirmPass()">Confirmer</button></div>

    <!-- <span id="mdpP" class="col-12 pl-2 pt-1"
      >Votre mot de passe doit contenir au moins 1 majuscule , 1 chiffre et un caractère spécial.</span
    > -->
  </div>
  <div [formGroup]="newPassword" class="row spaceRow pl pb">
    <div class="col-lg-4 col-md-4 col-12">
      <span class="profileSubtitle col-12">{{'profile.passwordActual' | translate}}</span>
      <div class="col-12">
        <input formControlName="oldpassword" placeholder="insérez votre mot de passe" type="password" class="profileRep" />
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-12 pt-1">
      <span class="profileSubtitle col-12">{{'profile.newpassword' | translate}}</span>
      <div class="col-12">
        <input placeholder="insérez votre nouveau mot de passe" formControlName="newpassword" type="password" class="profileRep" />
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-12 pt-1">
      <span style="display:flex;" class="profileSubtitle col-12">{{'profile.confirmNewPassword' | translate}}</span>
      <div class="col-12">
        <input formControlName="confirmNewpassword" placeholder="confirmez mot de passe" type="password" class="profileRep" />
      </div>
    </div>
  </div>
</div>
