import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-panel-xl-request-dialog',
  templateUrl: './panel-xl-request-dialog.component.html',
  styleUrls: ['./panel-xl-request-dialog.component.scss']
})
export class PanelXlRequestDialogComponent implements OnInit {
  devisFormGroup: FormGroup;
  sent: boolean;
  email: string;
  userName: string;
  constructor(
    private _formBuilder: FormBuilder,
    private sendEmailService: SendEmailService,
    private logger: LoggerService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog }
  ) {}

  ngOnInit() {
    this.sent = false;

    this.devisFormGroup = this._formBuilder.group({
      instructions: [''],
      description: [''],
      remarks: [''],
      email: '',
      phone: '',
      firstName: '',
      lastName: ''
    });
  }

  close() {
    this.data.dialog.closeAll();
  }

  onSubmit() {
    if (this.devisFormGroup.invalid) {
      this.logger.log('invalid form');
      return;
    }
    this.logger.log(this.devisFormGroup.value);
    this.devisFormGroup.value.remarks = this.devisFormGroup.value.instructions;
    this.devisFormGroup.value.email = this.authService.userEmail;
    this.devisFormGroup.value.lastName = this.authService.userName;
    this.sendEmailService.sendDevis(this.devisFormGroup.value, 'Panneau XL').subscribe(
      val => {
        this.logger.log(`🚀`);
        this.logger.log('Devis envoyé avec succès', val);
        this.sent = true;
      },
      response => {
        this.logger.log('😰 POST Devis error', response);
        this.sent = false;
      }
    );
  }
}
