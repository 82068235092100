import { VisitAdValoris } from './../models/visitAdValoris';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ActionTypes } from './../store/order/';
import * as fromOrder from '../store/order/order.actions';
import * as fromUI from '../store/ui/ui.actions';
import { AppState } from '../../interfaces';

import * as estates from './../data/estates';
import { Product } from '../models/product';
import { DataService } from '../services/data.service';
import * as PRODUCTS from '../data/products';
import { getServices } from '../store/order/order.selectors';
import { Reduction } from '../models/reduction';
import { descriptifPacks, ONLINE_REDUC } from '../data';
import * as orderSelector from '../store/order/order.selectors';
import * as uiSelector from '../store/ui/ui.selectors';
import { Document } from '../models/document';
import { Owner, UserForm, User, UserType } from '../models/user';
import { Address } from '../models/address';
import { Region } from '../models/region';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PackSelectedComponent } from 'src/app/snackbars/pack-selected/pack-selected.component';
import { PromoCodeDB } from '../models/PromoCodeDB';
import { DESCRIPTIF } from '../data/products';
import { LoggerService } from '../services/logger.service';

import { projectID } from '../data/theme-config';
import { VENTES } from '../enums/vente.enum';
import { defaultVenteInfo } from '../models/venteInfo';
import { Print } from '../models/print';
import { Panel } from '../models/paper';

@Injectable()
export class OrderEffects {
  descriptifPacks: number[];

  services: number[];
  products: Product[];
  getPacksWithPricesNoFilt: any[];
  newUserType;
  owner;
  showpacks: boolean;
  immoPackSelected: number;
  userPricingPlan: number;
  region: number;
  statut: number;
  esateType: number;
  estateStyle: number;
  reason: number;
  venteType: number;

  gazChoice: number;

  onlineReduc = ONLINE_REDUC;

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private logger: LoggerService
  ) {
    this.store.select(getServices).subscribe(serv => (this.services = serv));
    this.store.select(orderSelector.getProducts).subscribe(pr => (this.products = pr));
    this.store.select(orderSelector.getUserType).subscribe(ut => (this.newUserType = ut));
    this.store.select(orderSelector.getOwner).subscribe(o => (this.owner = o));
    this.store.select(orderSelector.getImmoPack).subscribe(i => (this.immoPackSelected = i));
    this.store.select(uiSelector.getShowPacks).subscribe(s => (this.showpacks = s));
    this.store.select(orderSelector.getPricingPlan).subscribe(pp => (this.userPricingPlan = pp));
    this.store.select(orderSelector.getRegion).subscribe(r => (this.region = r));
    this.store.select(orderSelector.getReason).subscribe(r => (this.reason = r));
    this.store.select(orderSelector.getVenteType).subscribe(v => (this.venteType = v));
    this.store.select(orderSelector.getEstateID).subscribe(e => (this.esateType = e));
    this.store.select(orderSelector.getEstateStyle).subscribe(e => (this.estateStyle = e));
    this.store.select(orderSelector.gazChoice).subscribe(g => (this.gazChoice = g));
    this.store.select(uiSelector.getPacksWithPricesNoFilt)
      .subscribe(pack => {
        this.getPacksWithPricesNoFilt = pack;
        // if pack changed reload products and reducs
        this.getProducts(this.products.map(p => p.id));
      });

    this.descriptifPacks = descriptifPacks;
  }

  @Effect()
  actionReason$ = this.actions$.pipe(
    ofType(ActionTypes.SET_REASON),
    switchMap((action: any) => {
      return [
        new fromOrder.SetEstateType(-1),
        new fromOrder.SetRegion(-1),
        new fromOrder.SetServices([]),
        new fromOrder.SetAddress(new Address()),
        new fromUI.ShowMoreServices(false)
      ];
    })
  );

  @Effect()
  actionImmoOrCtrl$ = this.actions$.pipe(
    ofType(ActionTypes.SET_IMMOORCTRL),
    switchMap((action: any) => {
      return [
        new fromOrder.SetEstateType(-1),
        new fromOrder.SetServices([]),
        action.payload === 1 ? new fromUI.ShowMoreServices(true) : new fromUI.ShowMoreServices(false)
      ];
    })
  );

  @Effect()
  actionVenteType$ = this.actions$.pipe(
    ofType(ActionTypes.SET_VENTETYPE),
    switchMap((action: any) => {
      return [new fromOrder.SetServices([]), new fromOrder.SetVenteInfo(defaultVenteInfo)];
    })
  );

  @Effect()
  actionRegion$ = this.actions$.pipe(
    ofType(ActionTypes.SET_REGION),
    switchMap((action: any) => [
      new fromOrder.SetRegionInfo(this.getRegion(action.payload)),
      new fromOrder.SetProducts(this.getProducts(this.services))
    ])
  );

  @Effect()
  actionX$ = this.actions$.pipe(
    ofType(ActionTypes.SET_ESTATETYPE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionEstateStyles$ = this.actions$.pipe(
    ofType(ActionTypes.SET_ESTATESTYLE),
    map((action: any) => this.checkStyles(this.services, action.payload))
  );

  @Effect()
  actionY$ = this.actions$.pipe(
    ofType(ActionTypes.SET_SERVICES),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(action.payload)))
  );

  @Effect()
  actionNbOfApp$ = this.actions$.pipe(
    ofType(ActionTypes.SET_NUMBEROFAPPARTMENTS),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionOnlinePayment$ = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_ONLINEPAYMENT),
    map((action: any) => new fromOrder.SetReductions(this.checkReductionOnline()))
  );

  @Effect()
  actionPromoCode$ = this.actions$.pipe(
    ofType(ActionTypes.SET_PROMOCODE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionGazChoice$ = this.actions$.pipe(
    ofType(ActionTypes.SET_GAZCHOICE),
    map((action: any) => new fromOrder.SetProducts(this.getProducts(this.services)))
  );

  @Effect()
  actionInvoiceTo$ = this.actions$.pipe(
    ofType(ActionTypes.SET_INVOICETO),
    map((action: any) => this.fillDocumentInfo(action.payload))
  );

  @Effect()
  actionSetUserForm$ = this.actions$.pipe(
    ofType(ActionTypes.SET_USERFORM),
    map((action: any) => new fromOrder.SetOwner(this.getOwner(action.payload)))
  );

  @Effect()
  actionSetUserInfo$ = this.actions$.pipe(
    ofType(ActionTypes.SET_USERINFO),
    switchMap((action: any) => [
      new fromOrder.SetProducts(this.getProducts(this.services)),
      new fromOrder.SetOwner(this.getOwner(action.payload))
    ])
  );

  @Effect()
  actionUrgent$ = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_URGENCE),
    map((action: any) => new fromOrder.ToggleOnlinePayment(true))
  );

  @Effect()
  actionUrgentPro$ = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_URGENCE_PRO),
    map((action: any) => this.addUrgentPro(action.payload))
  );

  @Effect()
  actionParutions$ = this.actions$.pipe(
    ofType(ActionTypes.SET_PARUTIONS),
    map((action: any) => this.checkParutions(action.payload))
  );

  @Effect()
  actionPanels$ = this.actions$.pipe(
    ofType(ActionTypes.SET_PANELS),
    map((action: any) => this.checkPanels(action.payload))
  );

  @Effect()
  actionVisitAdValoris$ = this.actions$.pipe(
    ofType(ActionTypes.SET_VISITADVALORIS),
    map((action: any) => this.checkVisitAdValoris(action.payload))
  );

  @Effect()
  actionSplitFacturation$ = this.actions$.pipe(
    ofType(ActionTypes.SET_SPLIT_INVOICE),
    map((action: any) => this.checkSplitFacturation(action.payload))
  );

  private getRegion(id: number): Region {
    return this.dataService.getRegionTypes().find(r => r.id === id);
  }

  private getOwner(user: UserForm) {
    if (this.newUserType === UserType.Particulier) {
      const owner = new Owner();
      owner.email = user.email;
      owner.firstname = user.firstname;
      owner.lastname = user.lastname;
      owner.phone = user.phone;
      return owner;
    } else {
      return this.owner;
    }
  }

  private checkSplitFacturation(split: boolean) {
    const agencyProducts = split ? [...this.products].filter(pr => pr.id > 17 || pr.id === PRODUCTS.PLAN) : [];
    return new fromOrder.SetAgencyProducts(agencyProducts);
  }

  private checkStyles(services, styleID: number) {
    /* if (styleID === -1) {
      return new fromOrder.SetServices([]);
    } else {
      return new fromOrder.SetProducts(this.getProducts(services));
    } */
    /* if (styleID === 3) {
      return new fromOrder.SetEstateType(estates.TERRAIN);
    } */
    return new fromOrder.SetServices([]);
  }

  private checkParutions(p) {
    let serv = [...this.services];
    const fullPressList: Print = [...p].find(pr => pr.id === PRODUCTS.FullPress);
    const hasFullPressParu = this.services.find(s => s === PRODUCTS.FullPress);
    if (!hasFullPressParu && fullPressList) {
      const arrayFP = new Array(fullPressList.nbOfWeeks).fill(PRODUCTS.FullPress);
      serv = [...this.services, ...arrayFP];
    } else if (hasFullPressParu && !fullPressList) {
      serv = [...this.services].filter(id => id !== PRODUCTS.FullPress);
    }
    const withoutFullPressList = [...p].filter(pr => pr.id !== PRODUCTS.FullPress);
    if (withoutFullPressList.length > 0) {
      const ids = withoutFullPressList.map(par => par.id);
      serv = [
        ...serv.filter(
          id =>
            (id < PRODUCTS.PublicationVlan ||
              id > PRODUCTS.PublicationProximagArdennes ||
              id === PRODUCTS.VISITADVALORIS ||
              id === PRODUCTS.LOCKSMITH) &&
            (id < PRODUCTS.ProximagNamurBW || id > PRODUCTS.ProximagHainautOutOfOcc) &&
            id !== PRODUCTS.VlanEditionLocal
            && id !== PRODUCTS.ZimmoOnline
            && (id >= PRODUCTS.ProximagNamurBW || id <= PRODUCTS.ProximagHainautOutOfOcc)
        ),
        ...ids
      ];
    } else if (withoutFullPressList.length === 0) {
      serv = [...serv].filter(
        id =>
          (id < PRODUCTS.PublicationVlan ||
            id > PRODUCTS.PublicationProximagArdennes ||
            id === PRODUCTS.VISITADVALORIS ||
            id === PRODUCTS.LOCKSMITH) &&
          (id < PRODUCTS.ProximagNamurBW || id > PRODUCTS.ProximagHainautOutOfOcc) &&
          id !== PRODUCTS.VlanEditionLocal 
          && id !== PRODUCTS.ZimmoOnline
      );
    }
    return new fromOrder.SetServices(serv);
  }

  private checkPanels(p: Panel[]) {
    const ids = p.map(panel => panel.id);
    const serv = [...this.services.filter(s => s !== PRODUCTS.PANEL_STANDARD && s !== PRODUCTS.PANEL_XL_CERTI)];
    return new fromOrder.SetServices([...serv, ...ids]);
  }

  private checkVisitAdValoris(av: VisitAdValoris) {
    let serv = [...this.services];
    const hasVisitForfait = this.services.find(
      s =>
        s === PRODUCTS.VISITADVALORIS ||
        s === PRODUCTS.VisitGil4S ||
        s === PRODUCTS.VisitGil6S ||
        s === PRODUCTS.VisitGilBoundless_3M
    );
    const selectedForfait = av.forfaits.find(f => f.active);
    if (!hasVisitForfait && selectedForfait) {
      serv = [...this.services, selectedForfait.id];
      av.options.map(op => op.active && serv.push(op.id));
    } else if (hasVisitForfait && !selectedForfait) {
      serv = [...this.services].filter(
        id =>
          id !== PRODUCTS.VISITADVALORIS &&
          id !== PRODUCTS.VisitGil4S &&
          id !== PRODUCTS.VisitGil6S &&
          id !== PRODUCTS.VisitGilBoundless_3M &&
          id !== PRODUCTS.VisitGil2Times_Bid &&
          id !== PRODUCTS.VisitGilBoundless_Bid
      );
    }
    return new fromOrder.SetServices(serv);
  }

  private fillDocumentInfo(type: string) {
    const doc = new Document();
    let owner: Owner;
    let userForm: UserForm;
    let user: User;
    let address: Address;
    this.store.select(orderSelector.getOwner).subscribe(o => (owner = o));
    this.store.select(orderSelector.getUserForm).subscribe(u => (userForm = u));
    this.store.select(orderSelector.getAddress).subscribe(a => (address = a));
    this.store.select(orderSelector.getUserInfo).subscribe(u => (user = u));
    if (type === 'toProprio') {
      doc.firstName = owner.firstname !== null ? owner.firstname : userForm.firstname;
      doc.lastName = owner.lastname !== null ? owner.lastname : userForm.lastname;
      doc.address.street = address.street;
      doc.address.number = address.number;
      doc.address.zip = address.zip;
      doc.address.city = address.city;
    } else if (type === 'toProprioAndPro') {
      doc.firstName = owner.firstname;
      doc.lastName = owner.lastname + ' C/o ' + user.lastname;
      doc.address.street = user.address.street;
      doc.address.number = user.address.number;
      doc.address.zip = user.address.zip;
      doc.address.city = user.address.city;
    } else if (type === 'toPro') {
      doc.firstName = user.firstname;
      doc.lastName = user.lastname;
      doc.address.street = user.address.street;
      doc.address.number = user.address.number;
      doc.address.zip = user.address.zip;
      doc.address.city = user.address.city;
    }

    return new fromOrder.SetDocument(doc);
  }

  private checkReductionOnline() {
    let onlinePayment;
    let reductions;
    this.store.select(orderSelector.getOnlinePayment).subscribe(o => (onlinePayment = o));
    this.store.select(orderSelector.getReductions).subscribe(r => (reductions = [...r]));
    if (onlinePayment) {
      const reduction = new Reduction(1001, this.onlineReduc, 'Réduction paiement en ligne');
      reductions.push(reduction);
    } else {
      reductions = reductions.filter(r => r.id !== 1001);
    }

    return reductions;
  }

  private checkReductionPromoCode(): Promise<PromoCodeDB> {
    let promoCode;
    let products;
    let region;

    this.store.select(orderSelector.getPromoCode).subscribe(p => (promoCode = p));
    this.store.select(orderSelector.getServices).subscribe(p => (products = p));
    this.store.select(orderSelector.getRegion).subscribe(r => (region = r));

    const found = this.dataService.isPromoCodeValid(promoCode, products, region);

    return found;
  }

  private getProducts(ids: number[]): Product[] {
    if (ids.length === 0) {
      this.store.dispatch(new fromOrder.SetImmoPack(-1));
      this.store.dispatch(new fromOrder.SetPackID(''));
      this.store.dispatch(new fromOrder.SetPackName(''));
      return [];
    }

    this.store
      .select(orderSelector.getUrgence)
      .pipe(take(1))
      .subscribe(u => {
        if (u) {
          this.store.dispatch(new fromOrder.ToggleUrgence(true));
        }
      });

    this.store
      .select(orderSelector.getUrgencePro)
      .pipe(take(1))
      .subscribe(u => {
        if (u && !ids.find(id => id === PRODUCTS.URGENT_PRO)) {
          this.store.dispatch(new fromOrder.ToggleUrgencePro(false));
        }
      });

    const reductions: Reduction[] = [];
    // this.logger.log('calculating price');

    let panels: Panel[] = [];
    this.store
      .select(orderSelector.getPanels)
      .pipe(take(1))
      .subscribe(pan => (panels = pan));

    const products = ids.map(id => {
      const prod = this.dataService.getProductPrice(id);
      if (this.estateStyle === 3 && (id === 29 || id === 32)) {
        id += 1;
      }

      if (panels.length && (id === PRODUCTS.PANEL_STANDARD || id === PRODUCTS.PANEL_XL_CERTI)) {
        const panelFound = panels.find(pa => pa.id === id);
        prod.price = panelFound.totalPrice;
        panels = panels.filter(p => p !== panelFound);
      } else if (id === PRODUCTS.DEPLACEMENT) {
        this.store.select(orderSelector.getAddressKeyPrice).subscribe(keyPrice => {
          prod.price = keyPrice;
          prod.reduc = 0;
          prod.commission = 0;
        });
      }

      return new Product(id, prod.price, prod.reduc, prod.commission);
    });

    products.forEach(prod => {
      let reduction: Reduction;
      let reduc = Number(prod.reduction);
      // Check if prod.id is in pricingPlansDB.services for region and estatetype and add special reduction
      if (this.userPricingPlan !== 0 && this.esateType !== estates.IMMEUBLE_APPART) {
        reduc = this.dataService.getStatutReduc(prod.id, reduc, this.dataService.getProduct(prod.id));
      }
      if (reduc && reduc !== 0) {
        reduction = new Reduction(prod.id, reduc, 'Réduction ' + prod.name);
        reductions.push(reduction);
      }
    });

    if (this.gazChoice === 0) {
      const gaz = reductions.filter(r => r.id === 15).length > 0 ? reductions.filter(r => r.id === 15)[0] : null;
      if (gaz) {
        gaz.price = +gaz.price + 0 + '';
      }
    }

    const packReducs = this.checkForPacks(products);

    // Check if there is a packReducs.id in pricingPlansDB.services for region and estatetype and add special reduction

    const redOnline = !this.getReductionOnline() ? [] : this.getReductionOnline();

    this.checkReductionPromoCode().then(promoCode => {
      let reducCode = [];
      if (promoCode) {
        let code = '';
        if (typeof promoCode !== 'undefined') {
          reducCode = [new Reduction(1002, promoCode.PromoCode.reduc, `${promoCode.KeyWord}`)];
          code = promoCode.id;
          this.logger.log(reducCode);
        }
        this.store.dispatch(new fromOrder.SetPromoCodeID(code));
      }
      this.store.dispatch(
        new fromOrder.SetReductions(
          reductions
            .concat(packReducs ? packReducs : [])
            .concat(redOnline)
            .concat(reducCode)
        )
      );
    });

    return products;
  }

  private getReductionOnline() {
    let onlinePayment;
    let reduction;
    this.store.select(orderSelector.getOnlinePayment).subscribe(o => (onlinePayment = o));
    if (onlinePayment) {
      reduction = new Reduction(1001, this.onlineReduc, 'RÉDUCTION PAIEMENT EN LIGNE');
    }

    return reduction;
  }

  checkForPacks(products: Product[]): Reduction {
    let reduction: Reduction;
    const immoPacks = JSON.parse(JSON.stringify(this.getPacksWithPricesNoFilt));
    if (!immoPacks) {
      window.location.reload();
    } else if (products.length > 0 && immoPacks.length > 0) {
      const productsID: number[] = [];
      let biggestReductionPack = null;
      let biggestLength = 0;
      products.map(p => productsID.push(p.id));
      this.logger.log('productsID', productsID);
      // saving only pack with biggest reduction
      if (
        (projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') &&
        this.immoPackSelected !== -1
      ) {
        this.logger.log('immoPackSelected', this.immoPackSelected);
        const packReduc = immoPacks.find(pa => this.immoPackSelected === +pa.description);
        biggestReductionPack = packReduc;
      } else {
        immoPacks.forEach(pack => {
          if (projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') {
            const found =
              pack.services.length > 0 &&
              pack.services.every(s => productsID.indexOf(s) >= 0) &&
              productsID.length >= pack.services.length;
            this.logger.log(found);
            if (found) {
              const packReduc = immoPacks.filter(p => p.id === pack.id)[0];
              biggestReductionPack = packReduc;
            }
          } else {
            this.logger.log('pack', pack.services);
            const found = pack.services.every(s => productsID.indexOf(s) >= 0);
            this.logger.log(found);
            if (found) {
              const packReduc = immoPacks.filter(p => p.id === pack.id)[0];
              if (!biggestReductionPack) {
                biggestReductionPack = packReduc;
                biggestLength = pack.services.length;
              }
              biggestReductionPack =
                packReduc.reduc > biggestReductionPack.reduc && pack.services.length >= biggestLength
                  ? packReduc
                  : biggestReductionPack;
            }
          }
        });
      }
      if (biggestReductionPack) {
        this.store.dispatch(new fromOrder.SetPackID(biggestReductionPack.id));
        this.store.dispatch(new fromOrder.SetPackName(biggestReductionPack.name));
        let reduc = Number(biggestReductionPack.reduc);
        const packID = parseInt(biggestReductionPack.description, 10);
        this.logger.log('packID', biggestReductionPack);
        if (this.userPricingPlan !== 0 && this.esateType !== estates.IMMEUBLE_APPART) {
          reduc = this.dataService.getStatutReduc(packID, reduc, this.dataService.getProduct(packID));
          biggestReductionPack.reduc = reduc;
        }
        // this.logger.log(reduc);
        if (reduc && reduc !== 0) {
          reduction = new Reduction(packID, reduc, 'Réduction ' + biggestReductionPack.name);
        }
        if (!this.showpacks && this.immoPackSelected !== packID) {
          this.snackBar.openFromComponent(PackSelectedComponent, {
            panelClass: 'snackbar-success',
            data: biggestReductionPack
          });
          this.store.dispatch(new fromOrder.SetImmoPack(packID));
          // this.logger.log(biggestReductionPack);
        }

        const found = this.services.indexOf(DESCRIPTIF) > -1;
        const foundPlaquette = this.services.indexOf(PRODUCTS.PLAQUETTE) > -1;
        const descriptifPack = this.descriptifPacks?.some(id => id === packID);
        this.logger.log('found?', found);
        this.logger.log('descriptifPack?', descriptifPack);

        const tempProducts = [...this.services];
        if (found && !foundPlaquette) {
          tempProducts.push(PRODUCTS.PLAQUETTE);
          tempProducts.push(PRODUCTS.LIVRET);
          tempProducts.push(PRODUCTS.AFFICHETTE);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
        }
      } else {
        this.store.dispatch(new fromOrder.SetPackID(''));
        this.store.dispatch(new fromOrder.SetPackName(''));
        const found = this.services.indexOf(DESCRIPTIF) && this.services.indexOf(PRODUCTS.PLAQUETTE) > -1;
        if (found) {
          let tempProducts = [...this.services];
          // tempProducts = tempProducts.filter(pr => pr !== DESCRIPTIF);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.DESCRIPTIF_URBA);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.PLAQUETTE);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.LIVRET);
          tempProducts = tempProducts.filter(pr => pr !== PRODUCTS.AFFICHETTE);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
        }
        this.store.dispatch(new fromOrder.SetImmoPack(-1));
      }
    }

    this.checkNeedAddForfait();
    return reduction;
  }

  checkNeedAddForfait() {
    this.store
      .select(orderSelector.getPackID)
      .pipe(take(1))
      .subscribe(id => {
        const tempProducts = [...this.services];
        if (projectID === 'nvn' && id !== -1 && !tempProducts.find(d => d === PRODUCTS.Fees)) {
          tempProducts.push(PRODUCTS.Fees);
          if (id === 9994) {
            tempProducts.push(PRODUCTS.EXTRA);
          }
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
          this.logger.log('⚡️ Add Frais de dossier ⚡️');
        }
        if (
          projectID !== 'default' &&
          id !== -1 &&
          this.venteType === VENTES.BIDDIT &&
          !tempProducts.find(d => d === PRODUCTS.BidditFees)
        ) {
          tempProducts.push(PRODUCTS.BidditFees);
          this.store.dispatch(new fromOrder.SetServices(tempProducts));
          this.logger.log('⚡️ Add Frais Biddit ⚡️');
        }
      });
  }

  addUrgentPro(isChecked: boolean) {
    const hasUrgentPro = this.services.find(s => s === PRODUCTS.URGENT_PRO);
    let tempProducts = [...this.services];

    if (isChecked && !hasUrgentPro) {
      tempProducts.push(PRODUCTS.URGENT_PRO);
      this.logger.log('⚡️ Add URGENT_PRO ⚡️');
    } else if (!isChecked && hasUrgentPro) {
      tempProducts = [...tempProducts].filter(pr => pr !== PRODUCTS.URGENT_PRO);
      this.logger.log('⚡️ Remove URGENT_PRO ⚡️');
    }
    return new fromOrder.SetServices(tempProducts);
  }
}
