<ng-container
  *ngIf="
    ($hasImmoServices | async) &&
    ($hasLegalServices | async) &&
    (hasSplitInvoice && (($isAgency | async) || ($isNotary | async)))
  "
>
  <h6 *ngIf="showTitle">
    {{ 'section-accueil.options-title' | translate }}
  </h6>
  <div>
    <mat-slide-toggle [(ngModel)]="splitInvoice" (change)="togglesplitInvoice()">
      {{ 'section-accueil.split-invoice' | translate }}
      {{
        ($isAgency | async)
          ? ('section-accueil.at-agency.title' | translate)
          : ('section-accueil.at-notary' | translate)
      }}
    </mat-slide-toggle>
    <ng-container *ngIf="$productsForAgency | async as products">
      <div *ngIf="showDetails && products.length > 0">
        <ul>
          <ng-container *ngFor="let product of products">
            <li *ngIf="product.price > 0">
              {{ 'section-money.reductions.' + product.id | translate }} -
              {{ product.price - product.reduction - product.commission }} € TVAC
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
</ng-container>
