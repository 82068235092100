<div class="dialog">
  <h4 class="modalTitle break">{{'profile.editContact' | translate}}</h4>
  <span id="close" (click)="this.data.dialog.closeAll()"
    ><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3941 3.90878C12.2097 3.72432 11.9106 3.72432 11.7262 3.90878L8.72021 6.91473L5.71427 3.90878C5.52981 3.72433 5.23075 3.72433 5.04629 3.90878L4.37832 4.57676C4.19387 4.76121 4.19387 5.06027 4.37832 5.24473L7.38426 8.25067L4.37846 11.2565C4.19401 11.4409 4.19401 11.74 4.37846 11.9244L5.04644 12.5924C5.23089 12.7769 5.52995 12.7769 5.71441 12.5924L8.72021 9.58661L11.726 12.5924C11.9105 12.7769 12.2095 12.7769 12.394 12.5924L13.062 11.9244C13.2464 11.74 13.2464 11.4409 13.062 11.2565L10.0562 8.25067L13.0621 5.24472C13.2466 5.06027 13.2466 4.7612 13.0621 4.57675L12.3941 3.90878Z"
        fill="#6C788D"
      />
    </svg>
  </span>
  <form class="content" [formGroup]="newContact">
    <span class="modalSubtitles col-12">{{'profile.title' | translate}}</span>
    <div class="col-12">
      <mat-form-field appearance="outline" class="col-12 formField">
        <mat-label>{{'profile.title' | translate}}</mat-label>
        <mat-select formControlName="Title">
          <mat-option *ngFor="let type of titleType" [value]="type.value">
            {{'profile.title'+type.text | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span *ngIf="!this.data.mainContact" class="modalSubtitles col-12">{{'profile.function' | translate}}</span>
    <div *ngIf="!this.data.mainContact" class="col-12">
      <mat-form-field appearance="outline" class="col-12 formField">
        <mat-label>{{'profile.function' | translate}}</mat-label>
        <mat-select formControlName="Function">
          <mat-option class="TEST" *ngFor="let function of functionType" [value]="function.value">
          {{ 'profile.fonction'+function.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitles col-12">{{'profile.name' | translate}}</span>
        <div class="col-12">
        <mat-form-field appearance="outline" class="col-12 formField">
          <input matInput required  formControlName="Name" type="text" />
        </mat-form-field>
      </div>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitles col-12">{{'profile.firstname' | translate}}</span>
        <div class="col-12">
      <mat-form-field appearance="outline" class="col-12 formField">
        <input matInput required formControlName="FirstName" type="text" />
      </mat-form-field>
    </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitles col-12">{{'profile.gsm' | translate}}</span>
        <div class="col-12">
        <mat-form-field appearance="outline" class="col-12 formField">
          <input
            matInput
            required
            placeholder="04xx/xx.xx.xx"
            mask="0000000/00.00.00||0000/00.00.00"
            formControlName="PhoneNumber"
            type="text"
          />
        </mat-form-field>
      </div>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitles col-12">{{'profile.phone' | translate}}</span>
        <div class="col-12">
        <mat-form-field appearance="outline" class="col-12 formField">
          <input
            matInput
            required
            mask="00/000.00.00"
            placeholder="02/xx.xx.xx"
            formControlName="TelNumber"
            type="text"
          />
        </mat-form-field>
      </div>
      </div>
    </div>
    <span class="modalSubtitles col-12">{{'profile.email' | translate}}</span>
    <div class="col-12">
      <mat-form-field appearance="outline" class="col-12 formField">
        <input *ngIf="this.data.mainContact" readonly matInput required placeholder="Email" formControlName="Email" type="email" />

        <input *ngIf="!this.data.mainContact" matInput required placeholder="Email" formControlName="Email" type="email" />
      </mat-form-field>
    </div>
    <div class="row checkbox">
      <div class="col-md-6 col-12 cent">
      <span class="">{{ 'profile.contactNotifOrder' | translate }}</span>
      <mat-checkbox
          [checked]="!this.data.contact.UnsubscribedOrderInformations"
          formControlName="UnsubscribedOrderInformations"
          class=""
        ></mat-checkbox
      >
    </div>
    <div class="col-md-6 col-12 cent">
      <span class="">{{ 'profile.contactNotifAll' | translate }}</span>
      <mat-checkbox
          [checked]="!this.data.contact.UnsubscribedEmailReports"
          formControlName="UnsubscribedEmailReports"
          class=""
        ></mat-checkbox
      >
    </div>
    </div>
    <!-- <mat-error id="errorMsg" *ngIf="displayError && newContact.status != 'VALID'"
      >Veuillez bien remplir le formulaire</mat-error
    > -->
    <div class="flex">
      <button class="btn btn-secondary" type="submit" (click)="editContact()" >{{'profile.editContact' | translate}} </button>
    </div>
  </form>
</div>
