import { Component, OnInit } from '@angular/core';
import { displayHelpSection, projectID, showBigFooter } from '../core/data/theme-config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  displayHelpSection = displayHelpSection;
  projectID = projectID;
  showBigFooter = showBigFooter;

  constructor() {}

  ngOnInit() {}
}
