<div id="dialog-container" *ngIf="!sent; else congrats">
  <ng-container *ngIf="!displayAdd">
    <header id="dialog-header">
      <h5>
        <img src="../../../assets/images/service-poster.svg" />
        {{ 'signs-request-dialog.title' | translate }}
      </h5>
    </header>
    <div id="dialog-intro">
      <p>
        {{ 'poster-request-dialog.intro-certi' | translate }}
      </p>
    </div>
    <div id="dialog-content">
      <button *ngIf="panelList.length === 0" (click)="displayAddPanel()" id="first-support" class="btn-accent">
        <fa-icon icon="plus"></fa-icon>
        {{ 'signs-request-dialog.add-paper' | translate }}
      </button>
      <ng-container *ngIf="panelList.length > 0">
        <ul id="paper-list">
          <li *ngFor="let panel of panelList; let i = index">
            <div class="paper-info">
              <span class="support">
                {{ panel.format }} | {{ 'section-vente-type.vente-type.' + panel.saleType | translate }} |
                {{ panel.language }} | {{ panel.quantity }} {{ 'poster-request-dialog.units' | translate }}
              </span>
              <strong class="price">= {{ panel.totalPrice | htva }}€ {{ 'section-money.htva' | translate }}</strong>
            </div>
            <!-- <button
              (click)="editPanel(panel, i)"
              class="paper-edit"
              title="{{ 'poster-request-dialog.edit-title' | translate }}"
            >
              <fa-icon icon="pen"></fa-icon>
            </button> -->
            <button
              (click)="deletePanel(i)"
              class="paper-delete"
              title="{{ 'poster-request-dialog.delete-title' | translate }}"
            >
              <fa-icon icon="trash"></fa-icon>
            </button>
          </li>
          <li *ngIf="fraisMDN">
            <div class="paper-info">
              <span class="support">
                {{ 'signs-request-dialog.fees' | translate }}
              </span>
              <strong class="price">= {{ fraisMDN }} {{ 'section-money.htva' | translate }}</strong>
            </div>
          </li>
        </ul>
        <button (click)="displayAddPanel()" id="add-paper">
          <fa-icon icon="plus"></fa-icon> {{ 'signs-request-dialog.add-paper' | translate }}
        </button>
      </ng-container>
      <label>{{ 'section-services.panels.info' | translate }}</label>
      <mat-form-field id="information-area" appearance="outline">
        <textarea
          [(ngModel)]="description"
          matInput
          placeholder="{{ 'messages-page.placeholder' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
    <footer id="dialog-footer">
      <button (click)="cancel()" class="btn">{{ 'common.cancel' | translate }}</button>
      <button (click)="confirmPanels()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
    </footer>
  </ng-container>

  <ng-container *ngIf="displayAdd">
    <header id="dialog-header">
      <h5>
        <fa-icon icon="plus"></fa-icon>
        {{ 'signs-request-dialog.add-paper' | translate }}
      </h5>
    </header>
    <form id="dialog-content">
      <div class="form-element">
        <a href="https://www.certinergie.be/fr/panneaux-publicitaires-immobiliers/" target="_blank">
          {{ 'signs-request-dialog.more-info' | translate }}
        </a>
      </div>
      <div class="form-element" id="format-choice">
        <label>{{ 'signs-request-dialog.format' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-format" [(ngModel)]="selectedPanel.selectedFormat" (ngModelChange)="panelSelect()">
            <mat-option *ngFor="let option of formatOptions; let i = index" [value]="i">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="couleur-choice">
        <label>{{ 'signs-request-dialog.type-of-sale' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-color" [(ngModel)]="selectedPanel.selectedSaleType">
            <mat-option *ngFor="let saleType of saleTypeOptions; let i = index" [value]="i">
              {{ 'section-vente-type.vente-type.' + saleType | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="number-choice">
        <label for="poster-number">{{ 'common.language' | translate }} :</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-color" [(ngModel)]="selectedPanel.selectedLanguage">
            <mat-option *ngFor="let language of languageOptions; let i = index" [value]="i">
              {{ language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-element" id="number-choice">
        <label for="poster-number">{{ 'signs-request-dialog.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select name="selected-color" [(ngModel)]="selectedPanel.quantity">
            <mat-option *ngFor="let quantity of quantityOptionsExt; let i = index" [value]="i">
              {{ quantity.amount }} ({{ quantity.price.toFixed(2) }}€ / {{ 'poster-request-dialog.units' | translate }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <footer id="dialog-footer">
      <button (click)="backToList()" class="btn">{{ 'common.cancel' | translate }}</button>
      <button *ngIf="!editing" (click)="addPanel()" class="btn-primary">{{ 'common.add' | translate }}</button>
      <button *ngIf="editing" (click)="saveEditPanel()" class="btn-primary">
        {{ 'common.edit' | translate }}
      </button>
    </footer>
  </ng-container>
</div>

<ng-template #congrats>
  <div id="dialog-confirm">
    <picture>
      <fa-icon icon="check"></fa-icon>
    </picture>
    <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
    <p>
      {{ 'specific-request-dialog.success-content' | translate }}
    </p>
    <button (click)="cancel()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
  </div>
</ng-template>
