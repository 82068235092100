import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../interfaces';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as userSelector from '../../core/store/order/order.selectors';
import * as uiSelector from '../../core/store/ui/ui.selectors';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LoggerService } from 'src/app/core/services/logger.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { debounceTime, delay } from 'rxjs/operators';
import { projectID } from 'src/app/core/data/theme-config';
import { UserType } from 'src/app/core/models/user';
import { SetLoginError } from 'src/app/core/store/ui';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  $isLoggedIn: Observable<boolean>;
  $isTablette: Observable<boolean>;
  $isLoggedError: Observable<boolean>;

  debouncer: any;
  emailSent = false;
  emailAsked = false;
  emailError = false;
  emailNotInDB = false;
  language = 'fr';

  emailFoundForOrderUserId = '';
  userIdFoundForOrderUserId = '';

  displayForgotInfoWindow = false;
  displayEmailProposition = false;
  showOthers = false;

  userFG: FormGroup;

  loginStatus: Subject<number>;

  UserTypes = UserType;
  userTypeSelected = UserType.Notaire;

  others = [
    UserType.Administration_Communale,
    UserType.Architecte,
    UserType.Avocat,
    UserType.Certifier,
    UserType.Expert_Immobilier,
    UserType.Surveyor,
    UserType.Promoteur,
    UserType.Other
  ];

  userInfo = {
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
    company: '',
    tva: '',
    street: '',
    num: '',
    zip: '',
    city: ''
  };

  constructor(
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private logger: LoggerService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { register: boolean; forgotten: boolean; selectedUserType: number; dialog: MatDialog }
  ) {
    this.userTypeSelected = this.data.selectedUserType;
    this.displayForgotInfoWindow = this.data.forgotten;
    this.userFG = this._formBuilder.group({
      firstName: [{ value: this.userInfo.firstName, disabled: false }, Validators.required],
      lastName: [{ value: this.userInfo.lastName, disabled: false }, Validators.required],
      phone: [{ value: this.userInfo.phone, disabled: false }, Validators.required],
      email: [
        { value: this.userInfo.email, disabled: false },
        [Validators.required, Validators.email],
        this.isEmailUnique.bind(this)
      ],
      company: [{ value: this.userInfo.company, disabled: false }, Validators.required],
      tva: [{ value: this.userInfo.tva, disabled: false }, Validators.required]
      /* street: [
        {
          value: this.userInfo.street,
          disabled: false
        },
        Validators.required
      ],
      num: [
        {
          value: this.userInfo.num,
          disabled: false
        },
        Validators.required
      ],
      zip: [
        {
          value: this.userInfo.zip,
          disabled: false
        },
        Validators.required
      ],
      city: [
        {
          value: this.userInfo.city,
          disabled: false
        },
        Validators.required
      ] */
    });
  }

  ngOnInit() {
    this.$isLoggedIn = this.store.select(userSelector.getIsLoggedIn);
    this.$isTablette = this.store.select(uiSelector.getIsTablette);
    this.$isLoggedError = this.store.select(uiSelector.getLoginError);
    this.$isLoggedIn.pipe(delay(100)).subscribe(logged => {
      if (logged) {
        this.closeDialog();
      }
    });
    this.loginStatus = this.authService.loginStatus;

    this.store.select(uiSelector.getLanguage).subscribe(ln => (this.language = ln));

    this.userFG.valueChanges.pipe(debounceTime(1000)).subscribe(values => {
      this.userInfo.firstName = values.firstName;
      this.userInfo.lastName = values.lastName;
      this.userInfo.phone = values.phone;
      this.userInfo.email = values.email;
      this.userInfo.company = values.company;
      this.userInfo.tva = values.tva;
      this.userInfo.street = values.street;
      this.userInfo.num = values.num;
      this.userInfo.city = values.city;
      this.userInfo.zip = values.zip;
    });

    const selectedUserTypeString = this.data.selectedUserType;
  }

  ngOnDestroy() {
    this.store.dispatch(new SetLoginError(false));
  }

  signIn(email, pw) {
    if (email !== '' && pw !== '') {
      this.authService.login(email, pw);
    }
  }

  forgotPassword(email: string) {
    this.emailAsked = true;
    if (email === '') {
      this.emailError = true;
      return;
    }
    this.authService.isEmailAlreadyInUse(email).subscribe(data => {
      if (data.Data) {
        // this.logger.log('Email exists', data.Data);
        this.http
          .post<any>('https://systems.certinergie.be/api/Emailwithtemplate/sendmail', {
            EmailType: 94,
            Recipient: email
          })
          .subscribe(
            val => {
              this.logger.log(`🚀`);
              this.logger.log('POST call successful value returned in body', val);
              this.snackBar.open('🚀 Email sent 🚀');
              this.emailSent = true;
            },
            response => {
              this.logger.log('POST call in error', response);
              this.snackBar.open('An error occured 😰');
              this.emailError = true;
            }
          );
      } else {
        this.logger.log(`Email not in DB`);
        this.emailNotInDB = true;
      }
    });
  }

  checkEmailForgotten(email: string) {
    // this.emailAsked = true;
    if (email === '') {
      this.emailError = true;
      return;
    }
    if (!isNaN(+email)) {
      const httpParams = new HttpParams().append('id', email);

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        params: httpParams
      };

      this.http
        .post<{ Data: { email: string; userId: string } }>(
          'https://systems.certinergie.be/api/LoginWeb/GetUserEmailForOrderUserId',
          null,
          httpOptions
        )
        .subscribe(
          data => {
            this.logger.log(`🚀`);
            this.logger.log('POST call successful value returned in body', data);
            this.emailFoundForOrderUserId = data.Data.email;
            this.userIdFoundForOrderUserId = data.Data.userId;
            this.displayEmailProposition = true;
            // this.resetEmail(data.Data.email);
          },
          response => {
            this.logger.log('POST call in error', response);
            this.snackBar.open('Une erreur a eu lieu 😰');
            this.emailError = true;
          }
        );
      return;
    }
    this.authService.isEmailAlreadyInUse(email).subscribe(data => {
      if (data.Data) {
        // this.logger.log('Email exists', data.Data);
        this.resetEmail(email);
      } else {
        this.logger.log(`Email not in DB`);
        this.emailNotInDB = true;
      }
    });
  }

  private resetEmail(email: string) {
    this.http
      .post<any>('https://systems.certinergie.be/api/Emailwithtemplate/sendmail', {
        EmailType: 94,
        Recipient: email
      })
      .subscribe(
        val => {
          this.logger.log(`🚀`);
          this.logger.log('POST call successful value returned in body', val);
          this.snackBar.open('🚀 Email envoyé avec succès 🚀');
          this.emailSent = true;
        },
        response => {
          this.logger.log('POST call in error', response);
          this.snackBar.open('Une erreur a eu lieu 😰');
          this.emailError = true;
        }
      );
  }

  closeDialog() {
    this.data.dialog.closeAll();
  }

  selectUserType(type: UserType) {
    if (type === UserType.Other) {
      this.showOthers = true;
    } else if (type === UserType.Agence || type === UserType.Notaire || type === UserType.Installateur) {
      this.showOthers = false;
    }
    this.logger.log(type);
    this.userTypeSelected = type;
  }

  selectedClass(selectedId) {
    const cls = this.userTypeSelected === selectedId ? 'selected' : '';
    return cls;
  }

  askForProAccount() {
    if (this.userFG.invalid) {
      return;
    }
    const userInfo = {
      ConsumerType: 1688,
      Users: {
        Name: this.userInfo.lastName,
        Email: this.userInfo.email,
        FirstName: this.userInfo.firstName,
        PhoneNumber: this.userInfo.phone,
        Language:
        this.language === 'nl'
          ? 'nl-BE'
          : this.language === 'en'
            ? 'en-BE'
            : this.language === 'de'
              ? 'de-BE'
              : 'fr-BE',
        Addresses: {
          Company: this.userInfo.company,
          VatNumber: this.userInfo.tva,
          Street: this.userInfo.street,
          Number: this.userInfo.num,
          PostalCode: this.userInfo.zip,
          City: this.userInfo.city
        }
      },
      Comment: projectID + ' ' + this.userTypeSelected
    };

    this.http
      .post<any>('https://certinergiesystems.azurewebsites.net/api/CreateNewUser/SaveOrderClient', userInfo)
      .subscribe(
        val => {
          this.logger.log(`🚀`);
          this.logger.log('POST call successful value returned in body', val);
          this.snackBar.open('🚀 Email envoyé avec succès 🚀');
          this.emailSent = true;
          this.authService.loginWithUserID(val.UserID);
          this.closeDialog();
        },
        response => {
          this.logger.log('POST call in error', response);
          this.snackBar.open('Une erreur a eu lieu 😰');
          this.emailError = true;
        }
      );
  }

  isEmailUnique(control: FormControl) {
    clearTimeout(this.debouncer);

    return new Promise((resolve, reject) => {
      this.debouncer = setTimeout(() => {
        this.authService.isEmailAlreadyInUse(control.value).subscribe(data => {
          if (data.Data) {
            this.logger.log('Email exists', data.Data);
            resolve({ isEmailAlreadyInUse: true });
          } else {
            this.logger.log(`🚀`);
            resolve(null);
          }
        });
      }, 1000);
    });
  }

  showForgotInfo() {
    this.displayForgotInfoWindow = true;
  }

  expandRegister() {
    this.data.register = true;
  }

  expandConnect() {
    this.data.register = false;
  }

  close() {
    this.data.dialog.closeAll();
  }
}
