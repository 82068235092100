<div id="dialog-container" *ngIf="!this.sent">
    <header id="dialog-header">
        <h5>
            <fa-icon icon="file-invoice"></fa-icon>
            {{ 'visit-zyva-request-dialog.title' | translate }}
        </h5>
    </header>
    <div id="dialog-intro">
        <p>{{'visit-zyva-request-dialog.intro' | translate}}</p>
        <div id="summary" [innerHTML]="'visit-zyva-request-dialog.info' | translate"></div>
    </div>
    <div id="dialog-content">
        <form id="zyva-form" [formGroup]="zyvaFormGroup">
            <div>
                <label>{{'visit-zyva-request-dialog.key' | translate}}</label>
                <mat-radio-group formControlName="hasKey">
                    <mat-radio-button value="true">{{'visit-zyva-request-dialog.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="false">{{'visit-zyva-request-dialog.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field class="devis-firstname" appearance="outline">
                <mat-label>{{ 'visit-zyva-request-dialog.ownerName' | translate }}</mat-label>
                <input
                  matInput
                  name="firstname"
                  autocomplete="given-name"
                  formControlName="ownerName"
                  required
                />
                <mat-error *ngIf="zyvaFormGroup.get('ownerName')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="devis-firstname" appearance="outline">
                <mat-label>{{ 'visit-zyva-request-dialog.address' | translate }}</mat-label>
                <input
                  matInput
                  name="firstname"
                  autocomplete="given-name"
                  formControlName="address"
                  required
                />
                <mat-error *ngIf="zyvaFormGroup.get('address')?.hasError('required')">
                  {{ 'error-messages.required-blank' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="text-area">
                <textarea matInput formControlName="instructions" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" placeholder="{{ 'visit-zyva-request-dialog.info-supp' | translate }}"></textarea>
            </mat-form-field>
            <div id="files-section">
                <p class="intro">{{ 'visit-zyva-request-dialog.upload' | translate}}</p>
                <div id="upload-files-trigger" *ngIf="uploadLinks.length === 0">
                    <button class="btn-accent" (click)="fileInput.click()">
            <fa-icon icon="upload"></fa-icon>
            {{ 'specific-request-dialog.choose-file' | translate }}
          </button>
                    <span class="input-container">
            <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
          </span>
                </div>
                <div id="upload-list-container" *ngIf="uploadLinks.length > 0">
                    <ul id="upload-list">
                        <li *ngFor="let file of uploadLinks; let i = index" class="upload-element">
                            <div class="info">
                                <span class="name ellipsis" matTooltip="{{ 'File ' + fileIndex(i) }}" matTooltipPosition="above">{{
                  'File ' + fileIndex(i) }}
                </span>
                            </div>
                            <a [href]="file | safeHtml" target="_blank" rel="noopener" class="action action-download" title="{{ 'print-request-dialog.carte.download-file-title' | translate }}">
                                <fa-icon icon="download"></fa-icon>
                            </a>
                            <button (click)="deletePhotoLink(i)" class="action action-delete" title="{{ 'print-request-dialog.carte.delete-file-title' | translate }}">
                <fa-icon icon="trash"></fa-icon>
              </button>
                        </li>
                    </ul>
                    <div id="upload-other-files-trigger">
                        <button (click)="fileInput.click()">
              <fa-icon icon="file-image"></fa-icon>{{ 'specific-request-dialog.add-another-file' | translate }}
            </button>
                        <span class="input-container">
              <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
            </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="footer">
            <p>
                <a href="https://www.zyva.immo/" target="_blank">
          {{ 'visit-zyva-request-dialog.footer' | translate }} <span>{{ 'visit-zyva-request-dialog.price' |
            translate}}</span>
        </a>
            </p>
        </div>
    </div>
    <div id="dialog-footer">
        <button (click)="cancelVisit()" class="btn">{{ 'common.cancel' | translate }}</button>
        <button (click)="AddVisit()" [disabled]="!zyvaFormGroup.valid" class="btn-primary">{{ 'specific-request-dialog.send-request-btn' | translate }}</button>
    </div>
</div>