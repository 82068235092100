import { ExecFileOptionsWithStringEncoding } from 'child_process';
import { Address, AddressDB } from './address';

export class User {
  userid: string;
  firstname: string;
  lastname: string;
  email: string;
  companyname: string;
  phone: string;
  gsm: string;
  indicator: string;
  indicatorphone: string;
  address: Address;
  sendByMail: boolean;
  userType: UserType;
  hasCommission: boolean;
  pricingPlan: number;
  roles: Role[];
  hasImmoAccess: boolean;
  tva = '';
  status?: number;
  customPrices: CustomPrice[];
  notifications: string[];
  unpaidAmount: number;
  unpaidCount: number;
  unsignedBdc: number;
  pendingOrders: number;
  unpaidCallbackAmount: number;
  unpaidCallbackCount: number;
  yearlyOrdersCount: number;
  ongoingOrders: number;
  newOrdersCount: number;
  ordersCount: number;
  latestOrdersCount: number;
  suspensOrdersCount: number;
  canceledOrdersCount: number;
  orderId: string;
  consumerAddresses: Address[];

  constructor() {
    this.address = new Address();
  }
}

export class CustomPrice {
  constructor(public price, public commission, public region, public estateType, public productTypeId) {}
}

export enum UserType {
  Particulier = 1688,
  Agence = 1689,
  Certificateur = 1690,
  Notaire = 8122,
  Installateur = 1691,
  Avocat = 1692,
  Syndic = 1693,
  Promoteur = 1694,
  Public_AIS = 1695,
  Multipropriétaire = 1696,
  Banque = 1697,
  Architecte = 1698,
  DesignOffice = 1699,
  Certifier = 1700,
  Installateur_Chauffage = 1701,
  General_Contractor = 1702,
  Surveyor = 1703,
  Expert_Immobilier = 1704,
  Administration_Communale = 1705,
  OrderRequestor = 1687,
  Other = 0
}

export enum ReasonType {
  Vente = 0,
  Location = 1,
  Renovation = 2,
  Controle = 3
}

export enum UserRole {
  ImmoAccess = 'c8909f2b-1e5d-4758-b920-a2b4f6114c90'
}

export class UserDB {
  UserID: string;
  FirstName: string;
  Name: string;
  Email: string;
  PhoneNumber: string;
  Address: AddressDB;
  Consumer: ConsumerDB;
  Roles: Role[];
  Notifications: string[];
  UnpaidAmount: number;
  UnpaidCount: number;
  UnsignedBdc: number;
  UnpaidCallbackAmount: number;
  UnpaidCallbackCount: number;
  YearlyOrdersCount: number;
  OngoingOrders: number;
  PendingOrders: number;
  NewOrdersCount: number;
  OrdersCount: number;
  LatestOrdersCount: number;
  CanceledOrdersCount: number;
  SuspensOrdersCount: number;
  OrderID: string;
  ConsumerAddresses: AddressDB[];

  constructor() {
    this.Address = new AddressDB();
  }
}

export class Role {
  constructor(public RoleID: UserRole, public Name: string) {}
}

export interface Notification {
  NotificationId: string;
  UserId: string;
  User: User;
  CommentId: string;
  Comment: Comment;
}

export class ConsumerDB {
  ConsumerType: number;
  Commission: boolean;
  Status: number;
  ReceiveByEmail: boolean;
  ConsumerPrices: ConsumerPriceDB[];

  constructor() {
    this.ConsumerType = 1688;
    this.Commission = false;
    this.Status = 0;
    this.ReceiveByEmail = true;
  }
}

class ConsumerPriceDB {
  constructor(
    public ConsumerAmount,
    public ConsumerCommission,
    public ConsumerRegion,
    public EstateType,
    public ProductTypeId
  ) {}
}

export class Contact {
  description: string;
  firstname: string;
  lastname: string;
  phone: string;
  gsm: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.phone = '';
    this.description = '';
    this.gsm = '';
  }
}

export class Owner {
  firstname: string;
  lastname: string;
  phone: string;
  gsm: string;
  indicator: string;
  indicatorphone: string;
  email: string;
  tva?: string;
  companyname?: string;
  title?: string;

  constructor() {
    this.firstname = '';
    this.lastname = '';
    this.phone = '';
    this.gsm = '';
    this.indicator = '';
    this.indicatorphone = '';
    this.email = '';
    this.tva = '';
    this.companyname = '';
    this.title = '';
  }
}

export class UserForm extends Owner {
  tva?: string;
  address?: Address;
  companyname: string;
  constructor() {
    super();
    this.address = new Address();
  }
}
