import { Component, ViewChild, ElementRef , OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-profile-comissions',
  templateUrl: './profile-comissions.component.html',
  styleUrls: ['./profile-comissions.component.scss']
})
export class ProfileComissionsComponent implements OnInit {
  @ViewChild('alert', { static: true }) alert: ElementRef;
  comissions$: BehaviorSubject <any>;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getComissions();
    this.comissions$=this.dataService.comissions$
    // this.dataService.getComissions().subscribe(data =>{
    //   this.comissions=data;
    //   console.log(data)
    //   console.log(this.comissions)
    // })

  }
  closeAlert() {
    this.alert.nativeElement.classList.remove('show');
  }
}
