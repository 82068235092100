import { Component, OnInit , Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators , FormControl } from '@angular/forms';
import {FunctionType} from '../../../core/enums/function-type.enum'
import {TitleType} from '../../../core/enums/title.enum'
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss']
})
export class AddContactDialogComponent implements OnInit {
  newContact: FormGroup;
  functionType = FunctionType;
  titleType = TitleType;
  displayError = false;
  myModel=true;
  myModel2=true;
  constructor(private fb: FormBuilder,private dataService: DataService,@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.newContact = this.fb.group({
      Function: [0, [Validators.required]],
      Title: ['', [Validators.required]],
      Name: ['', [Validators.required]],
      FirstName: ['', [Validators.required]],
      TelNumber: ['', [Validators.required]],
      PhoneNumber: ['',[Validators.required] ],
      Email: ['', [Validators.required, Validators.email],],
      UnsubscribedOrderInformations: [false,],
      UnsubscribedEmailReports: [false,],
    });
  }
  addNewContact(){
    if(this.newContact.status!="VALID"){
      this.displayError=true;
    }
    else{
      this.newContact.get('UnsubscribedOrderInformations').setValue(!this.newContact.get('UnsubscribedOrderInformations').value)
      this.newContact.get('UnsubscribedEmailReports').setValue(!this.newContact.get('UnsubscribedEmailReports').value)
      this.displayError=false;
      this.dataService.createContact(this.newContact.value).subscribe(data => {
        if(data.status=204){
          this.data.dialog.closeAll()
          this.dataService.getProfile();
        }

    })
  }
}

}

