import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-actifs-notaires',
  templateUrl: './actifs-notaires.component.html',
  styleUrls: ['./actifs-notaires.component.scss']
})
export class ActifsNotairesComponent implements OnInit {
  @Input() activeFilter;
  constructor() { }

  ngOnInit(): void {
  }

}
