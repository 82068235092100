import { Component, OnInit , Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddContactDialogComponent} from '../add-contact-dialog/add-contact-dialog.component'
import { DataService } from '../../../core/services/data.service';
import {FunctionType} from '../../../core/enums/function-type.enum'
import {DeleteContactComponent} from '../delete-contact/delete-contact.component'
import {EditContactDialogComponent} from '../edit-contact-dialog/edit-contact-dialog.component'
@Component({
  selector: 'app-profile-pro-contacts',
  templateUrl: './profile-pro-contacts.component.html',
  styleUrls: ['./profile-pro-contacts.component.scss']
})
export class ProfileProContactsComponent implements OnInit {
  @Input() profile;
  functionType= ['Manager','','Member','Planning','Accounting','MainContact'];
  constructor(  public dialog: MatDialog,private dataService: DataService) { }

  ngOnInit(): void {
  }
  addContact(){
    this.dialog.open(AddContactDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog }
    });
  }
  editContact(mainContact , contact , id){
    this.dialog.open(EditContactDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog , profile : this.profile , mainContact: mainContact, contact : contact , id:id }
    });
  }
  popDelete(id){
    this.dialog.open(DeleteContactComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog , profile : this.profile , id: id, dataService : this.dataService }
    });
  }
  deleteContact(id){
    this.dataService.deleteContact(id).subscribe(data =>  console.log(data))
  }

}
