<div class="orders white">
  <div class="row spaceRowww">
    <div class="row specRow">
    <span class="bold dFle col-lg-4 col-md-12 col-12">{{ 'stats.nbrCommande' | translate }}</span>
      <div class="flex col-lg-8 col-md-12 col-12">
        <a #sixMonth class="active tab" (click)="setTab('sixMonth',this.sixMonthData)">{{ 'stats.sixMonth' | translate }}</a>
        <a #year class="tab" (click)="setTab('year',this.yearData)">{{ 'stats.thisYear' | translate }}</a>
        <a #all class="tab" (click)="setTab('all',this.allData)">{{ 'stats.all' | translate }}</a>
    </div>
  </div>
  <div class="row pt3">

    <ngx-charts-line-chart
    class="col-12"
    [view]="[containerRef.offsetWidth > 540 ? containerRef.offsetWidth -40 : containerRef.offsetWidth-25,450]"
    [results]="data"
    [xAxis]="xAxis"
    [yAxis]="true"
    [scheme]="colorScheme"
    >
  </ngx-charts-line-chart>
</div>
  </div>
</div>

<div class="orders white">
  <div class="row spaceRowww">
    <div class="specRow row">
    <span class="bold dFle col-lg-4 col-md-12 col-12">{{ 'stats.typeOrder' | translate }}</span>
      <div class="flex col-lg-8 col-md-12 col-12">
        <a *ngIf="this.monthTypeData.length" #monthType [ngClass]="this.monthTypeData.length ?'active tab':'tab'"  (click)="setTabType('monthType',this.monthTypeData)">{{ 'stats.thisMonth' | translate }}</a>
        <a #sixMonthType [ngClass]="this.monthTypeData.length ?'tab':'active tab'" class="tab" (click)="setTabType('sixMonthType',this.sixMonthTypeData)">{{ 'stats.sixMonth' | translate }}</a>
        <a #yearType class="tab" (click)="setTabType('yearType',this.yearTypeData)">{{ 'stats.thisYear' | translate }}</a>
        <a #allType class="tab" (click)="setTabType('allType',this.allTypeData)">{{ 'stats.all' | translate }}</a>
    </div>
  </div>
    <div #containerRef class="row pl3 pt3">
      <ngx-charts-pie-chart
      [view]="[containerRef.offsetWidth,450]"
      [scheme]="typeOrderColor"
      [results]="typeOrdersData"
      [gradient]="true"
      [legend]="false"
      [labels]="containerRef.offsetWidth > 540 ? true : false"
      [doughnut]="false"
      [explodeSlices]="false"
      class="col-12 speCol"
      [trimLabels]="containerRef.offsetWidth > 656 ? false : true">
    </ngx-charts-pie-chart>
    </div>
    <div class="row spaceRow">
      <div *ngFor="let product of typeOrdersData" class="col-lg-4 col-md-6 col-12">
        <div class="blockStats">
          <div class="row" style="align-items: center;">
            <div class="col-md-2 col-2">
              <svg class="svg" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5.67568" cy="6.35134" r="4.25676" stroke="#FFB946" stroke-width="2.83784" />
              </svg>
            </div>

          <span class="col-md-7 col-6">
            {{" "+product.value+" " + product.name}}
          </span>
          <span style="display:flex;align-items: center;flex-wrap:wrap;justify-content: center;" class="col-md-3 col-4">
              <strong> {{this.getPourcentage(product.value) |number : '1.0-1'}}%</strong>
          </span>
        </div>
        </div>
      </div>
    </div>
</div>
</div>
