<div class="main white">
  <div style="align-items: flex-end;" class="row spaceRow">
 <div class="col-md-6 col-12">
 <h3 class="bold titleStats"> {{stats.ConsumerName}} </h3>
 <div class="row m0">
 <svg *ngIf="isActive" class="svgDNone" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
   <rect width="40" height="40" rx="10" fill="#9AC356"/>
   <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 13.3334C21.8411 13.3334 23.3335 14.8258 23.3335 16.6667C23.3335 18.5077 21.8411 20 20.0002 20C18.1592 20 16.6668 18.5077 16.6668 16.6667C16.6668 14.8258 18.1592 13.3334 20.0002 13.3334ZM20.0002 15C19.0797 15 18.3335 15.7462 18.3335 16.6667C18.3335 17.5872 19.0797 18.3334 20.0002 18.3334C20.9206 18.3334 21.6668 17.5872 21.6668 16.6667C21.6668 15.7462 20.9206 15 20.0002 15ZM20.0002 20.8334C22.2252 20.8334 26.6668 21.9417 26.6668 24.1667V26.6667H13.3335V24.1667C13.3335 21.9417 17.7752 20.8334 20.0002 20.8334ZM20.0001 22.4167C17.5251 22.4167 14.9167 23.6334 14.9167 24.1667V25.0834H25.0834V24.1667C25.0834 23.6334 22.4751 22.4167 20.0001 22.4167Z" fill="white"/>
   <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="13" y="13" width="14" height="14">
   <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 13.3334C21.8411 13.3334 23.3335 14.8258 23.3335 16.6667C23.3335 18.5077 21.8411 20 20.0002 20C18.1592 20 16.6668 18.5077 16.6668 16.6667C16.6668 14.8258 18.1592 13.3334 20.0002 13.3334ZM20.0002 15C19.0797 15 18.3335 15.7462 18.3335 16.6667C18.3335 17.5872 19.0797 18.3334 20.0002 18.3334C20.9206 18.3334 21.6668 17.5872 21.6668 16.6667C21.6668 15.7462 20.9206 15 20.0002 15ZM20.0002 20.8334C22.2252 20.8334 26.6668 21.9417 26.6668 24.1667V26.6667H13.3335V24.1667C13.3335 21.9417 17.7752 20.8334 20.0002 20.8334ZM20.0001 22.4167C17.5251 22.4167 14.9167 23.6334 14.9167 24.1667V25.0834H25.0834V24.1667C25.0834 23.6334 22.4751 22.4167 20.0001 22.4167Z" fill="white"/>
   </mask>
   <g mask="url(#mask0)">
   </g>
 </svg>
   <span *ngIf="isActive" class="bold pl-2"> {{ 'stats.actif' | translate }} <br> <span class="normal"> {{stats.last12Year}} {{ 'stats.actif2' | translate }} </span></span>
   <span *ngIf="!isActive" class="bold">  <span class="normal"> {{stats.last12Year}} {{ 'stats.actif2' | translate }} </span></span>
 </div>
 </div>
 <div class="col-md-6 col-12 mainLayout">
 <span class="green bold pr-2 pt-3"> {{stats.OrderAmount}} </span>
 <span class="bold bigger pt-2">{{ 'stats.orderNumber' | translate }} <br> {{'stats.orderNumber2'| translate }}</span>
 </div>
  </div>
 </div>
