export class Paper {
  totalPrice: number = this.quantity * this.unitPrice;
  constructor(public format: string, public color: string, public quantity: number, public unitPrice: number) {}
}

export class Panel {
  totalPrice: number = this.quantity * this.unitPrice;
  id: number = this.format === 'XL' ? 103 : 102;
  constructor(
    public format: string,
    public saleType: string,
    public quantity: number,
    public unitPrice: number,
    public language: string
  ) {}
}
