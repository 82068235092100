import * as PRODUCTS from '../data/products';

export class Product {
  id: number;
  name: string;
  price: string;
  reduction: string;
  commission: number;

  constructor(id, price, reduction, commisison = 0) {
    this.id = id;
    this.price = price;
    this.reduction = reduction;
    this.name = this.SetName(id);
    this.commission = commisison;
  }

  private SetName(id: number): string {
    switch (id) {
      case PRODUCTS.URGENT_PRO:
        return 'Frais urgence NVN';
      case 1:
        return 'Certificat PEB';
      case 6:
        return 'Contrôle citerne aérienne';
      case 4:
        return 'Contrôle électrique';
      case 8:
        return 'Contrôle électrique supplémentaire';
      case 5:
        return 'Réduction';
      case 9:
        return 'Contrôle citerne - enterrée';
      case 6:
        return 'Contrôle citerne - aérienne';
      case PRODUCTS.EXTRA:
        return 'Frais de dossier Certinergie';
      case 11:
        return 'PEB Partiel';
      case 13:
        return 'Déplacement clé';
      case 14:
        return 'Plan 2D';
      case 15:
        return 'Contrôle gaz';
      case 16:
        return 'Contrôle gaz supplémentaire';
      case 17:
        return 'Plan isométrique';
      case 18:
        return 'Photo [HDR]';
      case 19:
        return 'Photos aériennes';
      case 20:
        return 'Vidéo';
      case 21:
        return 'Visite 360°';
      case 26:
        return 'Plan 3D';
      case 27:
        return 'Descriptif';
      case 28:
        return 'PEB chauffage type 1';
      case 29:
        return 'Panneau Pack panneau';
      case 30:
        return 'Pack panneau avec support';
      case 32:
        return 'Placement de vos panneaux';
      case 33:
        return 'Placement de vos panneaux avec support';
      case 34:
        return 'Code SMS';
      case 49:
        return 'PEB chauffage type 2';
      case 50:
        return 'Entretien chaudière';
      case 51:
        return 'Contrôle combi peb elec';
      case 55:
        return 'Contrôle combi gaz élec';
      case 52:
        return 'Média combi';
      case 53:
        return 'Immo light combi';
      case 54:
        return 'Immo combi';
      case 100:
        return 'Contrôle Gaz périodique';
      case 101:
        return 'Photo vidéo drone';
      case 102:
        return 'Commande panneaux';
      case 11111:
        return 'Certificat PEB';
      case 10000:
        return 'Publication standard IPL';
      case 10001:
        return 'Publication Immoweb';
      case 10002:
        return 'Mise en ligne Immovlan';
      case 10005:
        return 'Frais de dossier';
      case 10003:
        return 'Mise en ligne notaire.be';
      case 10006:
        return 'Supplément publication IPL + BIDDIT.be';
      case 10023:
        return 'Visite Ad Valoris';
      case 10024:
        return 'Vente forcée serrurier';
      case 10025:
        return 'Publication sur l’Avenir';
      case 10026:
        return 'Publication sur Proximag Andenne';
      case 10027:
        return 'Publication sur Proximag Ciney/Condroz';
      case 10028:
        return 'Publication sur Proximag Dinant/Beauraing';
      case 10029:
        return 'Publication sur Proximag Gembloux';
      case 10030:
        return 'Publication sur Proximag Namur';
      case 10031:
        return 'Publication sur Proximag Philippeville';
      case 10032:
        return 'Publication sur Proximag Couvin/Chimay';
      case 10033:
        return 'Publication sur Proximag Ciney/Dinant/Beauraing';
      case 10034:
        return 'Publication sur Proximag Charleroi';
      case 10035:
        return 'Publication sur Proximag Brabant Wallon Est';
      case 10036:
        return 'Publication sur Proximag Huy';
      case 10037:
        return 'Publication sur Proximag Hesbaye';
      case 10038:
        return 'Publication sur Proximag Famenne';
      case 10039:
        return 'Publication sur Proximag Ardennes';
      case 10040:
        return 'Visite Gil 4 semaines';
      case 10041:
        return 'Visite Gil 6 semaines';
      case 10042:
        return 'Visite Gil illimité 3 mois';
      case 10043:
        return 'Visite Gil option - 2 visites période enchère';
      case 10044:
        return 'Visite Gil option - visites illimitées pendant période d’enchère';
      case 10002:
        return 'Mise en ligne Immovlan';
      case 10003:
        return 'Mise en ligne notaire.be';
      case 10023:
        return 'Visite Ad Valoris';
      case 10040:
        return 'Visite Gil 4 semaines';
      case 10041:
        return 'Visite Gil 6 semaines';
      case 10042:
        return 'Visite Gil illimité 3 mois';
      case 10043:
        return 'Visite Gil option - 2 visites période enchère';
      case 10044:
        return 'Visite Gil option - visites illimitées pendant période d’enchère';
      case 10056:
        return 'Boost Facebook/Instagram';
      case PRODUCTS.AUDIT:
        return 'Audit';
      case PRODUCTS.ELEC_PHOTOVOLTA:
        return 'Réception RGIE photovoltaïque';
      case PRODUCTS.AMIANTE:
        return 'Amiante';
      case PRODUCTS.ELEC_NONDOM_MAX125A:
        return 'Elec non résidentiel (<125A)';
      case PRODUCTS.ELEC_NONDOM_OVER125A:
        return 'Elec non résidentiel (>125A)';
      case PRODUCTS.NETTMAISON:
        return 'Nettoyage maison';
      case PRODUCTS.NETTJARDIN:
        return 'Nettoyage Jardin';
      case PRODUCTS.RESP_PEB:
        return 'Responsabilité PEB / Conseil PEB';
      case -2:
        return 'Web vitrine du bien';
      case -41:
        return 'Publication à la carte';
      case -42:
        return 'Panneau XL';
      default:
        return 'wrong id';
    }
  }
}
