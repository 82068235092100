import { Address } from './address';

export class Document {
  firstName: string;
  lastName: string;
  tva: string;
  companyName: string;
  address: Address;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.tva = '';
    this.companyName = '';
    this.address = new Address();
  }
}
