/**
 * ENUM for Packs
 **/
export enum Products {
  PEB = 1,
  PEB_Partiel = 11,
  ELEC = 4,
  ELEC_SUPP = 8,
  CITERNE_ENTERREE = 9,
  CITERNE_AERIENNE = 6,
  PLAN = 14,
  GAZ_CONTROLE = 15,
  GAZ_SUPP = 16,
  ISO = 17,
  PHOTO = 18,
  PHOTODRONE = 19,
  VIDEO = 20,
  VISITE360 = 21,
  DESCRIPTIF = 27,
  PLAN3D = 26,
  PEBCHAUFFAGE = 28,
  PEB_ELEC = 51,
  MEDIA = 52,
  IMMO_LIGHT = 53,
  IMMO = 54,
  GAZ_ELEC = 55,
  LIVRET = -7,
  AFFICHETTE = -8,
  TRIO_A = 59,
  TRIO_E = 60
}
