import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-specific-request-dialog',
  templateUrl: './specific-request-dialog.component.html',
  styleUrls: ['./specific-request-dialog.component.scss']
})
export class SpecificRequestDialogComponent implements OnInit {
  devisFormGroup: FormGroup;
  sent: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private sendEmailService: SendEmailService,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; estateId: number }
  ) {}

  ngOnInit() {
    this.sent = false;

    this.devisFormGroup = this._formBuilder.group({
      firstName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      lastName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      phone: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      email: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      userStreet: [''],
      userNum: [''],
      userZip: [''],
      userCity: [''],
      remarks: ['']
    });
  }

  close() {
    this.data.dialog.closeAll();
  }

  onSubmit() {
    if (this.devisFormGroup.invalid) {
      this.logger.log('invalid form');
      return;
    }
    this.logger.log(this.devisFormGroup.value);
    this.sendEmailService
      .sendDevis(this.devisFormGroup.value, this.data.estateId === 0 ? 'bien +500m²' : 'non résidentiel +250m²')
      .subscribe(
        val => {
          this.logger.log(`🚀`);
          this.logger.log('Devis envoyé avec succès', val);
          this.sent = true;
        },
        response => {
          this.logger.log('😰 POST Devis error', response);
          this.sent = false;
        }
      );
  }
}
