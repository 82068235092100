export interface VenteInfo {
  street: string;
  num: string;
  city: string;
  zip: string;
  division: string;
  section: string;
  numCadastre: string;
  rcni: string;
  comment: string;
  references: string;
  ventePub: {
    venteStatut: {
      id: number;
      name: string;
    };
    date: Date;
    date_DateTime: Date;
    startPrice: number;
  };
  greAgre: {
    askedPrice: number;
  };
  biddit: {
    venteStatut: {
      id: number;
      name: string;
    };
    date: Date;
    venteForced: number;
    venteDate_DateTime: Date;
    startDate: Date;
    startDate_DateTime: Date;
    endDate: Date;
    endDate_DateTime: Date;
    adjuDate_DateTime: Date;
    infoDate_DateTime: Date;
    miseAPrix: number;
    minPrice: number;
  };
}

export const defaultVenteInfo: VenteInfo = {
  street: '',
  num: '',
  city: '',
  zip: '',
  division: '',
  section: '',
  numCadastre: '',
  rcni: '',
  comment: '',
  references: '',
  ventePub: {
    venteStatut: {
      id: 0,
      name: 'Première séance'
    },
    date: null,
    startPrice: 0,
    date_DateTime: null
  },
  greAgre: {
    askedPrice: 0
  },
  biddit: {
    venteStatut: {
      id: 0,
      name: 'Vente online'
    },
    date: null,
    venteForced: -1,
    venteDate_DateTime: null,
    startDate: null,
    startDate_DateTime: null,
    endDate: null,
    endDate_DateTime: null,
    adjuDate_DateTime: null,
    infoDate_DateTime: null,
    miseAPrix: 0,
    minPrice: 0
  }
};
