export const immoOrCtrlOptions = [
  {
    id: -1,
    txt: 'Votre demande concerne ?',
    fileName: 'user-type'
  },
  {
    id: 0,
    txt: 'Des contrôles légaux',
    fileName: 'certinergie-logo_squared'
  },
  {
    id: 2,
    txt: 'Des panneaux',
    fileName: 'reason-controle'
  },
  {
    id: 1,
    txt: 'Des services immo',
    fileName: 'reason-immo'
  }
];

export const mdnOptions = [
  {
    id: -1,
    txt: 'Votre demande concerne ?',
    fileName: 'user-type'
  },
  {
    id: 1,
    txt: 'Des contrôles légaux',
    fileName: 'reason-controle'
  },
  {
    id: 2,
    txt: 'Des panneaux',
    fileName: 'service-panneau-colle'
  },
  {
    id: 3,
    txt: 'Services MDN',
    fileName: 'service-descriptif'
  }
];

export const nvnOptions = [
  {
    id: -1,
    txt: 'Votre demande concerne ?',
    fileName: 'user-type'
  },
  {
    id: 1,
    txt: 'Des contrôles légaux',
    fileName: 'reason-controle'
  },
  {
    id: 2,
    txt: 'Des panneaux',
    fileName: 'reason-controle'
  }
];

export const liegeOptions = [
  {
    id: -1,
    txt: 'Votre demande concerne ?',
    fileName: 'user-type'
  }
,
  {
    id: 1,
    txt: 'Des contrôles légaux',
    fileName: 'reason-controle'
  }
];
