import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';
import { Error404Component } from './error404/error404.component';
import { OrderResolver } from './order-steppers/order-resolver.service';
import { NotaryServicesComponent } from './notary-services/notary-services.component';
import { QrCodeComponent } from './qr-code/qr-code.component';

import { PublicShareResolver } from './public-share/public-share-resolver.service';
import { ProfileComponent } from './client-space/profile/profile/profile.component';
import { ListingNotaireComponent } from './client-space/listing-notaire-stats/listing-notaire/listing-notaire.component';
import { ProfileComissionsComponent } from './client-space/comissions/profile-comissions/profile-comissions.component';
import { StatsComponent } from './client-space/listing-notaire-stats/stats/stats.component';
import { AuthenticationGuard } from './core/guard/auth.guard';
const routes: Routes = [
  { path: '', component: OrderSteppersComponent, resolve: { prices: OrderResolver } },
  { path: 'notary-services',  canActivate: [AuthenticationGuard], canActivateChild: [AuthenticationGuard], component: NotaryServicesComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'listing', component: ListingNotaireComponent },
  { path: 'stats', component: StatsComponent },
  {
    path: 'public/:orderid',
    resolve: { order: PublicShareResolver },
    loadChildren: () => import('./public-share/public-share.module').then(m => m.PublicShareModule)
  },
  { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  {
    path: 'customer',
    loadChildren: () => import('./client-space/client-space.module').then(m => m.ClientSpaceModule)
  },
  {
    path: 'site',
    loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule)
  },
  { path: 'qr/:qrid', component: QrCodeComponent },
  { path: 'error', component: Error404Component },
  { path: 'comissions', component: ProfileComissionsComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled',  onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [OrderResolver, PublicShareResolver]
})
export class AppRoutingModule {}
