import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { OrderInfoDTO } from '../core/models/OrderInfoDTO';
import { map, first, tap } from 'rxjs/operators';

@Injectable()
export class PublicShareResolver implements Resolve<OrderInfoDTO> {
  constructor(private dataService: DataService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<OrderInfoDTO> {
    const id = route.paramMap.get('orderid');
    return this.dataService.publicOrderInfo(id).pipe(first());
  }
}
