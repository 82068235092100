import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit, OnDestroy {
  private routeSub: Subscription;
  private routeParamSub: Subscription;
  qrid: string;
  apiUrl = 'https://certicore.azurewebsites.net/api/v1/misc/qr/url/';
  link?: string = null;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.routeParamSub = this.route.queryParamMap.subscribe(qParams => {
        const link = qParams.get('link') ? qParams.get('link') : null;
        this.qrid = params['qrid'];
        this.http.get<{ url: string }>(this.apiUrl + `${this.qrid}?link=${link}`).subscribe(res => {
          this.link = res.url;
          window.location.href = this.link;
        });
      });
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.routeParamSub.unsubscribe();
  }
}
