<div class="urgence-pro-action">
  <mat-checkbox #checkx (change)="urgencePro(checkx.checked)" [checked]="urgencePro$ | async">
    <b>{{ 'section-money.urgencePro.label-sub' | translate }}</b> {{ 'section-money.urgencePro.label' | translate }}
  </mat-checkbox>
  <app-tooltip
    [tooltipPosition]="'top'"
    [title]="'section-money.urgencePro.tooltip-title' | translate"
    [content]="'section-money.urgencePro.tooltip-content' | translate"
  ></app-tooltip>
</div>
