<div class="profile-particulier">
  <h1 class="profileTitle">Informations sur l'utilisateur</h1>
  <div class="content">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Nom</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Prénom</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Titre</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">GSM</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="Dupont" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="" class="profileRep" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Email</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Date de naissance</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Numéro national</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Entreprise</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="jean@gmail.com" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" class="profileRep" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Numéro de TVA</span>
        </div>
        <div class="col-lg-3 col-md-3">
          <span class="profileSubtitle">Langue</span>
        </div>
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="4655481496" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3">
          <input type="text" value="Fr-be" class="profileRep" />
        </div>
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-3 col-md-3">
          <button class="btn-primary link-devis">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="adress">
    <h1 class="profileTitle">Adresses</h1>
    <h3 class="profileSubtitle content">Adresse principal</h3>
    <div>
      <div *ngIf="profile.MainAddress">
        <div class="row content">
          <div class="col-2 profileSubtitle">
            Pays
          </div>
          <div class="col-2 profileSubtitle">
            Ville
          </div>
          <div class="col-2 profileSubtitle">
            Code Postal
          </div>
          <div class="col-2 profileSubtitle">
            Rue
          </div>
          <div class="col-2 profileSubtitle">
            numéro
          </div>
          <div class="col-2 profileSubtitle">
            numéro de boite
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.Country }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.City }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.PostalCode }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.Street }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.Number }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ profile.MainAddress.BoxNumber }}" />
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="profile.Addresses.length && profile.Addresses[0].Street != profile.MainAddress.Street"
      class="row content"
    >
      <h3 class="profileSubtitle content">Autres adresses</h3>
      <div class="col-2 profileSubtitle">Pays</div>
      <div class="col-2 profileSubtitle">Ville</div>
      <div class="col-2 profileSubtitle">Code Postal</div>
      <div class="col-2 profileSubtitle">Rue</div>
      <div class="col-2 profileSubtitle">Numéro</div>
      <div class="col-2 profileSubtitle">Boite</div>
    </div>
    <div *ngIf="profile.Addresses.length">
      <div *ngFor="let adress of profile.Addresses">
        <div *ngIf="adress.Street !== profile.MainAddress.Street" class="row content">
          <div class="col-2">
            <input type="text" value="{{ adress.Country }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ adress.City }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ adress.PostalCode }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ adress.Street }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ adress.Number }}" />
          </div>
          <div class="col-2">
            <input type="text" value="{{ adress.BoxNumber }}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
