import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { tap, retryWhen, concatMap, delay } from 'rxjs/operators';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { LoggerService } from '../services/logger.service';
@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  MIN_LOADING_TIME = 500;
  RETRY = 2;

  constructor(private loaderService: SpinnerOverlayService, private logger: LoggerService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlPath = req.url.toLowerCase();
    if (urlPath.includes('auth/refreshtoken')) {
      return next.handle(req);
    }

    const start = Date.now();
    this.showLoader();
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const end = Date.now();
          const diff = end - start;
          this.logger.log('http load time', diff);
          if (diff > this.MIN_LOADING_TIME) {
            this.onEnd();
          } else {
            setTimeout(() => this.onEnd(), this.MIN_LOADING_TIME - diff);
          }
        }
      }),
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, count) => {
            if (
              count < this.RETRY &&
              (error.status === 503 || error.status === 504 || error.status === 400 || error.status === 0)
            ) {
              this.logger.log('🚑🚑🚑 retrying ' + count);
              return of(error.status);
            }
            this.onEnd();
            return throwError(error);
          }),
          delay(3000)
        )
      )
    );
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
