import { map, switchMap, mapTo } from 'rxjs/operators';
import { Observable, of, merge, fromEvent } from 'rxjs';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { ActionTypes, SetOnline } from './../store/ui/';

@Injectable()
export class UIEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  startOnlineOfflineCheck$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.StartOnlineOfflineCheck),
    switchMap(() => {
      return merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
    }),
    map(isOnline => {
      return new SetOnline(isOnline);
    })
  );
}
