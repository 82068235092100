export class Reduction {
  id: number;
  type: string;
  price: string;

  constructor(id, price, type) {
    this.id = id;
    this.price = price;
    this.type = type;
  }
  /*
    private SetNameReduction(id: string, type: string): string {
      const reduc = 'RÉDUCTION ';
      switch (type) {
        case 'online':
          return 'CERTIFICAT PEB';
        case 'promoCode':
          return 'CONTRÔLE ÉLECTRIQUE';

        default:
          return reduc + type;
      }
    } */
}
