import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LegalForm } from '../../../core/enums/legalForm.enum';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
  info: FormGroup;
  displayError = false;
  legalForm = LegalForm;
  errorContent = 'Erreur';
  constructor(private fb: FormBuilder,private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.info = this.fb.group({
      CommercialName: [this.data.profile.CommercialName,],
      Company: [this.data.profile.Company, ],
      Email: [this.data.profile.MainUser.Email, ],
      Website: [this.data.profile.Website, ],
      VatNumber: [this.data.profile.VatNumber, ],
      Tel: [this.data.profile.MainUser.TelNumber, ],
      LegalForm: [this.data.profile.LegalForm, ],
      Gsm: [this.data.profile.MainUser.PhoneNumber, ],
    });
    this.info.controls['Email'].disable();
    this.info.controls['VatNumber'].disable();
  }
  editInfo() {
    // console.log(this.info);
    let toPatch = [
      { "op": "replace", "path": "/TelNumber", "value": this.info.get('Tel').value  },
      { "op": "replace", "path": "/PhoneNumber", "value": this.info.get('Gsm').value },
      { "op": "replace", "path": "/CommercialName", "value": this.info.get('CommercialName').value  },
      { "op": "replace", "path": "/LegalForm", "value": this.info.get('LegalForm').value },
      { "op": "replace", "path": "/Website", "value": this.info.get('Website').value }
    ]
    this.dataService.editInfo(toPatch).subscribe(data => {
      // console.log(data);
      if ((data.status = 204)) {
        console.log("good response")
        this.dataService.getProfile();
        this.data.dialog.closeAll();

      }
      else{
        this.data.dialog.closeAll();
      }
    })
  }
}
