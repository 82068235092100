<header id="dialog-header">
  <h5>
    <img src="../../../assets/images/service-paper.svg" />
    {{ 'paper-request-dialog.title' | translate }}
  </h5>
</header>
<div id="dialog-intro">
  <p>
    {{ 'paper-request-dialog.content' | translate }}
  </p>
</div>
<div id="dialog-content">
  <form>
    <div class="form-element">
      <label for="a3input">{{ 'paper-request-dialog.reams-a3' | translate }} ({{ A3.size }} {{ 'paper-request-dialog.sheets' | translate }}) | {{ A3.price }}€</label>
      <mat-form-field name="nombre" appearance="outline">
        <input
          [(ngModel)]="choice.A3"
          id="a3input"
          name="selected-quantity"
          matInput
          placeholder="{{ 'signs-request-dialog.label' | translate }}"
          min="0"
          type="number"
        />
      </mat-form-field>
    </div>
    <div class="form-element">
      <label for="a4input">{{ 'paper-request-dialog.reams-a4' | translate }} ({{ A4.size }} {{ 'paper-request-dialog.sheets' | translate }}) | {{ A4.price }}€</label>
      <mat-form-field name="nombre" appearance="outline">
        <input
          [(ngModel)]="choice.A4"
          id="a4input"
          name="selected-quantity"
          matInput
          placeholder="{{ 'signs-request-dialog.label' | translate }}"
          min="0"
          type="number"
        />
      </mat-form-field>
    </div>
    <div class="form-element">
      <label>{{ 'paper-request-dialog.collect-place' | translate }} :</label>
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="choice.officeId" name="office" id="bureauSelect">
          <mat-option *ngFor="let office of officeChoices" [value]="office.id">
            {{ officeChoices[office.id].name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-element">
      <label>{{ 'paper-request-dialog.collect-date' | translate }}</label>
      <mat-form-field appearance="outline" id="date-picker">
        <input
          [(ngModel)]="choice.date"
          name="date-choice"
          matInput
          [matDatepicker]="picker"
          placeholder="DD/MM/YYYY"
          [min]="tomorrow"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div id="total">
    <span
      ><strong>{{ 'signs-request-dialog.info.total-label' | translate }}</strong></span
    >
    <span>{{ totalPrice }}€</span>
  </div>
</div>
<footer id="dialog-footer">
  <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
  <button (click)="sendEmail()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
</footer>
