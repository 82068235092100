export const reasons = [
  {
    id: -1,
    txt: 'Sélectionnez la raison de votre venue',
    fileName: 'reason-type'
  },
  {
    id: 3,
    txt: 'Ctrl',
    fileName: 'reason-controle'
  },
  {
    id: 0,
    txt: 'Vente',
    fileName: 'reason-vente'
  },
  {
    id: 1,
    txt: 'Location',
    fileName: 'reason-location'
  },
  {
    id: 2,
    txt: 'Reno',
    fileName: 'reason-renovation'
  }
];
