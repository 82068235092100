import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htva'
})
export class HtvaPipe implements PipeTransform {
  transform(value: number, args?: any): number {
    const valueHTVA = value / 1.21;

    let rounded = parseFloat(valueHTVA.toFixed(2));

    const y = Math.round(((valueHTVA * 100) % 10) * 100) / 100;

    if (y <= 5) {
      rounded = parseFloat(rounded.toFixed(1));
    } else {
      rounded += 0.05;
    }
    rounded = Math.floor(rounded * 10) / 10;

    return rounded;
  }
}
