import { Component, OnInit , Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-delete-adress',
  templateUrl: './delete-adress.component.html',
  styleUrls: ['./delete-adress.component.scss']
})
export class DeleteAdressComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dataService: DataService) { }

  ngOnInit(): void {
  }
  deleteAdress(id){
    this.dataService.deleteAdress(id).subscribe(data =>  {
      this.dataService.getProfile();
    })
    this.data.dialog.closeAll()

  }
  annuler(){
    this.data.dialog.closeAll()
  }

}
