export const AdressType = [
  {
    name:"HeadOffice",
    value:0
  },
  {
    name:"OperatingAddress",
    value:1
  },
  {
    name:"Other",
    value:3
  }
]
