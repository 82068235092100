import { Component, OnInit } from '@angular/core';
import { projectID } from '../core/data/theme-config';
import { mdnServices } from '../core/data';
import { PosterRequestDialogComponent } from '../dialogs/poster-request-dialog/poster-request-dialog.component';
import { SignsRequestDialogComponent } from '../dialogs/signs-request-dialog/signs-request-dialog.component';
import { PaperRequestDialogComponent } from '../dialogs/paper-request-dialog/paper-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notary-services',
  templateUrl: './notary-services.component.html',
  styleUrls: ['./notary-services.component.scss']
})
export class NotaryServicesComponent implements OnInit {
  mdnServices =
    projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege' ? mdnServices : [];

  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  serviceImgUrl(fileName) {
    let url = 'assets/images/';

    url += fileName;
    url += '.svg';

    return url;
  }

  openDialog(id) {
    if (id === -101) {
      this.dialog.open(PosterRequestDialogComponent, {
        panelClass: ['poster-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -102) {
      this.dialog.open(SignsRequestDialogComponent, {
        panelClass: ['signs-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
    if (id === -103) {
      this.dialog.open(PaperRequestDialogComponent, {
        panelClass: ['paper-request-dialog', 'full-screen-dialog'],
        data: {
          dialog: this.dialog
        },
        disableClose: true
      });
      return;
    }
  }
}
