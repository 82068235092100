<div class="editInfo">
  <h4 class="modalTitle break">{{'profile.editCompanyInfo' |translate }}</h4>
  <span id="close" (click)="this.data.dialog.closeAll()"
    ><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3941 3.90878C12.2097 3.72432 11.9106 3.72432 11.7262 3.90878L8.72021 6.91473L5.71427 3.90878C5.52981 3.72433 5.23075 3.72433 5.04629 3.90878L4.37832 4.57676C4.19387 4.76121 4.19387 5.06027 4.37832 5.24473L7.38426 8.25067L4.37846 11.2565C4.19401 11.4409 4.19401 11.74 4.37846 11.9244L5.04644 12.5924C5.23089 12.7769 5.52995 12.7769 5.71441 12.5924L8.72021 9.58661L11.726 12.5924C11.9105 12.7769 12.2095 12.7769 12.394 12.5924L13.062 11.9244C13.2464 11.74 13.2464 11.4409 13.062 11.2565L10.0562 8.25067L13.0621 5.24472C13.2466 5.06027 13.2466 4.7612 13.0621 4.57675L12.3941 3.90878Z"
        fill="#6C788D"
      />
    </svg>
  </span>
  <form *ngIf="this.data.profile" class="content" [formGroup]="info">
    <div class="row">
      <div class="col-md-12 col-12">
      <span class="modalSubtitle col-12">{{'profile.companyName' |translate }}</span>
      <mat-form-field appearance="outline" class="col-12 ptop formField">
        <input matInput required placeholder="Dénomination commerciale" formControlName="CommercialName" type="text" />
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12"></div>
    </div>


    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{'profile.companyEmail' |translate }}</span>
        <mat-form-field appearance="outline" class=" col-12 formField">
          <input matInput required readonly placeholder="joxx@xxxx.xx" formControlName="Email" type="email" />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-6">{{'profile.form' |translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <mat-label>{{'profile.form' |translate }}</mat-label>
          <mat-select formControlName="LegalForm">
            <mat-option *ngFor="let form of legalForm" [value]="form.value">
              {{ form.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  <div class="row">
    <div class="col-md-6 col-12">
      <span class="modalSubtitle col-12">{{'profile.companyWebsite' |translate }}</span>
      <mat-form-field appearance="outline" class="col-12 formField">
        <input matInput placeholder="www.xxxxxx.xx" formControlName="Website" type="text" />
      </mat-form-field>
    </div>
    <div class="col-md-6 col-12">
      <span class="modalSubtitle col-12">{{'profile.companyTVA' |translate }}</span>
      <mat-form-field appearance="outline" class="col-12 formField">
        <input readonly matInput placeholder="BE 0751 4546" formControlName="VatNumber" type="text" />
      </mat-form-field>
    </div>
  </div>

<div class="row">
 <div class="col-md-6 col-12">
  <span class="modalSubtitle col-12">{{'profile.companyPhone' |translate }}</span>
  <mat-form-field appearance="outline" class="col-12 formField">
    <input
    matInput
    required
    placeholder="02/xxx.xx.xx"
    mask="00/000.00.00"
    formControlName="Tel"
    type="text"
  />
  </mat-form-field>
 </div>
 <div class="col-md-6 col-12">
  <span class="modalSubtitle col-12">{{'profile.companyGsm' |translate }}</span>
  <mat-form-field appearance="outline" class="col-12 formField">
    <input matInput placeholder="04xx/xx.xx.xx" mask="0000000/00.00.00||0000/00.00.00" formControlName="Gsm" type="text" />
  </mat-form-field>
 </div>
</div>

    <!-- <mat-error id="errorMsg" *ngIf="displayError && this.info.status != 'VALID'">{{ this.errorContent }}</mat-error> -->
    <p id="infoEdit">Merci de nous contacter au 0800 82 171 pour modifier votre adresse email et numéro de tva.</p>
      <button class="btn btn-primary" type="submit" (click)="editInfo()"> {{'profile.editCompanyInfo' |translate }}</button>
  </form>
</div>
