<mat-accordion *ngIf="this.activeFilter=='table'" >
  <mat-expansion-panel class="wrap" [expanded]="true">
  <mat-expansion-panel-header class="accordion-unPaid">
  <mat-panel-title class="white">
    Listing de tous les notaires
  </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="content">
    <div class="row spaceRow">
      <div class="col-2 align-center"><span class="subTitle">Prénom</span></div>
      <div class="col-2 align-center"><span class="subTitle">Nom de famille</span></div>
      <div class="col-2 align-center"><span class="subTitle">Utilisateur</span></div>
      <div class="col-2 align-center"><span class="subTitle">Commandes totales</span></div>
      <div class="col-4 align-center"><span class="subTitle">Voir toutes les stats</span></div>
    </div>
    <div class="row spaceRow">
      <div class="col-2 align-center"><span class="response">David</span></div>
      <div class="col-2 align-center"><span class="response">Souery</span></div>
      <div class="col-2 align-center"><span class="response">  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#E96136"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="8" height="8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
        <span class="red pl-2">Inactif</span>
        </span>
      </div>
      <div class="col-2 align-center"><span class="response red">14</span></div>
      <div class="col-4 align-center"><svg class="click" width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="6" y="4" width="27" height="29" rx="3.5011" fill="#333463"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0834 13.625C14.3826 13.625 14.6251 13.8675 14.6251 14.1667V21.75C14.6251 22.0492 14.8676 22.2917 15.1667 22.2917H24.9167C25.2159 22.2917 25.4584 22.5342 25.4584 22.8333C25.4584 23.1325 25.2159 23.375 24.9167 23.375H15.1667C14.2693 23.375 13.5417 22.6475 13.5417 21.75V14.1667C13.5417 13.8675 13.7843 13.625 14.0834 13.625Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.102 14.7797C24.3618 14.9281 24.452 15.259 24.3036 15.5187L22.5775 18.5393C22.1932 19.2118 21.3876 19.5196 20.6528 19.2747L18.9619 18.7111C18.7467 18.6393 18.5095 18.7094 18.3677 18.8866L16.6729 21.005C16.486 21.2386 16.1452 21.2765 15.9116 21.0896C15.678 20.9028 15.6401 20.5619 15.827 20.3283L17.5217 18.2098C17.947 17.6782 18.6587 17.468 19.3045 17.6833L20.9953 18.2469C21.2403 18.3286 21.5088 18.226 21.6369 18.0018L23.363 14.9813C23.5114 14.7215 23.8423 14.6313 24.102 14.7797Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="39" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        </div>
    </div>
    <div class="row spaceRow">
      <div class="col-2 align-center"><span class="response">David</span></div>
      <div class="col-2 align-center"><span class="response">Souery</span></div>
      <div class="col-2 align-center"><span class="response">  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#E96136"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="8" height="8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
        <span class="red pl-2">Inactif</span>
        </span>
      </div>
      <div class="col-2 align-center"><span class="response red">14</span></div>
      <div class="col-4 align-center"><svg class="click" width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="6" y="4" width="27" height="29" rx="3.5011" fill="#333463"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0834 13.625C14.3826 13.625 14.6251 13.8675 14.6251 14.1667V21.75C14.6251 22.0492 14.8676 22.2917 15.1667 22.2917H24.9167C25.2159 22.2917 25.4584 22.5342 25.4584 22.8333C25.4584 23.1325 25.2159 23.375 24.9167 23.375H15.1667C14.2693 23.375 13.5417 22.6475 13.5417 21.75V14.1667C13.5417 13.8675 13.7843 13.625 14.0834 13.625Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.102 14.7797C24.3618 14.9281 24.452 15.259 24.3036 15.5187L22.5775 18.5393C22.1932 19.2118 21.3876 19.5196 20.6528 19.2747L18.9619 18.7111C18.7467 18.6393 18.5095 18.7094 18.3677 18.8866L16.6729 21.005C16.486 21.2386 16.1452 21.2765 15.9116 21.0896C15.678 20.9028 15.6401 20.5619 15.827 20.3283L17.5217 18.2098C17.947 17.6782 18.6587 17.468 19.3045 17.6833L20.9953 18.2469C21.2403 18.3286 21.5088 18.226 21.6369 18.0018L23.363 14.9813C23.5114 14.7215 23.8423 14.6313 24.102 14.7797Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="39" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        </div>
    </div>
    <div class="row spaceRow">
      <div class="col-2 align-center"><span class="response">David</span></div>
      <div class="col-2 align-center"><span class="response">Souery</span></div>
      <div class="col-2 align-center"><span class="response">  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#E96136"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="8" height="8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
        <span class="red pl-2">Inactif</span>
        </span>
      </div>
      <div class="col-2 align-center"><span class="response red">14</span></div>
      <div class="col-4 align-center"><svg class="click" width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="6" y="4" width="27" height="29" rx="3.5011" fill="#333463"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0834 13.625C14.3826 13.625 14.6251 13.8675 14.6251 14.1667V21.75C14.6251 22.0492 14.8676 22.2917 15.1667 22.2917H24.9167C25.2159 22.2917 25.4584 22.5342 25.4584 22.8333C25.4584 23.1325 25.2159 23.375 24.9167 23.375H15.1667C14.2693 23.375 13.5417 22.6475 13.5417 21.75V14.1667C13.5417 13.8675 13.7843 13.625 14.0834 13.625Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.102 14.7797C24.3618 14.9281 24.452 15.259 24.3036 15.5187L22.5775 18.5393C22.1932 19.2118 21.3876 19.5196 20.6528 19.2747L18.9619 18.7111C18.7467 18.6393 18.5095 18.7094 18.3677 18.8866L16.6729 21.005C16.486 21.2386 16.1452 21.2765 15.9116 21.0896C15.678 20.9028 15.6401 20.5619 15.827 20.3283L17.5217 18.2098C17.947 17.6782 18.6587 17.468 19.3045 17.6833L20.9953 18.2469C21.2403 18.3286 21.5088 18.226 21.6369 18.0018L23.363 14.9813C23.5114 14.7215 23.8423 14.6313 24.102 14.7797Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="39" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        </div>
    </div>
    <div class="row spaceRow">
      <div class="col-2 align-center"><span class="response">David</span></div>
      <div class="col-2 align-center"><span class="response">Souery</span></div>
      <div class="col-2 align-center"><span class="response">  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z" fill="#E96136"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="8" height="8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 6.66666C10.9209 6.66666 11.6671 7.41285 11.6671 8.33332C11.6671 9.2538 10.9209 9.99999 10.0004 9.99999C9.07993 9.99999 8.33374 9.2538 8.33374 8.33332C8.33374 7.41285 9.07993 6.66666 10.0004 6.66666ZM10.0003 7.49999C9.54009 7.49999 9.16699 7.87309 9.16699 8.33333C9.16699 8.79356 9.54009 9.16666 10.0003 9.16666C10.4606 9.16666 10.8337 8.79356 10.8337 8.33333C10.8337 7.87309 10.4606 7.49999 10.0003 7.49999ZM10.0003 10.4167C11.1128 10.4167 13.3337 10.9708 13.3337 12.0833V13.3333H6.66699V12.0833C6.66699 10.9708 8.88783 10.4167 10.0003 10.4167ZM10.0003 11.2083C8.76278 11.2083 7.45862 11.8167 7.45862 12.0833V12.5417H12.542V12.0833C12.542 11.8167 11.2378 11.2083 10.0003 11.2083Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
        <span class="red pl-2">Inactif</span>
        </span>
      </div>
      <div class="col-2 align-center"><span class="response red">14</span></div>
      <div class="col-4 align-center"><svg class="click" width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <rect x="6" y="4" width="27" height="29" rx="3.5011" fill="#333463"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0834 13.625C14.3826 13.625 14.6251 13.8675 14.6251 14.1667V21.75C14.6251 22.0492 14.8676 22.2917 15.1667 22.2917H24.9167C25.2159 22.2917 25.4584 22.5342 25.4584 22.8333C25.4584 23.1325 25.2159 23.375 24.9167 23.375H15.1667C14.2693 23.375 13.5417 22.6475 13.5417 21.75V14.1667C13.5417 13.8675 13.7843 13.625 14.0834 13.625Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.102 14.7797C24.3618 14.9281 24.452 15.259 24.3036 15.5187L22.5775 18.5393C22.1932 19.2118 21.3876 19.5196 20.6528 19.2747L18.9619 18.7111C18.7467 18.6393 18.5095 18.7094 18.3677 18.8866L16.6729 21.005C16.486 21.2386 16.1452 21.2765 15.9116 21.0896C15.678 20.9028 15.6401 20.5619 15.827 20.3283L17.5217 18.2098C17.947 17.6782 18.6587 17.468 19.3045 17.6833L20.9953 18.2469C21.2403 18.3286 21.5088 18.226 21.6369 18.0018L23.363 14.9813C23.5114 14.7215 23.8423 14.6313 24.102 14.7797Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0" y="0" width="39" height="41" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        </div>
    </div>
  </div>
  </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion *ngIf="this.activeFilter=='segmented'" >
    <mat-expansion-panel class="wrap" [expanded]="true">
    <mat-expansion-panel-header class="accordion-unPaid">
    <mat-panel-title class="white">
      Listing de tous les notaires
    </mat-panel-title>
    <mat-panel-description class="white" id="toPay">
    </mat-panel-description>
    </mat-expansion-panel-header>
      <div class="row content spaceRowSeg">
        <div class="col-lg-4 col-md-4 col-6 ">
          <div class="segmented">
          <div class="row spaceRowSeg">
            <div class="col-7 br">
              <p class="bold">David Souery</p>
              <p class="red"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                <rect width="10" height="10" rx="2.5" fill="#E96136"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="10" height="10" fill="white"/>
                </clipPath>
                </defs>
                </svg>
                 Utilisateur inactif</p>
              <button class="btn-primary">Voir tous les stats</button>
            </div>
            <div class="col-5">
              <p class="bold">Nombre de commandes total</p>
              <p class="red bigger">41</p>
            </div>
          </div>
            </div>
        </div>
      <div class="col-lg-4 col-md-4 col-6 ">
        <div class="segmented">
        <div class="row spaceRowSeg">
          <div class="col-7 br">
            <p class="bold">David Souery</p>
            <p class="red"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <rect width="10" height="10" rx="2.5" fill="#E96136"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="10" height="10" fill="white"/>
              </clipPath>
              </defs>
              </svg>
               Utilisateur inactif</p>
            <button class="btn-primary">Voir tous les stats</button>
          </div>
          <div class="col-5">
            <p class="bold">Nombre de commandes total</p>
            <p class="red bigger">22</p>
          </div>
        </div>
          </div>
      </div>
      <div class="col-lg-4 col-md-4 col-6 ">
        <div class="segmented">
        <div class="row spaceRowSeg">
          <div class="col-7 br">
            <p class="bold">David Souery</p>
            <p class="red"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <rect width="10" height="10" rx="2.5" fill="#E96136"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="10" height="10" fill="white"/>
              </clipPath>
              </defs>
              </svg>
               Utilisateur inactif</p>
            <button class="btn-primary">Voir tous les stats</button>
          </div>
          <div class="col-5">
            <p class="bold">Nombre de commandes total</p>
            <p class="red bigger">44</p>
          </div>
        </div>
          </div>
      </div>
      <div class="col-lg-4 col-md-4 col-6 ">
        <div class="segmented">
        <div class="row spaceRowSeg">
          <div class="col-7 br">
            <p class="bold">David Souery</p>
            <p class="red"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <rect width="10" height="10" rx="2.5" fill="#E96136"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="10" height="10" fill="white"/>
              </clipPath>
              </defs>
              </svg>
               Utilisateur inactif</p>
            <button class="btn-primary">Voir tous les stats</button>
          </div>
          <div class="col-5">
            <p class="bold">Nombre de commandes total</p>
            <p class="red bigger">33</p>
          </div>
        </div>
          </div>
      </div>
      <div class="col-lg-4 col-md-4 col-6 ">
        <div class="segmented">
        <div class="row spaceRowSeg">
          <div class="col-7 br">
            <p class="bold">David Souery</p>
            <p class="red"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <rect width="10" height="10" rx="2.5" fill="#E96136"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="10" height="10" fill="white"/>
              </clipPath>
              </defs>
              </svg>
               Utilisateur inactif</p>
            <button class="btn-primary">Voir tous les stats</button>
          </div>
          <div class="col-5">
            <p class="bold">Nombre de commandes total</p>
            <p class="red bigger">12</p>
          </div>
        </div>
          </div>
      </div>
    </div>
    </mat-expansion-panel>
    </mat-accordion>

