import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoggerService } from 'src/app/core/services/logger.service';
import { FileUploader } from 'ng2-file-upload';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { getUserID } from 'src/app/core/store/order/order.selectors';
import { TranslateService } from '@ngx-translate/core';
import { projectID } from '../../core/data/theme-config';

import * as PRODUCTS from '../../core/data/products';
import { Observable, of } from 'rxjs';
// const URL = '/api/';
const URL = 'https://certiservices.azurewebsites.net/api/Order/UploadPhotoNotary';

@Component({
  selector: 'app-custom-request-dialog',
  templateUrl: './custom-request-dialog.component.html',
  styleUrls: ['./custom-request-dialog.component.scss']
})
export class CustomRequestDialogComponent implements OnInit {
  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  devisFormGroup: FormGroup;
  sent: boolean;
  uploadLinks = [];
  userID: string;
  PRODUCTS = PRODUCTS;

  public uploader: FileUploader = new FileUploader({
    url: URL,
    removeAfterUpload: false,
    autoUpload: true
  });
  fileUrl = 'https://certinergie.blob.core.windows.net/certinergieclientfiles/';

  constructor(
    private _formBuilder: FormBuilder,
    private sendEmailService: SendEmailService,
    private store: Store<AppState>,
    private logger: LoggerService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog; title: string; serviceId: number; needAddress: boolean }
  ) {}

  ngOnInit() {
    this.sent = false;
    this.store.select(getUserID).subscribe(us => (this.userID = us));
    this.devisFormGroup = this._formBuilder.group({
      firstName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      lastName: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      phone: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      email: [
        {
          value: '',
          disabled: false
        },
        Validators.required
      ],
      userStreet: [{ value: '', disabled: false }, this.data.needAddress ? Validators.required : null],
      userNum: [{ value: '', disabled: false }, this.data.needAddress ? Validators.required : null],
      userZip: [{ value: '', disabled: false }, this.data.needAddress ? Validators.required : null],
      userCity: [{ value: '', disabled: false }, this.data.needAddress ? Validators.required : null],
      remarks: ['']
    });

    this.uploader.onBuildItemForm = (item, form) => {
      item.withCredentials = false;
      const id = this.userID && this.userID !== '' ? this.userID : 'bc132cfc-aeb1-4fb0-b8ba-0193f96b8230';
      form.append('userID', id);
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.uploadLinks = [...this.uploadLinks, JSON.parse(response)];
    };
  }

  close() {
    this.data.dialog.closeAll();
  }

  fileIndex(i: number) {
    return i + 1;
  }

  deletePhotoLink(index: number) {
    this.uploadLinks = [...this.uploadLinks];
    this.uploadLinks.splice(index, 1);
  }

  get titleTxt$(): Observable<string> {
    switch (this.data.serviceId) {
      case PRODUCTS.RESP_PEB:
        return this.translate.get('specific-request-dialog.dialog-content-service');
      default:
        return this.translate.get('specific-request-dialog.dialog-content-service');
    }
  }

  get remarkPlaceholder$(): Observable<string> {
    switch (this.data.serviceId) {
      case PRODUCTS.RESP_PEB:
        return this.translate.get('specific-request-dialog.rpeb-comment');
      case PRODUCTS.NETTJARDIN:
        return this.translate.get('specific-request-dialog.nett-jardin-comment');
      case PRODUCTS.NETTMAISON:
        return this.translate.get('specific-request-dialog.nett-maison-comment');
      case PRODUCTS.ELEC_NONDOM_MAX125A:
        return of('');
      case PRODUCTS.ELEC_NONDOM_OVER125A:
        return of('');
      default:
        return this.translate.get('step4.section-comment.placeholder');
    }
  }

  get fileUploadTitle$(): Observable<string> {
    switch (this.data.serviceId) {
      case PRODUCTS.RESP_PEB:
        return this.translate.get('specific-request-dialog.rpeb-fileupload-title');
      case PRODUCTS.NETTJARDIN:
        return this.translate.get('specific-request-dialog.nett-jardin-fileupload-title');
      case PRODUCTS.NETTMAISON:
        return this.translate.get('specific-request-dialog.nett-maison-fileupload-title');
      default:
        return this.translate.get('specific-request-dialog.fileupload-title');
    }
  }

  get isElecNv3() {
    return this.data.serviceId === PRODUCTS.ELEC_NONDOM_MAX125A;
  }

  get isElecNv4() {
    return this.data.serviceId === PRODUCTS.ELEC_NONDOM_OVER125A;
  }

  get isEtudePEB() {
    return this.data.serviceId === PRODUCTS.RESP_PEB;
  }

  onSubmit() {
    if (this.devisFormGroup.invalid) {
      this.logger.log('invalid form');
      return;
    }

    this.logger.log(this.devisFormGroup.value);
    this.sendEmailService
      .sendDevis(this.devisFormGroup.value, this.data.title, this.uploadLinks, this.data.serviceId, true)
      .subscribe(
        val => {
          this.logger.log(`🚀`);
          this.logger.log('Devis envoyé avec succès', val);
          this.sent = true;
        },
        response => {
          this.logger.log('😰 POST Devis error', response);
          this.sent = false;
        }
      );
  }
}
