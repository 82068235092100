<h5 *ngIf="!succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-calculate-here' | translate }}
  <small *ngIf="step1SubTitle">
    {{
      ($hasImmoAccess | async) ? ('step1.header-immo' | translate) : ('step1.header-no-immo' + projectID | translate)
    }}
  </small>
</h5>

<h5 *ngIf="succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-summary' + projectID | translate }} {{ 'section-situation.region.' + currentRegionId | translate }}
</h5>

<h5 *ngIf="step1TitleImmo" class="step-introduction">
  {{ 'step1.header-mdn' | translate }}
</h5>

<div [class]="'step-content ' + (products.length > 0 ? 'step-money-visible' : '')">
  <section class="section-situation" id="card-1" style="position: relative;">
    <div style="position: absolute; top: -125px; left: 0" #sectioImmoOrCtrl></div>
    <h6>{{ 'section-reason.title' | translate }}</h6>
    <app-big-select
      class="client-type"
      [selected]="$immoOrCtrlSelected | async"
      [items]="nvnOptions"
      [isMobile]="$isMobile | async"
      (valueChange)="setImmoOrCtrl($event, sectioImmoOrCtrl)"
      [translationString]="
        isHainautOrNamurorNVN ? 'section-immo-or-ctrl.options-mdn.' : 'section-immo-or-ctrl.options.'
      "
      [elementClass]="'clientType'"
    ></app-big-select>
  </section>
  <section *ngIf="($immoOrCtrlSelected | async) === 1" class="section-type" id="card-2">
    <h6>
      <span>{{ 'section-type.title' | translate }}</span>
      <app-tooltip
        [title]="'section-type.title-tooltip.title' | translate"
        [content]="'section-type.title-tooltip.content' | translate"
        [emphasis]="'section-type.title-tooltip.emphasis' | translate"
      ></app-tooltip>
    </h6>
    <app-big-select
      class="estate-selector"
      [selected]="$selectedEstateStyle | async"
      [items]="estateStyles"
      [isMobile]="$isMobile | async"
      (valueChange)="setEstateStyle($event)"
      [translationString]="'section-type.style.'"
      [elementClass]="'estate'"
    ></app-big-select>
    <ul class="sub-questions">
      <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.house.label' | translate }} </label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="houseControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let houseOption of houseOptions"
              (click)="setEstateType(houseOption.typeId)"
              [value]="houseOption.typeId"
            >
              {{ 'estateTpes.' + houseOption.typeId | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.flat.label' | translate }} </label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="appartControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let appartOption of appartOptions"
              (click)="setEstateType(appartOption.typeId)"
              [value]="appartOption.typeId"
            >
              {{ 'estateTpes.' + appartOption.typeId | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li *ngIf="($selectedEstateStyle | async) === 2" [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.sub.building.label' | translate }}</label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="buildingControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let immeubleOption of immeubleOptions"
              (click)="setNbOfAppartments(immeubleOption.id)"
              [value]="immeubleOption.id"
            >
              {{ immeubleOption.text }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
      <li *ngIf="($selectedEstateStyle | async) === 4" [class]="nonResControl.hasError('isEmpty') ? '' : 'selected'">
        <label>{{ 'section-type.non-res' | translate }} </label>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="nonResControl">
            <mat-option (click)="setEstateType(-1)" [value]="-1">--</mat-option>
            <mat-option
              *ngFor="let nonResOption of nonResOptions"
              (click)="setEstateType(nonResOption.typeId)"
              [value]="nonResOption.typeId"
            >
              {{ 'estateTpes.' + nonResOption.typeId | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="nonResControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
        </mat-form-field>
        <fa-icon icon="check-circle" class="icon-selected"></fa-icon>
      </li>
    </ul>
  </section>
  <section
    *ngIf="
      (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
      (!succintStep1 && (($selectedEstateType | async) !== -1 || ($selectedEstateStyle | async) === 3))
    "
    class="section-vente-type"
  >
    <h6>{{ 'section-vente-type.title' | translate }}</h6>
    <app-big-select
      class="vente-type"
      [selected]="$venteType | async"
      [items]="venteTypes"
      [isMobile]="$isMobile | async"
      (valueChange)="setVenteType($event)"
      [translationString]="'section-vente-type.vente-type.'"
      [elementClass]="'venteType'"
    ></app-big-select>
  </section>
  <section
    class="section-services"
    id="card-3"
    *ngIf="
      ((($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
        ($venteType | async) !== -1) ||
      (($userType | async) === UserTypes.Particulier &&
        ((!succintStep1 && ($selectedEstateType | async) !== -1) || ($selectedEstateStyle | async) === 3))
    "
  >
    <header>
      <h6>{{ 'section-services.title' | translate }}</h6>
    </header>
    <!-- All pack here -->
    <div *ngIf="$immoPacksWithPrices | async as immoPacks" id="notary-pack-container">
      <div
        *ngFor="let immoPack of immoPacks"
        class="services-pack-element"
        [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
      >
        <header class="pack-header subtitle">
          {{ immoPack.name }}
          <span class="pack-pricing-big"
            >{{ immoPack.totalWithReduc | htva }}<small>€ {{ 'section-money.htva' | translate }}</small></span
          >
          <span class="pack-pricing-small"
            >{{ immoPack.totalWithReduc?.toFixed(2) }}€ {{ 'section-money.tvac' | translate }}</span
          >
        </header>
        <div class="pack-details open" *ngIf="immoPack.description !== '9994'">
          <span class="pack-details-title"> {{ 'section-services.service-certinergie' | translate }}</span>
          <ul>
            <li *ngFor="let serviceID of immoPack.services" class="pack-details-element">
              <img [src]="serviceImgUrl(null, serviceID)" />
              <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
              <button
                *ngIf="displayDetailsBtn(serviceID)"
                (click)="openDetailsDialog(serviceID)"
                class="details-dialog-trigger btn-link"
              >
                <div
                  [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                  [removeSVGAttributes]="['class']"
                  [setSVGAttributes]="svgPrimaryColor"
                ></div>
              </button>
            </li>
            <ng-container *ngIf="packHasDescriptif(immoPack.services)">
              <li class="pack-details-element">
                <img src="../../assets/images/service-plaquette.svg" />
                <span>{{ 'section-money.reductions.-2' | translate }}</span>
              </li>
              <li *ngIf="currentRegionId === 1" class="pack-details-element">
                <img src="../../assets/images/service-descriptif.svg" />
                <span>{{ 'section-money.reductions.-6' | translate }} <small>(en option)</small></span>
              </li>
              <li class="pack-details-element">
                <img src="../../assets/images/service-descriptif.svg" />
                <span>{{ 'section-money.reductions.-7' | translate }}</span>
              </li>
              <li class="pack-details-element">
                <img src="../../assets/images/service-descriptif.svg" />
                <span>{{ 'section-money.reductions.-8' | translate }}</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="immoPack.servicesNotary && immoPack.servicesNotary.length > 0" class="pack-details open">
          <span class="pack-details-title">{{ 'section-services.service-notaire' | translate }}</span>
          <ul>
            <li *ngFor="let serviceID of immoPack.servicesNotary" class="pack-details-element">
              <img src="../../assets/images/mdn-namur/mdn-icon.svg" />
              <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
            </li>
          </ul>
        </div>
        <button class="btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
          {{ 'section-services.choose-this-pack' | translate }}
        </button>
        <button (click)="setImmoPack(immoPack.description)" class="btn-accent btn-selected">
          {{ 'section-services.pack-selected' | translate }}
        </button>
      </div>
    </div>
    <!-- All carte here -->
    <div *ngIf="!($showPacks | async)" class="services-carte-container">
      <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
        <!-- Section publication -->
        <mat-expansion-panel
          *ngIf="
            (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
            ($filteredPublicationsServices | async).length > 0
          "
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.pub' | translate }}
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of $filteredPublicationsServices | async"
              [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
              <app-tooltip
                *ngIf="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    service.id +
                    '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    service.id +
                    '.content'
                "
                [title]="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    service.id +
                    '.title' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    service.id +
                    '.title'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                      | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- Section controle -->
        <mat-expansion-panel
          *ngIf="($filteredServices | async).length > 0"
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.control' | translate }}
                <small>({{ howManyControlSelected }}/{{ ($filteredServices | async).length }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of $filteredServices | async"
              [class]="'option ' + serviceClass(service.id)"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">
                {{ 'section-services.services.' + service.id | translate }}
              </p>
              <app-tooltip
                *ngIf="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                      | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- <button id="show-all-services" class="btn btn-primary" (click)="toggleShowMoreServices(!showMoreServices)">
          {{
            showMoreServices
              ? ('homepage.section-services.show-less' | translate)
              : ('homepage.section-services.show-more' | translate)
          }}
        </button> -->
        <ul
          *ngIf="
            !($hasPack | async) && (hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1))
          "
          class="sub-questions"
        >
          <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
            <label>
              {{ 'section-services.peb-partiel.title' | translate }}
            </label>
            <mat-form-field appearance="outline">
              <mat-select [value]="pebPartiel">
                <mat-option (click)="setPebPartiel(false)" [value]="false">
                  {{ 'section-services.peb-partiel.no' | translate }}
                </mat-option>
                <mat-option (click)="setPebPartiel(true)" [value]="true">
                  {{ 'section-services.peb-partiel.yes' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div id="peb-partiel" *ngIf="pebPartiel">
              <div>
                <label
                  >{{ 'section-services.peb-partiel.subtitle' | translate }}
                  <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                </label>
                <mat-form-field class="peb-partiel" appearance="outline">
                  <input
                    matInput
                    #pebPartielNumber
                    (blur)="addPebNb(pebPartielNumber.value)"
                    [value]="pebNb"
                    placeholder="20190313001111"
                    name="peb-partiel"
                  />
                </mat-form-field>
              </div>
              <div>
                <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                <button class="btn btn-default" (click)="addPebPartiel()">
                  {{ 'section-services.peb-partiel.option-action' | translate }}
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="hasElec">
            <label>{{ 'section-services.elec.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbElec | async">
                <mat-option
                  *ngFor="let elecOption of elecOptions"
                  (click)="setElecSupp(elecOption)"
                  [value]="elecOption"
                >
                  {{ elecOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasGaz">
            <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbGaz | async">
                <mat-option *ngFor="let gazOption of gazOptions" (click)="setGazSupp(gazOption)" [value]="gazOption">
                  {{ gazOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasGaz">
            <label>{{ 'section-services.gaz.iso' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="$nbOfIso | async">
                <mat-option (click)="setPlanIso(0)" [value]="0">
                  {{ 'section-services.peb-partiel.no' | translate }}
                </mat-option>
                <mat-option *ngFor="let gazOption of gazOptions" (click)="setPlanIso(gazOption)" [value]="gazOption">
                  {{ gazOption }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li *ngIf="hasCiterne">
            <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
            <mat-form-field appearance="outline">
              <mat-select [value]="citerneType">
                <mat-option (click)="setService(9)" [value]="9">
                  {{ 'section-services.citerne.under' | translate }}
                </mat-option>
                <mat-option (click)="setService(6)" [value]="6">
                  {{ 'section-services.citerne.aerial' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
        <!-- Section aide à la vente -->
        <mat-expansion-panel
          *ngIf="
            ((($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
              ($selectedEstateStyle | async) !== 2 &&
              ($filteredImmoServices | async).length > 0) ||
            (($selectedEstateStyle | async) === 3 && ($filteredImmoServices | async).length > 0)
          "
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.immo' | translate }}
                <small>({{ howManyImmoSelected }}/{{ ($filteredImmoServices | async).length }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of $filteredImmoServices | async"
              [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
              <app-tooltip
                *ngIf="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    | translate) !==
                  'MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('MDN-hainaut-namur-nvn.section-services.services-tooltip.' + service.id + '.emphasis'
                      | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
</div>
<div id="step-money" *ngIf="products.length > 0">
  <div id="step-money-details">
    <ul *ngIf="!succintStep1" id="product-list">
      <li *ngIf="($packProducts | async).length > 0" class="product-list--element">
        <div class="name">
          {{ $packName | async }}
          <button (click)="showMorePack = true" *ngIf="!showMorePack" id="show-pack-details-trigger" class="btn-link">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button (click)="showMorePack = false" *ngIf="showMorePack" id="hide-pack-details-trigger" class="btn-link">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <div class="price">{{ ($packPrice | async)?.toFixed(2) }}€</div>
        <ul *ngIf="showMorePack" id="list-details">
          <li class="list-details--element" *ngFor="let product of $packProducts | async">
            <div class="name">
              {{ 'section-money.reductions.' + product.id | translate }}
              <app-tooltip
                *ngIf="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.content'
                "
                [title]="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.title' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.title'
                    ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                        product.id +
                        '.title' | translate)
                    : null
                "
                [content]="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.content' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.content'
                    ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                        product.id +
                        '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.emphasis' | translate) !==
                  'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.emphasis'
                    ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                        product.id +
                        '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
            <ng-container *ngIf="product.id === PRODUCTS.PublicationImmowebXL && product.price == '0'; else notImmoweb">
              <div class="price">{{ 'section-money.invoiced-by-immoweb' | translate }}</div>
            </ng-container>
            <ng-template #notImmoweb>
              <div *ngIf="product.price != '0'; else free" class="price">{{ product.price?.toFixed(2) }} €</div>
              <ng-template #free>
                <div class="price">{{ 'section-money.free' | translate }}</div>
              </ng-template>
            </ng-template>
          </li>
        </ul>
      </li>
      <li class="product-list--element" *ngFor="let product of $pricingProducts | async">
        <div class="name">
          {{ 'section-money.reductions.' + product.id | translate }}
          <app-tooltip
            *ngIf="
              ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                product.id +
                '.content' | translate) !==
              'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' + product.id + '.content'
            "
            [title]="
              ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' + product.id + '.title'
                | translate) !==
              'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' + product.id + '.title'
                ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.title' | translate)
                : null
            "
            [content]="
              ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                product.id +
                '.content' | translate) !==
              'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' + product.id + '.content'
                ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.content' | translate)
                : null
            "
            [emphasis]="
              ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                product.id +
                '.emphasis' | translate) !==
              'MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                product.id +
                '.emphasis'
                ? ('MDN-hainaut-namur-nvn.MDN-hainaut-namur-nvn.section-services.services-tooltip.' +
                    product.id +
                    '.emphasis' | translate)
                : null
            "
          ></app-tooltip>
        </div>
        <ng-container *ngIf="product.id === PRODUCTS.PublicationImmowebXL && product.price == '0'; else notImmoweb">
          <div class="price">{{ 'section-money.invoiced-by-immoweb' | translate }}</div>
        </ng-container>
        <ng-template #notImmoweb>
          <div *ngIf="product.price != '0'; else free" class="price">{{ product.price?.toFixed(2) }} €</div>
          <ng-template #free>
            <div class="price">{{ 'section-money.free' | translate }}</div>
          </ng-template>
        </ng-template>
      </li>
    </ul>
    <div
      class="products-reduction"
      [ngClass]="{ 'show-details': reducMoreDetails }"
      *ngIf="!succintStep1 && !($urgence | async) && ($reduction | async) !== 0"
    >
      <div class="reduction-total">
        <div class="name">
          {{ 'section-money.reduction' | translate }}
          <button *ngIf="($commission | async) === 0" class="btn-link btn-more" (click)="reducMoreDetails = true">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button *ngIf="($commission | async) === 0" class="btn-link btn-less" (click)="reducMoreDetails = false">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <div class="price">- {{ reduction }} €</div>
      </div>
      <ul class="reduction-details" *ngIf="($commission | async) === 0">
        <li *ngFor="let reduction of $reductions | async">
          <div *ngIf="reduction.id !== 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }}
          </div>
          <div *ngIf="reduction.id === 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }} "{{ promoCode }}"
          </div>
          <div>- {{ reduction.price }} €</div>
        </li>
      </ul>
    </div>
    <div *ngIf="hasDescriptif" class="final-options">
      <app-urgence-pro></app-urgence-pro>
      <app-desc-urba-checkbox></app-desc-urba-checkbox>
    </div>
    <div *ngIf="showFinalOptions" [class]="'final-options ' + (succintStep1 ? 'is-summary' : '')">
      <div *ngIf="!($urgence | async)" class="internet-payment">
        <mat-checkbox (change)="onlinePayment()" [checked]="$onlinePayment | async">
          <span class="checkbox-label">{{ 'section-money.online-payment' | translate }}</span>
        </mat-checkbox>
        <span *ngIf="$onlinePayment | async">- 10 €</span>
      </div>
      <div *ngIf="!succintStep1" class="urgence-action">
        <mat-checkbox (change)="urgence()" [checked]="$urgence | async">
          {{ 'section-money.urgence.label' | translate }}
        </mat-checkbox>
        <app-tooltip
          [tooltipPosition]="'top'"
          [title]="'section-money.urgence.tooltip-title' | translate"
          [content]="'section-money.urgence.tooltip-content' | translate"
          [emphasis]="'section-money.urgence.tooltip-emphasis' | translate"
        ></app-tooltip>
      </div>
      <div
        *ngIf="!succintStep1"
        [ngClass]="{
          'promo-reduction': true,
          valid: promoCode !== '' && ($promocodeID | async) !== '',
          invalid: promoCode !== '' && ($promocodeID | async) === ''
        }"
      >
        <span class="title">
          <fa-icon icon="check" class="valid-icon"></fa-icon>
          {{ 'section-money.promo-code.label' | translate }}
          <app-tooltip [tooltipPosition]="'top'" [content]="'section-money.promo-code.tooltip' | translate">
          </app-tooltip>
          <span class="valid-promo-code">
            {{ promoCode }}
          </span>
        </span>
        <div class="result">- {{ reductionPromoCode }} €</div>
        <div class="form-container">
          <mat-form-field class="promo-code-form" appearance="outline">
            <input
              matInput
              #pcode
              [value]="promoCode"
              placeholder="{{ 'section-money.promo-code.label' | translate }}"
            />
          </mat-form-field>
          <button class="btn btn-default" (click)="setPromoCode(pcode.value)">
            {{ 'section-money.promo-code.add' | translate }}
          </button>
          <span *ngIf="promoCode !== '' && ($promocodeID | async) === ''" class="error-msg caption">{{
            'section-money.promo-code.invalid' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="step-money-result">
    <div class="title">{{ 'section-money.total' | translate }}</div>
    <div class="result" *ngIf="!($urgence | async)">
      <span class="htva">
        <small>{{ 'section-money.htva' | translate }}</small>
        {{ priceWithoutReduction - reduction | htva }} €
      </span>
      <span>
        <small>{{ 'section-money.tvac' | translate }}</small>
        {{ (priceWithoutReduction - reduction)?.toFixed(2) }} €
      </span>
    </div>
    <div class="result" *ngIf="$urgence | async">
      <span class="htva">
        <small>{{ 'section-money.htva' | translate }}</small>
        {{ priceWithoutReduction | htva }} €
      </span>
      <span>
        <small>{{ 'section-money.tvac' | translate }}</small>
        {{ priceWithoutReduction }} €
      </span>
    </div>
  </div>
</div>
<div class="step-actions" *ngIf="products.length > 0">
  <button class="btn btn-previous" (click)="toOrdering()">
    {{ 'navigation.reset' | translate }}
  </button>
  <div class="filler"></div>
  <button *ngIf="succintStep1" class="btn btn-warning" (click)="succintStep1 = false">
    {{ 'navigation.show-options' | translate }}
  </button>
  <button id="gt-next-step-1" matStepperNext class="btn btn-primary btn-next" (click)="nextStep()">
    {{ 'navigation.next' | translate }}
  </button>
</div>
