import { Component, OnInit,Input } from '@angular/core';
@Component({
  selector: 'app-profile-particulier',
  templateUrl: './profile-particulier.component.html',
  styleUrls: ['./profile-particulier.component.scss']
})
export class ProfileParticulierComponent implements OnInit {
  @Input() profile;
  constructor() { }

  ngOnInit(): void {
    console.log(this.profile)
  }

}
