import { Component, OnInit } from '@angular/core';
import { Address } from '../core/models/address';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../interfaces';
import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import {
  SetPaymentType,
  SetActeType,
  ToggleSendByMail,
  SetRemarks,
  SetNN,
  IOrder,
  ToggleOnlinePayment,
  SetAskForExpertise,
  SetAskForOffer
} from '../core/store/order';
import { Product } from '../core/models/product';
import * as PRODUCTS from '../core/data/products';
import { SetIAgree, SetStep4Valid, IUI } from '../core/store/ui';
import { DataService } from '../core/services/data.service';
import { Region } from '../core/models/region';
import { UserType, Contact, Owner, User, UserDB } from '../core/models/user';
import { Document } from '../core/models/document';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../core/services/auth.service';
import { CongratsDialogComponent } from '../dialogs/congrats-dialog/congrats-dialog.component';
import {
  GoogleAnalyticsService,
  PurchaseEventParams,
  PurchaseEventItemParams
} from '../core/services/google-analytics.service';
import { DefaultDialogComponent } from '../dialogs/default-dialog/default-dialog.component';

import { orderMeansType } from '../core/data';
import {
  showFinalOptions,
  step4ShowByMail,
  getPricesID,
  projectID,
  displayAskForExpertise
} from '../core/data/theme-config';
import { LoggerService } from '../core/services/logger.service';
import { VENTES } from '../core/enums/vente.enum';
import { SendEmailService } from '../core/services/send-email.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit {
  get isHainautOrNamurorNVN() {
    return projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege';
  }

  API_CREATE_ORDER = 'https://certinergiesystems.azurewebsites.net/api/NewOrdering/create';
  // paymentType: string;
  acteType: string;
  sendByMail: boolean;
  onlinePayment: boolean;
  remarks: string;
  paymentType: string;
  userZip: string;
  submitNotValid = false;
  visitAddress: Address;
  userType: UserType;
  gsmo: string;
  gsmu: string;
  phoneo: string;
  phoneu: string;
  step1IsValid: boolean;
  step2IsValid: boolean;
  step3IsValid: boolean;
  step4IsValid: boolean;

  products: Product[];
  hasPeb: boolean;
  hasElec: boolean;
  isUrgent: boolean;
  isInstallateur: boolean;

  displayAskForExpertise = displayAskForExpertise;
  order: IOrder;
  ui: IUI;

  userTypes = UserType;

  $paymentType: Observable<string>;
  $acteType: Observable<string>;
  $sendByMail: Observable<boolean>;
  $remarks: Observable<string>;
  $products: Observable<Product[]>;
  $IAgree: Observable<boolean>;
  $askForExpertise: Observable<boolean>;
  $askForOffer: Observable<boolean>;
  $selectedRegion: Observable<Region>;
  $estateName: Observable<string>;
  $estateType: Observable<number>;
  $visitAddress: Observable<Address>;
  $dateButoir: Observable<any>;
  $goGetKeys: Observable<boolean>;
  $userType: Observable<UserType>;
  $contact: Observable<Contact>;
  $owner: Observable<Owner>;
  $user: Observable<User>;
  $document: Observable<Document>;
  $nn: Observable<string>;
  $language: Observable<string>;
  $onlinePayment: Observable<boolean>;
  $hasPeb: Observable<boolean>;
  $hasElec: Observable<boolean>;
  $isUrgent: Observable<boolean>;
  $userZip: Observable<string>;
  $isInstallateur: Observable<boolean>;

  showFinalOptions = showFinalOptions;
  step4ShowByMail = step4ShowByMail;

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    private logger: LoggerService,
    private mailService: SendEmailService
  ) {
    this.sendByMail = true;
  }

  ngOnInit() {
    this.$paymentType = this.store.select(orderSelector.getPaymentType);
    this.$acteType = this.store.select(orderSelector.getActeType);
    this.$sendByMail = this.store.select(orderSelector.getSendByMail);
    this.$remarks = this.store.select(orderSelector.getRemarks);
    this.$products = this.store.select(orderSelector.getProducts);
    this.$IAgree = this.store.select(uiSelector.getIAgree);
    this.$askForExpertise = this.store.select(orderSelector.getAskForExpertise);
    this.$askForOffer = this.store.select(orderSelector.getAskForOffer);
    this.$selectedRegion = this.store.select(orderSelector.getRegionInfo);
    this.$estateName = this.store.select(orderSelector.getEstateName);
    this.$visitAddress = this.store.select(orderSelector.getAddress);
    this.$dateButoir = this.store.select(orderSelector.getDateButoir);
    this.$goGetKeys = this.store.select(orderSelector.getGoGeyKeys);
    this.$contact = this.store.select(orderSelector.getContact);
    this.$userType = this.store.select(orderSelector.getUserType);
    this.$owner = this.store.select(orderSelector.getOwner);
    this.$user = this.store.select(orderSelector.getUser);
    this.$document = this.store.select(orderSelector.getDocument);
    this.$nn = this.store.select(orderSelector.getNN);
    this.$language = this.store.select(uiSelector.getLanguage);
    this.$estateType = this.store.select(orderSelector.getEstateID);
    this.$onlinePayment = this.store.select(orderSelector.getOnlinePayment);
    this.$hasPeb = this.store.select(orderSelector.hasPeb);
    this.$hasElec = this.store.select(orderSelector.hasElec);
    this.$isUrgent = this.store.select(orderSelector.getUrgence);
    this.$userZip = this.store.select(orderSelector.getUserZip);

    this.$products.subscribe(p => (this.products = p));
    this.$hasPeb.subscribe(h => (this.hasPeb = h));
    this.$hasElec.subscribe(h => (this.hasElec = h));
    this.$isUrgent.subscribe(u => (this.isUrgent = u));
    this.$userZip.subscribe(uz => (this.userZip = uz));
    this.$userType.subscribe(ut => (this.userType = ut));
    this.$visitAddress.subscribe(ad => (this.visitAddress = ad));
    this.authService.isInstallateur.subscribe(inst => (this.isInstallateur = inst));

    this.$paymentType.subscribe(pt => (this.paymentType = pt));
    this.store.select(orderSelector.getOrder).subscribe(o => (this.order = o));
    this.store.select(uiSelector.getUI).subscribe(u => (this.ui = u));
    this.store.select(uiSelector.getStep1IsValid).subscribe(s => (this.step1IsValid = s));
    this.store.select(uiSelector.getStep2IsValid).subscribe(s => (this.step2IsValid = s));
    this.store.select(uiSelector.getStep3IsValid).subscribe(s => (this.step3IsValid = s));
    this.store.select(uiSelector.getStep4IsValid).subscribe(s => (this.step4IsValid = s));

    this.store.select(orderSelector.getRemarks).subscribe(string => (this.remarks = string));
    this.$isInstallateur = this.authService.isInstallateur;

    this.$onlinePayment.subscribe(online => {
      this.onlinePayment = online;
      if (online) {
        this.store.dispatch(new SetPaymentType('online'));
      }
    });

    this.$IAgree.subscribe(ia => {
      this.store.dispatch(new SetStep4Valid(ia));
    });
  }

  selectPayment(type: string) {
    if (type === 'online') {
      this.store.dispatch(new ToggleOnlinePayment(true));
    } else {
      this.store.dispatch(new ToggleOnlinePayment(false));
      this.store.dispatch(new SetPaymentType(type));
    }
  }

  selectActe(type: string) {
    this.store.dispatch(new SetActeType(type));
  }

  toggleSendByMail() {
    this.store.dispatch(new ToggleSendByMail(true));
  }

  toggleAskForExpertise(bool: boolean) {
    this.store.dispatch(new SetAskForExpertise(bool));
  }

  toggleAskForOffer(bool: boolean) {
    this.store.dispatch(new SetAskForOffer(bool));
  }

  SetRemarks(rem: string) {
    this.store.dispatch(new SetRemarks(rem));
  }

  toggleIAgree() {
    this.store.dispatch(new SetIAgree(true));
  }

  setNN(nn: string) {
    this.store.dispatch(new SetNN(nn));
  }

  checkIfProducts(order): boolean {
    const hasProdducts = order.OrderProducts.length > 0;
    const hasPrice = order.Invoice.TotalPrice > 0;
    if (hasProdducts && hasPrice) {
      return true;
    }
    return false;
  }

  finalizeOrder() {
    const orderIsValid = this.step1IsValid && this.step3IsValid && this.step4IsValid;

    // <-- NABIL reformat/map and clean order object before posting to Certinergie/NewOrder/api
    if (orderIsValid) {
      this.submitNotValid = false;
      const order = this.mapdOrderForDb();
      const hasProducts = this.checkIfProducts(order);

      if (!hasProducts) {
        this.dialog.open(DefaultDialogComponent, {
          panelClass: ['default-dialog'],
          data: {
            dialog: this.dialog,
            style: 'error'
          },
          disableClose: true,
          autoFocus: false
        });
      }

      this.logger.log(JSON.stringify(order));
      // NABIL Add link to Stripe payment with OrderID from success response
      this.http.post<any>(this.API_CREATE_ORDER, order).subscribe(
        val => {
          this.logger.log(`🚀`);
          // this.snackBar.open('🚀 Commande créée avec succès 🚀');
          const userDB: UserDB = val.Data;
          const orderID: string = val.Data.OrderID;
          // Emit eCommerce GA
          const purchaseItems: Partial<PurchaseEventItemParams>[] = order.OrderProducts.map(op => ({
            id: op.ProductTypeId.toString(),
            name: op.ProductName,
            price: parseInt(op.TotalTVAC, 10).toFixed(2)
          }));
          const purchaseParams: Partial<PurchaseEventParams> = {
            transaction_id: orderID,
            currency: 'EUR',
            affiliation: projectID,
            value: order.Invoice.TotalPrice,
            items: purchaseItems
          };
          this.googleAnalyticsService.emitPurchase(purchaseParams);
          this.googleAnalyticsService.emitConversionOrderFinalized(order.Invoice.TotalPrice, orderID);
          this.googleAnalyticsService.emitClick({
            eventCategory: 'Button',
            eventLabel: 'Order finalized'
          });

          const displayAgenda =
            !this.isUrgent &&
            (((this.hasPeb || this.hasElec) && this.products.length === 1) ||
              (this.hasPeb && this.hasElec && this.products.length === 2));

          const dialogRef = this.dialog.open(CongratsDialogComponent, {
            panelClass: ['congrats-dialog', 'no-padding-dialog'],
            data: {
              onlinePayment: this.onlinePayment,
              orderid: orderID,
              dialog: this.dialog,
              displayAgenda: displayAgenda
            },
            disableClose: true,
            autoFocus: false
          });

          dialogRef.afterClosed().subscribe(data => {
            this.authService.orderSuccessLogin(userDB.UserID, 2000, false);
          });
        },
        error => {
          this.logger.log('POST call in error', error);
          this.snackBar.open('Une erreur a eu lieu lors de la création de votre commande 😰');
        },
        () => {
          this.logger.log('The POST observable is now completed.');
        }
      );
    } else {
      this.submitNotValid = true;
      this.logger.log('😰 order not valid');
    }
  }

  mapdOrderForDb() {
    const o: IOrder = JSON.parse(JSON.stringify(this.order));
    //this.logger.log(JSON.stringify(o), 'raw');
    const u: IUI = JSON.parse(JSON.stringify(this.ui));
    const notaryProject =
      projectID === 'hainaut' || projectID === 'namur' || projectID === 'nvn' || projectID === 'liege';

    const packComm =
      this.dataService.packsDB.find(pa => pa.id === o.packID) &&
      this.dataService.packsDB.find(pa => pa.id === o.packID).commission;
    let commOrder = packComm || 0;
    let commTotal = packComm || 0;

    const hasDescUrba = o.products.find(p => p.id === PRODUCTS.DESCRIPTIF_URBA);

    let orderProducts = [...o.products]
      .filter(op => op.id !== 1001)
      .filter(op => op.id !== -2)
      .map(op => {
        if (op.id < 10000) {
          commOrder += op.commission;
        }
        commTotal += op.commission;
        const dbId = op.id === 11111 ? 1 : op.id === PRODUCTS.URGENT_PRO ? PRODUCTS.EXTRA : op.id;
        return {
          ProductTypeId: dbId,
          ProductName: op.id === PRODUCTS.DESCRIPTIF && hasDescUrba ? op.name + ' + Desc. Sommaire' : op.name,
          Description: o.estateType,
          TotalTVAC: op.price,
          Reduction: op.reduction,
          ContactName: o.goGetKeys ? '/' : o.contact.firstname,
          ContactSurname: o.goGetKeys ? '/' : o.contact.lastname,
          ContactType: o.contactChoice === 'other' ? 0 : o.contactChoice === 'keyAtAgency' ? 7 : 1,
          // tslint:disable-next-line:quotemark
          ContactPhone: o.goGetKeys ? "Clé à l'agence" : o.contact.phone,
          SmsNumber: o.goGetKeys ? '' : o.contact.gsm
        };
      });

    orderProducts =
      o.productsForAgency && o.productsForAgency.length > 0
        ? [...orderProducts].filter(pr => !o.productsForAgency.find(ap => ap.id === pr.ProductTypeId))
        : [...orderProducts];

    const orderProductsPro = [...o.productsForAgency]
      .filter(op => op.id !== 1001)
      .filter(op => op.id !== -2)
      .map(op => {
        if (op.id < 10000) {
          commOrder += op.commission;
        }
        commTotal += op.commission;
        return {
          ProductTypeId: op.id === 11111 ? 1 : op.id,
          ProductName: op.name,
          Description: o.estateType,
          TotalTVAC: op.price,
          Reduction: op.reduction,
          ContactName: o.goGetKeys ? '' : o.contact.firstname,
          ContactSurname: o.goGetKeys ? '' : o.contact.lastname,
          ContactType: o.contactChoice === 'other' ? 0 : o.contactChoice === 'keyAtAgency' ? 7 : 1,
          // tslint:disable-next-line:quotemark
          ContactPhone: o.goGetKeys ? '' : o.contact.phone,
          SmsNumber: o.goGetKeys ? '' : o.contact.gsm
        };
      });

    // no reductions if urgence
    let orderReductions = o.urgence
      ? null
      : o.reductions
          .filter(r => r.id !== 1001)
          .map(op => {
            return {
              TypeId: op.id,
              Description: op.type,
              Reduction: op.price
            };
          });

    let reducWithoutOR = 0;
    o.reductions.filter(r => r.id !== 1001).forEach(r => (reducWithoutOR = reducWithoutOR + +r.price));

    let immoRed = 0;
    o.reductions
      .filter(
        r =>
          r.id < 1000 &&
          r.id !== 1 &&
          r.id !== 11 &&
          r.id !== 4 &&
          r.id !== 8 &&
          r.id !== 6 &&
          r.id !== 9 &&
          r.id !== 15 &&
          r.id !== 16 &&
          r.id !== 51 &&
          r.id !== 55 &&
          r.id !== 56 &&
          r.id !== 57 &&
          r.id !== 17
      )
      .forEach(r => (immoRed = immoRed + +r.price));

    o.reduction = o.urgence ? 0 : reducWithoutOR;

    o.reduction = o.reduction - o.commission;

    // defaults non needed values
    if (o.paymentType !== 'acte') {
      o.nn = null;
      o.acteType = 'ILLPAY';
    }
    if (o.urgence) {
      orderReductions = null;
      o.promoCode = null;
    }

    let remarque = o.dateButoir
      ? 'Date Butoir : ' +
        (function(d) {
          const date = new Date(d);
          return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        })(o.dateButoir)
      : '';

    /* if (data.partielNumber !== '') {
    remarque =
      remarque + "\n" + "Numéro du partiel existant : " + data.partielNumber;
  } */

    if (o.pebNb && o.pebNb !== '') {
      remarque = remarque + '\n' + 'Numéro du PEB partiel existant : ' + o.pebNb;
    }

    if (o.nn != null) {
      // tslint:disable-next-line:quotemark
      remarque = remarque + '\n' + "Numéro national en cas d'acte : " + o.nn;
    }

    if (o.promoCode && o.promoCode !== '') {
      remarque = remarque + '\n' + 'codepromo:' + o.promoCode;
    }

    if (o.contacts && o.contacts.length) {
      let formattedContacts = '';
      o.contacts.forEach(
        co =>
          (formattedContacts =
            formattedContacts + `${co.description} | ${co.firstname} ${co.lastname} - ${co.phone} \n`)
      );
      remarque = remarque + '\n' + 'Contacts:' + '\n' + formattedContacts;
    }

    if (remarque !== '') {
      remarque = remarque + '\n' + '-----------------';
    }

    remarque = remarque + '\n' + o.remarks;

    const lan = u.language === 'nl' ? 1 : u.language === 'en' ? 2 : u.language === 'de' ? 3 : 0;
    let docName = '';

    if (o.document.firstName && o.document.lastName) {
      docName = o.document.firstName + ' ' + o.document.lastName;
    } else if (o.document.firstName) {
      docName = o.document.firstName;
    } else if (o.document.lastName) {
      docName = o.document.lastName;
    }

    if (this.userType === 1688) {
      this.gsmu = o.user.indicator + o.user.gsm.substring(1);
      this.phoneu = o.user.indicatorphone + o.user.phone.substring(1);
    } else {
      this.gsmo = o.owner.indicator + o.owner.gsm.substring(1);
      this.phoneo = o.owner.indicatorphone + o.owner.phone.substring(1);
    }
    const dbOrder = {
      Order: {
        EstateType: o.estateType,
        RegionLocation: o.regionType,
        OrderCommission: notaryProject ? commOrder : o.commission,
        NeedBDC: o.acteType === 'BDC' || o.user.userType === this.userTypes.Agence,
        SentByMail: !o.sendByMail,
        Language: lan,
        ImmoDiscount: immoRed,
        PromoCode: o.promoCode,
        GoGetKeys: o.goGetKeys,
        AddressKeyID: o.addressKeyID,
        PromoCodeID: o.promoCodeID,
        PackID: o.packID,
        OrderMeansType: orderMeansType
      },
      Invoice: {
        IsDeedPayment: o.paymentType === 'acte',
        TotalPrice: o.price - o.reduction,
        NotaryActeChoice: o.acteType === 'BDC' ? 1 : o.acteType === 'NN' ? 2 : 0
      },
      InvoiceConsumerType: o.invoiceTo === 'toPro' ? 0 : 1,
      AddressBien: {
        Name: null,
        Company: o.owner.companyname,
        VatNumber: o.owner.tva,
        Street: o.address.street,
        Number: o.address.number,
        PostalCode: isNaN(parseInt(o.address.zip, 10)) ? null : parseInt(o.address.zip, 10),
        City: o.address.city,
        Country: o.address.country ? o.address.country : 'Belgique'
      },
      AddressEnvois: {
        Name: docName,
        Company: o.document.companyName ? o.document.companyName : '',
        Street: o.document.address.street ? o.document.address.street : '',
        Number: o.document.address.number ? o.document.address.number : '',
        Latitude: o.document.address.lat,
        Longitude: o.document.address.lng,
        VatNumber:
          o.invoiceTo === 'toProprio' || o.invoiceTo === 'toProprioAndPro'
            ? ''
            : o.invoiceTo === 'toPro'
            ? o.user.tva
            : o.document.tva && o.document.tva !== ''
            ? o.document.tva
            : '',
        PostalCode: isNaN(parseInt(o.document.address.zip, 10)) ? null : parseInt(o.document.address.zip, 10),
        City: o.document.address.city,
        Country: o.document.address.country
      },
      OrderProducts: orderProducts.filter(op => op.ProductTypeId >= 0 && op.ProductTypeId < 10000),
      OrderReductions: orderReductions,
      OrderConsumer: {
        ConsumerType: o.user.userType,
        UserID: o.user.userid,
        User: {
          Email: o.user.email,
          FirstName: o.user.firstname,
          Name: o.user.lastname,
          Language: u.language === 'nl' ? 'nl-BE' : u.language === 'de' ? 'DE' : u.language === 'en' ? 'EN' : 'fr-BE',
          PhoneNumber: this.phoneu,
          TelNumber: this.gsmu,
          Status: o.user.status,
          Commission: '0'
        },
        AddressID: o.user.address.addressid,
        Address: {
          Name: null,
          Company: o.user.companyname,
          Street: o.user.address.street,
          Number: o.user.address.number,
          VatNumber: o.user.tva,
          Latitude: o.user.address.lat,
          Longitude: o.user.address.lng,
          PostalCode: isNaN(parseInt(o.user.address.zip, 10)) ? null : parseInt(o.user.address.zip, 10),
          City: o.user.address.city,
          Country: o.user.address.country ? o.user.address.country : 'Belgique'
        }
      },
      Owner: {
        Email: o.owner.email,
        FirstName: o.owner.firstname,
        LastName: o.owner.lastname,
        PhoneNumber: this.phoneo,
        Title: o.owner.title,
        TelNumber: this.gsmo,
        NationalNumber: null
      },
      Comment: { Type: 1, Commentaire: remarque },
      AvailableDates: [],
      OrderProductsForAgency: orderProductsPro,
      Urgent: o.urgence || o.urgencePro,
      SentByMailAgency: false,
      OrderProductsNotary: orderProducts
        .filter(
          op =>
            (op.ProductTypeId >= PRODUCTS.PublicationNotaireBE && op.ProductTypeId <= PRODUCTS.BidditFees) ||
            (op.ProductTypeId >= PRODUCTS.VISITADVALORIS && op.ProductTypeId <= PRODUCTS.LOCKSMITH) ||
            (op.ProductTypeId >= PRODUCTS.VisitGil4S && op.ProductTypeId <= PRODUCTS.VlanEditionLocal) ||
            op.ProductTypeId == PRODUCTS.BoostFbIns
        )
        .map(op => ({
          ...op,
          Status:
            (op.ProductTypeId >= PRODUCTS.PublicationVlan && op.ProductTypeId <= PRODUCTS.PublicationDeMorgen) ||
            (op.ProductTypeId >= PRODUCTS.PublicationAvenir &&
              op.ProductTypeId <= PRODUCTS.PublicationProximagArdennes) ||
            op.ProductTypeId == PRODUCTS.NotaireBeZimmo ||
            op.ProductTypeId == PRODUCTS.ZimmoOnline
              ? 1001
              : 1000,
          description: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType
        })),
      Publications: o.parutions.map(pub => ({ ...pub, saletype: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType })),
      PublicationLinks: o.parutionFileLinks,
      PublicationComment: o.parutionsComment,
      Visits: o.visits,
      VenteInfo: o.venteInfo,
      VenteType: o.venteType === VENTES.VENTE_PUB ? 3 : o.venteType,
      ProjectID: getPricesID,
      TotalCommission: commTotal,
      AskForExpertise: o.askForExpertise,
      NbrOfApartments: o.nbOfAppartments
    };

    return dbOrder;
  }

  get isEligibleUserForExpertise() {
    return (
      this.userType === this.userTypes.Particulier &&
      (this.userZip === '1070' ||
        this.visitAddress.zip === '1070' ||
        this.userZip === '1150' ||
        this.visitAddress.zip === '1150' ||
        this.userZip === '1200' ||
        this.visitAddress.zip === '1200' ||
        this.userZip === '1050' ||
        this.visitAddress.zip === '1050' ||
        this.userZip === '1040' ||
        this.visitAddress.zip === '1040')
    );
  }
}
