import { Injectable } from '@angular/core';
import { AppState } from '../../interfaces';
import { Store } from '@ngrx/store';
import * as orderSelector from '../store/order/order.selectors';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, delay, map, shareReplay } from 'rxjs/operators';
import { throwError, Observable, Subject, of } from 'rxjs';
import { User, UserDB, Role, CustomPrice, UserType } from '../models/user';
import { ResetOrderState, SetUserInfo, ResetUserInfo, SetToken } from '../store/order';
import { Router } from '@angular/router';
import { SetResetStepper, ResetUI, SetLoginError } from '../store/ui';
import { projectID } from '../data/theme-config';
import { environment } from 'src/environments/environment';
import { NotificationDto } from '../models/OrderDTO';
import { DataService } from './data.service';
import { OrderStatus } from '../enums/order-status.enum';
import { getToken } from '../store/order/order.selectors';
import { Address } from '../models/address';

// const loginURL = `https://systems.certinergie.be/api/LoginWeb/LoginUser`;
// const loginWithUserIDURL = `https://systems.certinergie.be/api/LoginWeb/LoginUserID`;
const emailCheckURL = `https://systems.certinergie.be/api/LoginWeb/CheckEmailAlreadyInUse`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  immoAccess: boolean;
  userId: string;
  userEmail: string;
  userName: string;
  public loginStatus = new Subject<number>();

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private router: Router,
    private dataService: DataService
  ) {
    this.store.select(orderSelector.getUserID).subscribe(u => (this.userId = u));
    this.store.select(orderSelector.getUserEmail).subscribe(u => (this.userEmail = u));
    this.store.select(orderSelector.getUserName).subscribe(u => (this.userName = u));
  }

  private isLoggedIn(): Observable<boolean> {
    return this.store.select(orderSelector.getIsLoggedIn);
  }

  public get isAgency(): Observable<boolean> {
    return this.store.select(orderSelector.getUserType).pipe(map(ut => ut === UserType.Agence));
  }

  public get isNotary(): Observable<boolean> {
    return this.store.select(orderSelector.getUserType).pipe(map(ut => ut === UserType.Notaire));
  }

  public get isInstallateur(): Observable<boolean> {
    return this.store.select(orderSelector.getUserType).pipe(map(ut => ut === UserType.Installateur));
  }

  public get isParticulier(): Observable<boolean> {
    return this.store.select(orderSelector.getUserType).pipe(map(ut => ut === UserType.Particulier));
  }

  public get isFromNVN(): Observable<boolean> {
    return this.store.select(orderSelector.getUserZip).pipe(map(code => +code >= 1000 && +code <= 1299));
  }

  public get isFromHainaut(): Observable<boolean> {
    return this.store
      .select(orderSelector.getUserZip)
      .pipe(map(code => (+code >= 6000 && +code <= 6599) || (+code >= 7000 && +code <= 7999)));
  }

  public get isFromNamur(): Observable<boolean> {
    return this.store.select(orderSelector.getUserZip).pipe(map(code => +code >= 5000 && +code <= 5999));
  }

  public login(email: string, password: string) {
    this.logoutNoRedirect();
    let logged = false;
    this.isLoggedIn().subscribe(bool => (logged = bool));

    if (logged) {
      return;
    }

    const httpParams = new HttpParams().append('login', email).append('password', password);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };

    this.http
      .post<any>(
        environment.CertiCore.loginUrl,
        {
          Email: email,
          Password: password
        },
        httpOptions
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(new SetLoginError(true));
          return this.handleError(err);
        })
      )
      .subscribe(data => {
        const userDB: UserDB = data.User;
        const token: string = data.Token;
        // error not handled here anymore
        // if user not exist or credential invalid api throw error (404 if user not valid & 401 if password not valid)
        const loginStatus = data.LoginStatus;
        this.loginStatus.next(loginStatus);

        // loginStatus is normally always 1 now

        if (loginStatus === 1) {
          if (projectID === 'greenfish' && userDB.Consumer.ConsumerType !== UserType.Particulier) {
            return;
          }
          if (
            (projectID === 'hainaut' || projectID === 'namur' || projectID === 'nvn' || projectID === 'liege') &&
            (userDB.Consumer.ConsumerType !== UserType.Particulier && userDB.Consumer.ConsumerType !== UserType.Notaire)
          ) {
            return;
          }
          const user = this.mapUserdbToUser(userDB);
          this.store.dispatch(new SetUserInfo(user));
          this.store.dispatch(new SetToken(token));
          this.store.dispatch(new SetLoginError(false));
        }
      });
  }

  public loginWithUserID(userid: string, logout = true, delayms = 0, redirectUrl?) {
    let logged = false;
    this.isLoggedIn().subscribe(bool => (logged = bool));

    if (!userid || (this.userId === userid && this.userEmail)) {
      return;
    }

    if (logout) {
      this.logoutNoRedirect();
    }
    // const httpParams = new HttpParams().append('UserID', userid);

    /* const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    }; */

    this.http
      .post<any>(environment.CertiCore.loginUrl + userid, null)
      .pipe(
        delay(delayms),
        catchError(this.handleError)
      )
      .subscribe(data => {
        const userDB: UserDB = data.User;
        const token: string = data.Token;

        const loginStatus = data.LoginStatus;
        this.loginStatus.next(loginStatus);

        if (loginStatus === 1) {
          const user = this.mapUserdbToUser(userDB);
          this.store.dispatch(new SetUserInfo(user));
          this.store.dispatch(new SetToken(token));

          if (redirectUrl) {
            this.navigateToSubUrl(redirectUrl);
          }
        }
      });
  }

  public refreshUserInfo() {
    this.store.select(getToken).subscribe(t => {
      if (!t) {
        return;
      }
      this.http
        .get<any>(environment.CertiCore.refreshUserInfo)
        .pipe(catchError(this.handleError))
        .subscribe(data => {
          const userDB: UserDB = data;
          const user = this.mapUserdbToUser(userDB);
          if (!user.ongoingOrders) {
            this.dataService.filterOptionsDefault.orderStatus = OrderStatus.LatestOrders;
          }
          this.store.dispatch(new SetUserInfo(user));
        });
    });
  }

  public refreshToken(userid: string) {
    this.http
      .post<any>(environment.CertiCore.refresToken + userid, null)
      .pipe(catchError(this.handleError))
      .subscribe(data => {
        const token: string = data.Token;
        this.store.dispatch(new SetToken(token));
      });
  }

  orderSuccessLogin(userid: string, delayms = 0, redirect = true) {
    this.logoutNoRedirect(redirect);
    this.loginWithUserID(userid, false, delayms);
  }

  public mapUserdbToUser(userDB: UserDB): User {
    const user = new User();

    user.userid = userDB.UserID;
    user.firstname = userDB.FirstName;
    user.lastname = userDB.Name;
    user.email = userDB.Email;
    user.tva = userDB.Address.VatNumber;
    user.phone = userDB.PhoneNumber;
    user.address.addressid = userDB.Address.AddressId;
    user.address.street = userDB.Address.Street;
    user.address.number = userDB.Address.Number;
    user.address.city = userDB.Address.City;
    user.address.zip = userDB.Address.PostalCode;

    user.sendByMail = !userDB.Consumer.ReceiveByEmail;
    user.userType = userDB.Consumer.ConsumerType;
    user.hasCommission = userDB.Consumer.Commission;
    user.pricingPlan = userDB.Consumer.Status;
    user.roles = userDB.Roles.map(r => new Role(r.RoleID, r.Name));
    user.notifications = userDB.Notifications;
    user.pendingOrders = userDB.PendingOrders;
    user.unpaidAmount = userDB.UnpaidAmount;
    user.unpaidCount = userDB.UnpaidCount;
    user.unsignedBdc = userDB.UnsignedBdc;
    user.pendingOrders = userDB.PendingOrders;
    user.unpaidCallbackAmount = userDB.UnpaidCallbackAmount;
    user.unpaidCallbackCount = userDB.UnpaidCallbackCount;
    user.yearlyOrdersCount = userDB.YearlyOrdersCount;
    user.ongoingOrders = userDB.OngoingOrders;
    user.newOrdersCount = userDB.NewOrdersCount;
    user.ordersCount = userDB.OrdersCount;
    user.latestOrdersCount = userDB.LatestOrdersCount;
    user.canceledOrdersCount = userDB.CanceledOrdersCount;
    user.suspensOrdersCount = userDB.SuspensOrdersCount;
    user.orderId = userDB.OrderID;
    user.consumerAddresses = userDB.ConsumerAddresses.map(
      c =>
        <Address>{
          addressid: c.AddressId,
          city: c.City,
          street: c.Street,
          zip: c.PostalCode,
          number: c.Number
        }
    );

    user.customPrices = userDB.Consumer.ConsumerPrices.map(
      c =>
        <CustomPrice>{
          price: c.ConsumerAmount,
          commission: c.ConsumerCommission,
          estateType: c.EstateType,
          region: c.ConsumerRegion,
          productTypeId: c.ProductTypeId
        }
    );

    return user;
  }

  public logout() {
    return this.logoutNoRedirect(true);
  }

  public logoutNoRedirect(redirect = false) {
    let logged;
    const sub = this.isLoggedIn().subscribe(bool => (logged = bool));

    this.store.dispatch(new ResetUserInfo(null));
    this.store.dispatch(new ResetOrderState(null));
    this.store.dispatch(new SetResetStepper(true));
    this.store.dispatch(new ResetUI(true));

    if (redirect) {
      this.router.navigate(['']);
    }
    sub.unsubscribe();
    return logged;
  }

  public fetchUserNotifications() {
    return this.http.get<NotificationDto[]>(environment.CertiCore.notifications).pipe(shareReplay(1));
  }

  isEmailAlreadyInUse(email: string) {
    if (!email || email.length < 3) {
      return of({});
    }

    const httpParams = new HttpParams().append('email', email);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };

    return this.http.post<any>(emailCheckURL, null, httpOptions).pipe(catchError(this.handleError));
  }

  navigateToSubUrl(subUrl) {
    this.router.navigate([subUrl], { queryParamsHandling: 'merge' });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
