const prodAPI = 'https://certiservices.azurewebsites.net/';
const localAPI = 'http://localhost:52646/';
const apiRef = prodAPI;
export const certiCoreBaseUrl = 'https://certicore.azurewebsites.net/api/v1/';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAC3LGik6S0kYASfaAYMbcVUz5pxXXOj0s',
    authDomain: 'certimmo-930d3.firebaseapp.com',
    databaseURL: 'https://certimmo-930d3.firebaseio.com',
    projectId: 'certimmo-930d3',
    storageBucket: 'certimmo-930d3.appspot.com',
    messagingSenderId: '12993987359',
    appId: '1:12993987359:web:d3ce9ea6979c978c5e662a',
    measurementId: 'G-Z0C2QXWZH5'
  },
  release: '#{COMMIT_ID}#',
  chargeFuncUrl:
    'https://stripefunc.azurewebsites.net/api/UpdateOrderToPaid?code=tfat6WggMQd8ZFKuHfHn4nlyG42SzcvQKb1Cfh0NIV/FNPFd9zvMog==',
  bancontactFuncUrl:
    'https://stripefunc.azurewebsites.net/api/BancontactCreateSource?code=KMZEaijasRttqJGqwvkAJ7qETuggAQB0v5arAz4b/KxpWHZxZlksyw==',
  sessionFunUrl:
    'https://stripefunc.azurewebsites.net/api/CreateCheckoutSession?code=RG55ECuilJsFTpIJXyOsrFTNq/mwsrsw4aGibe7bPLQu78Yk1PEhWw==',
  stripePublicKey: 'pk_live_RPnzJ2n7aIxtPjVP0zVoF9Z3',
  stripePublicKeyGreenfish: 'pk_live_k19XEZCDlk91w5cGmg2333HH00WzFL3USj',
  GA_MEASUREMENT_ID: 'UA-81810524-1',
  API: {
    SEND_BROCHURE: apiRef + 'api/notary/plaquette/sendmails'
  },
  CertiCore: {
    userOrdersUrl: certiCoreBaseUrl + 'espaceclient/users/me/orders/',
    loginUrl: certiCoreBaseUrl + 'espaceclient/auth/login/',
    refresToken: certiCoreBaseUrl + 'espaceclient/auth/refreshtoken/',
    orderInfo: certiCoreBaseUrl + 'espaceclient/orders/',
    publicOrderInfo: certiCoreBaseUrl + 'espaceclient/orders/anonymous/',
    askActeToTrue: certiCoreBaseUrl + 'espaceclient/orders/{orderId}/askacte',
    agreeToPay: certiCoreBaseUrl + 'espaceclient/orders/{orderId}/agreetopay',
    notifications: certiCoreBaseUrl + 'users/me/notifications',
    refreshUserInfo: certiCoreBaseUrl + 'espaceclient/auth/me/',
    deleteNotifications: certiCoreBaseUrl + 'users/me/notifications?orderId={orderId}',
    getElecAgents: certiCoreBaseUrl + 'espaceclient/users/employees/elec',
    checkVat: certiCoreBaseUrl + 'helpers/vat',
    agendaEmployees: certiCoreBaseUrl + 'espaceclient/users/employees/elec/',
    sendDocReminder: certiCoreBaseUrl + 'espaceclient/comments/rappel/',
    checkoutInfo: certiCoreBaseUrl + 'espaceclient/orders/{orderId}/checkout',
    clientComment: certiCoreBaseUrl + 'espaceclient/comments/public/'
  }
};
