import { Component, OnInit, Inject } from '@angular/core';
import { Paper } from 'src/app/core/models/paper';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/core/models/user';
import { Store } from '@ngrx/store';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AppState } from 'src/app/interfaces';
import { getUserInfo } from 'src/app/core/store/order/order.selectors';
import { projectID } from '../../core/data/theme-config';

@Component({
  selector: 'app-poster-request-dialog',
  templateUrl: './poster-request-dialog.component.html',
  styleUrls: ['./poster-request-dialog.component.scss']
})
export class PosterRequestDialogComponent implements OnInit {
  get isNamur() {
    return projectID === 'namur';
  }
  displayAdd = false;
  editing = false;

  sent = false;

  selectedIndex = 0;

  selectedPaper = {
    selectedFormat: 0,
    selectedColor: 0,
    quantity: 100
  };

  paperList: Paper[] = [];
  description = '';

  connectedUser: User;

  options = [
    { format: 'A2', colors: ['Blanc', 'Orange fluo', 'Vert fluo', 'Rose fluo', 'Jaune fluo'], price: 3.5 * 1.21 },
    {
      format: 'A3',
      colors: ['Blanc', 'Jaune', 'Orange', 'Rose', 'Vert', 'Jaune fluo', 'Orange fluo', 'Rose fluo', 'Vert fluo'],
      price: 1.75 * 1.21
    },
    {
      format: 'A4',
      colors: ['Blanc', 'Jaune', 'Orange', 'Rose', 'Vert', 'Jaune fluo', 'Orange fluo', 'Rose fluo', 'Vert fluo'],
      price: 0.75 * 1.21
    },
    { format: 'A5', colors: ['Ivoire'], price: 0.75 * 1.21 }
  ];

  forfaitPrice = 20 * 1.21;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private sendEmailService: SendEmailService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.store.select(getUserInfo).subscribe(u => (this.connectedUser = u));
  }

  displayAddPaper() {
    this.displayAdd = true;
  }

  backToList() {
    this.displayAdd = false;
    this.editing = false;
  }

  addPaper() {
    const selectedFormat = this.options[this.selectedPaper.selectedFormat].format;
    const selectedColor = this.options[this.selectedPaper.selectedFormat].colors[this.selectedPaper.selectedColor];
    const selectedUnitPrice = this.options[this.selectedPaper.selectedFormat].price;
    const selectedQuantity = this.selectedPaper.quantity;
    const newPaper = new Paper(selectedFormat, selectedColor, selectedQuantity, selectedUnitPrice);
    this.paperList = [...this.paperList];
    this.paperList.push(newPaper);
    this.displayAdd = false;
  }

  setFormat() {
    this.selectedPaper.selectedColor = 0;
  }

  deletePaper(index) {
    this.paperList = [...this.paperList];
    this.paperList.splice(index, 1);
  }

  editPaper(index) {
    this.selectedIndex = index;
    this.editing = true;
    this.displayAdd = true;
    this.selectedPaper.quantity = this.paperList[index].quantity;
    this.selectedPaper.selectedFormat = this.options.findIndex(o => o.format === this.paperList[index].format);
    this.selectedPaper.selectedColor = this.options[this.selectedPaper.selectedFormat].colors.findIndex(
      o => o === this.paperList[index].color
    );
  }

  saveEditPaper() {
    this.editing = false;
    this.displayAdd = false;
    const editedPaper = this.paperList[this.selectedIndex];

    const selectedFormat = this.options[this.selectedPaper.selectedFormat].format;
    const selectedColor = this.options[this.selectedPaper.selectedFormat].colors[this.selectedPaper.selectedColor];
    const selectedUnitPrice = this.options[this.selectedPaper.selectedFormat].price;
    const selectedQuantity = this.selectedPaper.quantity;

    editedPaper.color = selectedColor;
    editedPaper.format = selectedFormat;
    editedPaper.unitPrice = selectedUnitPrice;
    editedPaper.quantity = selectedQuantity;
    editedPaper.totalPrice = selectedUnitPrice * selectedQuantity;
  }

  cancel() {
    this.paperList = [];
    this.data.dialog.closeAll();
  }

  confirmPapers() {
    this.sendEmail();
  }

  sendEmail() {
    let totalPrice = 0;
    let formattedMsg = `
    <table>
      <tr>
        <th>Format</th>
        <th>Couleur</th>
        <th>Quantité</th>
        <th>Prix unitaire</th>
        <th>Prix</th>
      </tr>
    `;

    this.paperList.forEach(print => {
      totalPrice += print.totalPrice;
      formattedMsg += `
      <tr>
        <td>${print.format}</td>
        <td>${print.color}</td>
        <td>${print.quantity}</td>
        <td>${print.unitPrice.toFixed(2)} €</td>
        <td>${print.totalPrice.toFixed(2)} €</td>
      </tr>
    `;
    });

    totalPrice += totalPrice !== 0 ? this.forfaitPrice : 0;

    formattedMsg += `
    </table>
    <br/>
    Forfait de composition: ${this.forfaitPrice}€
    <br/>
    <strong>Prix total: ${totalPrice}</strong>
    <br/>
    <h4>Information sur le bien</h4>
    ${this.description}
    `;

    const result = Object.assign(
      {
        subject: 'Commande Affiche: ',
        message: formattedMsg
      },
      {
        email: this.connectedUser.email,
        firstName: this.connectedUser.firstname,
        lastName: this.connectedUser.lastname,
        phone: this.connectedUser.phone,
        address: this.connectedUser.address
      }
    );

    this.sendEmailService.sendNotaryRequest(result).subscribe(
      val => {
        this.logger.log(`🚀`);
        this.logger.log('Audit envoyé avec succès', val);
        this.sent = true;
      },
      response => {
        this.logger.log('😰 POST Audit error', response);
        this.sent = false;
      }
    );
  }
}
