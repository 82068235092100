import { Component, OnInit , Input  } from '@angular/core';

@Component({
  selector: 'app-comissions-current',
  templateUrl: './comissions-current.component.html',
  styleUrls: ['./comissions-current.component.scss']
})
export class ComissionsCurrentComponent implements OnInit {
  @Input() activeFilter;
  @Input() comissions;
  constructor() { }

  ngOnInit(): void {
  }

}
