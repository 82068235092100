export class Address {
  addressid: string;
  street: string;
  number: string;
  zip: string;
  city: string;
  lat?: string;
  lng?: string;
  country?: string;
  company?: string;

  constructor() {
    this.street = '';
    this.number = '';
    this.zip = '';
    this.city = '';
  }
}

export class AddressDB {
  AddressId: string;
  Street: string;
  Number: string;
  PostalCode: string;
  City: string;
  Latitude: string;
  Longitude: string;
  Country: string;
  VatNumber: string;
}
