import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app/interfaces';
import * as userSelector from '../store/order/order.selectors';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
    constructor(private store: Store<AppState>, private router: Router) {  }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canNavigate(state.url).pipe(tap(isLogged => {
            if(!isLogged) {
                this.router.navigateByUrl('');
            }
        }));
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canNavigate(state.url);
    }

    private canNavigate(path: string): Observable<boolean>  {
        return this.store.select(userSelector.getIsLoggedIn)
    }

}