<div class="uper-header">
    <div class="header-container">
        <a href="tel:025137818" class="link-phone">
            <fa-icon icon="phone"></fa-icon>
            <span>02 513 78 18</span>
        </a>
        <button class="btn btn-langage" [matMenuTriggerFor]="langageMenu">
      <span>{{ currentLang | uppercase }}</span>
      <fa-icon icon="angle-down"></fa-icon>
    </button>
        <mat-menu #langageMenu="matMenu" class="menu-langage">
            <button (click)="switchLanguage('fr')" mat-menu-item>FR</button>
            <button (click)="switchLanguage('nl')" mat-menu-item>NL</button>
            <button (click)="switchLanguage('en')" mat-menu-item>EN</button>
            <button (click)="switchLanguage('de')" mat-menu-item>DE</button>
        </mat-menu>
        <button *ngIf="!($isLoggedIn | async)" class="btn btn-login" (click)="openLoginDialog()">
      <fa-icon icon="user"></fa-icon>
      <span>{{ 'header.btn-login' | translate }}</span>
    </button>
        <button class="notification-btn" *ngIf="($userNotifications | async)?.length > 0" mat-icon-button color="danger" matBadge="{{ ($userNotifications | async)?.length }}" matBadgeSize="small" matBadgePosition="after" matBadgeOverlap="true" (click)="toNotifications()">
      <mat-icon id="notifications" svgIcon="notifications" aria-hidden="false" aria-label="notification"></mat-icon>
    </button>
        <button class="btn btn-connected" [matMenuTriggerFor]="userMenu" matTooltip="{{ $userEmail | async }}" matTooltipPosition="below" *ngIf="$isLoggedIn | async">
      <span class="ellipsis">{{ $userEmail | async }}</span>
      <img src="../../assets/images/user-connected-icon.svg" />
    </button>
        <mat-menu #userMenu="matMenu" xPosition="before">
            <a class="link-espace-client" [routerLink]="['/customer']">
                <fa-icon icon="briefcase"></fa-icon>
                <span>{{ 'header.espace-client' | translate }}</span></a>
            <button mat-menu-item (click)="logOut()" class="btn-disconnect mat-menu-icon">
        <fa-icon icon="power-off"></fa-icon>
        <span>{{ 'header.deconnection' | translate }}</span>
      </button>
        </mat-menu>
    </div>
</div>
<div class="bottom-header">
    <div class="header-container">
        <a (click)="toHomePage()" class="logo-link">
            <img class="small" src="../../../assets/images/nvn/nvn-logo-icon.png" alt="{{ 'header.link-certinergie-website' | translate }}" />
            <img class="complete" src="../../../assets/images/nvn/nvn-logo.png" alt="{{ 'header.link-certinergie-website' | translate }}" />
        </a>
        <div>
            <div class="nav-container" [ngClass]="open ? 'visible' : ''">
                <nav *ngIf="displayHeaderMenu" class="action-side">
                    <ng-container *ngFor="let key of filteredHeaderMenu">
                        <a *ngIf="!key.external; else externalLink" [routerLink]="key.link">
                            <fa-icon icon="arrow-right"></fa-icon>
                            {{ key.text }}
                        </a>
                        <ng-template #externalLink>
                            <a [href]="key.link" target="_blank">
                                <fa-icon icon="arrow-right"></fa-icon>
                                {{ key.text }}
                            </a>
                        </ng-template>
                    </ng-container>
                </nav>
                <div class="nav-footer">
                    <h6>{{ 'footer.section-help.title' | translate }}</h6>
                    <a href="mailto: info@certinergie.be">info@certinergie.be</a>
                    <a href="tel:080082171">0800 82 171 </a>
                    <a href="www.certinergie.be">www.certinergie.be</a>
                    <div class="social-link">
                        <a href="https://www.facebook.com/certinergie/" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Groupe_225" data-name="Groupe 225" transform="translate(-266.345 -80.114)">
                  <path
                    id="Tracé_10"
                    data-name="Tracé 10"
                    d="M278.345,104.114h0a12,12,0,0,1-12-12h0a12,12,0,0,1,12-12h0a12,12,0,0,1,12,12h0A12,12,0,0,1,278.345,104.114Z"
                    transform="translate(0 0)"
                    fill="#fff"
                    class="white-part"
                  />
                  <path
                    id="Tracé_11"
                    data-name="Tracé 11"
                    d="M293.763,108.556h3v-7.188h2.092l.223-2.407h-2.315V97.59c0-.568.119-.792.692-.792h1.623V94.3H297c-2.231,0-3.237.941-3.237,2.742v1.919H292.2V101.4h1.56Z"
                    transform="translate(-17.294 -9.314)"
                    fill="#336"
                  />
                </g>
              </svg>
                        </a>
                        <a href="https://twitter.com/Certinergie" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.094" height="24.094" viewBox="0 0 24.094 24.094">
                <g id="Groupe_228" data-name="Groupe 228" transform="translate(-57.467 -80.114)">
                  <path
                    id="Tracé_15"
                    data-name="Tracé 15"
                    d="M69.514,104.208h0A12.047,12.047,0,0,1,57.467,92.161h0A12.047,12.047,0,0,1,69.514,80.114h0A12.047,12.047,0,0,1,81.561,92.161h0A12.047,12.047,0,0,1,69.514,104.208Z"
                    fill="#fff"
                    class="white-part"
                  />
                  <path
                    id="Tracé_16"
                    data-name="Tracé 16"
                    d="M74.222,109.7a7.49,7.49,0,0,0,11.523-6.651,5.334,5.334,0,0,0,1.314-1.363,5.248,5.248,0,0,1-1.512.415,2.638,2.638,0,0,0,1.158-1.457,5.283,5.283,0,0,1-1.672.64,2.635,2.635,0,0,0-4.488,2.4,7.475,7.475,0,0,1-5.428-2.752,2.637,2.637,0,0,0,.815,3.517,2.631,2.631,0,0,1-1.193-.33,2.637,2.637,0,0,0,2.113,2.616,2.637,2.637,0,0,1-1.189.045,2.634,2.634,0,0,0,2.46,1.829A5.293,5.293,0,0,1,74.222,109.7Z"
                    transform="translate(-11.127 -13.504)"
                    fill="#336"
                  />
                </g>
              </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/1444134" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.094" height="24.094" viewBox="0 0 24.094 24.094">
                <g id="Groupe_231" data-name="Groupe 231" transform="translate(-162.5 -181.737)">
                  <path
                    id="Tracé_17"
                    data-name="Tracé 17"
                    d="M174.547,205.831h0A12.047,12.047,0,0,1,162.5,193.784h0a12.047,12.047,0,0,1,12.047-12.047h0a12.047,12.047,0,0,1,12.047,12.047h0A12.047,12.047,0,0,1,174.547,205.831Z"
                    fill="#fff"
                    class="white-part"
                  />
                  <g id="Groupe_230" data-name="Groupe 230" transform="translate(168.845 187.071)">
                    <g id="Groupe_229" data-name="Groupe 229" transform="translate(0 0)">
                      <rect
                        id="Rectangle_1425"
                        data-name="Rectangle 1425"
                        width="2.493"
                        height="8.055"
                        transform="translate(0.24 4.027)"
                        fill="#336"
                      />
                      <path
                        id="Tracé_18"
                        data-name="Tracé 18"
                        d="M182.862,200.589a1.486,1.486,0,1,0-1.474-1.486A1.481,1.481,0,0,0,182.862,200.589Z"
                        transform="translate(-181.388 -197.617)"
                        fill="#336"
                      />
                    </g>
                    <path
                      id="Tracé_19"
                      data-name="Tracé 19"
                      d="M196.66,212.946c0-1.132.521-1.807,1.519-1.807.917,0,1.357.648,1.357,1.807v4.228h2.482v-5.1c0-2.157-1.223-3.2-2.931-3.2a2.808,2.808,0,0,0-2.427,1.331V209.12h-2.392v8.054h2.392Z"
                      transform="translate(-189.941 -205.093)"
                      fill="#336"
                    />
                  </g>
                </g>
              </svg>
                        </a>
                    </div>
                </div>
            </div>
            <a (click)="toOrdering()" class="btn-primary link-devis">
        {{ 'header.devis-btn' | translate }}
      </a>
            <button class="burger" (click)="open = !open" [ngClass]="open ? 'active' : ''">
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
        </div>
    </div>
</div>