import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { certiCoreBaseUrl } from 'src/environments/environment';
import { AppState } from 'src/app/interfaces';
import { Store } from '@ngrx/store';
import { getToken } from '../store/order/order.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = sessionStorage.getItem('Token');
    let token = '';
    this.store.select(getToken).subscribe(t => (token = t));

    let cloned;
    const urlPath = req.url.toLowerCase();
    if (urlPath.startsWith(certiCoreBaseUrl) && !urlPath.includes('auth/login')) {
      if (token) {
        // console.log(req.url);
        cloned = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          })
        });
      }
    }

    const request = cloned ? cloned : req;
    return next.handle(request).pipe(
      /* catchError(err => {
        console.log(err);
        if (
          (err.status === 400 && err.statusText === 'Bad Request') ||
          (err.status === 401 && err.statusText === 'Unauthorized')
        ) {
          // window.location.href = 'https://www.certinergie.be/fr/utilisateur/se-connecter/';
        }
        return of(err);
      }), */
      finalize(() => {})
    );
  }
}
