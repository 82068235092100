import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './interfaces';
import { combineLatest } from 'rxjs';

import * as orderSelector from './core/store/order/order.selectors';
import * as uiSelector from './core/store/ui/ui.selectors';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { StartOnlineOfflineCheck, SetLanguage, SetIsMobile, SetIsTablette } from './core/store/ui';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';

import { MatIconRegistry } from '@angular/material/icon';

import { UpdateSwService } from './core/services/update-sw.service';
import { AuthService } from './core/services/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { projectID } from './core/data/theme-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(OrderSteppersComponent, { static: false }) OrderSteppersComponent: OrderSteppersComponent;

  constructor(
    private store: Store<AppState>,
    public breakpointObserver: BreakpointObserver,
    translate: TranslateService,
    private sw: UpdateSwService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    translate.setDefaultLang('fr');
    this.store.select(uiSelector.getLanguage).subscribe(ln => translate.use(ln));
    this.iconRegistry.addSvgIcon(
      'notifications',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notifications.svg')
    );
  }

  ngOnInit() {
    this.store.dispatch(new StartOnlineOfflineCheck());
    // check the service worker for updates
    this.sw.checkForUpdates();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(event => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
        this.titleService.setTitle(this.getWebsiteTitle() + (title === '' ? '' : ' | ' + title));
      });

    this.breakpointObserver.observe(['(min-width: 576px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.store.dispatch(new SetIsMobile(false));
      } else {
        this.store.dispatch(new SetIsMobile(true));
      }
    });

    this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.store.dispatch(new SetIsTablette(false));
      } else {
        this.store.dispatch(new SetIsTablette(true));
      }
    });

    combineLatest(
      this.store.select(orderSelector.getUserID),
      this.store.select(orderSelector.getUserEmail),
      (uid, email) => ({
        uid,
        email
      })
    ).subscribe(({ uid, email }) => {
      if (uid && uid !== '' && email === '') {
        this.authService.loginWithUserID(uid);
      }

    });

    this.store.select(orderSelector.getIsLoggedIn).subscribe(logged => {
      if (logged) {
        this.authService.refreshUserInfo();
      }
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParamMap.subscribe(params => {
      let userid = params.get('u') ? params.get('u') : null;
      const redirectUrl = params.get('redirect') ? params.get('redirect') : null;
      const lan = params.get('ln') ? params.get('ln') : null;

      if (userid) {
        this.authService.loginWithUserID(userid, true, 0, redirectUrl);
      } else {
        this.store.select(orderSelector.getUserID).subscribe(uid => (userid = uid));
        if (userid) {
          this.authService.refreshToken(userid);
        }
      }
      if (redirectUrl) {
        this.authService.navigateToSubUrl(redirectUrl);
      }

      if (lan === 'fr' || lan === 'nl' || lan === 'de' || lan === 'en') {
        this.store.dispatch(new SetLanguage(lan as 'fr' | 'nl' | 'de' | 'en'));
      }
    });

    const ieIE = this.navigatorIsIE();

    if (ieIE) {
      alert(
        'Our website is not compatible with Internet Explore, please use any other modern browser (Chrome, Firefox, EDGE, Safari,...)'
      );
    }
  }

  connectUserId(userid: string) {
    this.authService.loginWithUserID(userid);
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  getWebsiteTitle(): string {
    return projectID === 'greenfish'
      ? 'Greenfish'
      : projectID === 'hainaut'
      ? 'MDNH'
      : projectID === 'namur'
      ? 'MDNN'
      : projectID === 'liege'
      ? 'MDNL'
      : projectID === 'nvn'
      ? 'NVN'
      : 'Certinergie';
  }

  navigatorIsIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }
}
