import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stats-main',
  templateUrl: './stats-main.component.html',
  styleUrls: ['./stats-main.component.scss']
})
export class StatsMainComponent implements OnInit {
  @Input() stats;
  isActive = false;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.stats)
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    // console.log(year, month)
    let array = []
    let first = this.stats.MonthlyStatistics[0]
    let loop = first.Month
    for (let index = first.Year; index <= year; index++) {
      for (let j = loop; j <= 12; j++) {
        let found = this.stats.MonthlyStatistics.find(element => element.Month == j && element.Year == index)
        if (found) {
          array.push({
            "name": found.Month + "/" + index,
            "value": found.OrderAmount
          })
        } else {
          array.push({
            "name": j + "/" + index,
            "value": 0
          })
        }
      }
      loop = 1
    }
    console.log(array)
    array = array.slice(0, month + -12)
    let total = 0
    if (array.length >= 12) {
      array.slice(Math.max(array.length - 12, 0)).forEach(element => {
        total += element.value
      })
      if (total / 12 > 1) {
        this.isActive = true
        this.stats.last12Year = total
      } else {
        this.stats.last12Year = total
        this.isActive = false
      }
    }
    else {
      array.forEach(element => {
        total += element.value
      })
      if (total / array.length > 1) {
        this.stats.last12Year = total
        this.isActive = true
      } else {
        this.stats.last12Year = total
        this.isActive = false
      }
    }
  }

}
