import { Component, OnInit, Inject } from '@angular/core';
import { Panel } from 'src/app/core/models/paper';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/core/models/user';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces';
import { getUserInfo, getPanels, getPanelsInfo } from 'src/app/core/store/order/order.selectors';
import { SetPanels, SetPanelsInfo } from 'src/app/core/store/order';
import { AuthService } from 'src/app/core/services/auth.service';
import { projectID } from '../../core/data/theme-config';
import { SendEmailService } from 'src/app/core/services/send-email.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { PanelXlRequestDialogComponent } from '../panel-xl-request-dialog/panel-xl-request-dialog.component';

@Component({
  selector: 'app-panel-request-dialog',
  templateUrl: './panel-request-dialog.component.html',
  styleUrls: ['./panel-request-dialog.component.scss']
})
export class PanelRequestDialogComponent implements OnInit {
  displayAdd = true;
  editing = false;

  sent = false;

  isCertinergie = projectID === 'default';

  selectedIndex = 0;

  selectedPanel = {
    selectedFormat: 0,
    selectedSaleType: 0,
    selectedLanguage: 0,
    quantity: 0
  };

  panelList: Panel[] = [];
  description = '';

  connectedUser: User;

  quantityOptions = [10, 25, 50, 100];
  quantityOptionsExt =
    this.selectedPanel.selectedFormat === 0
      ? [{ price: 28, amount: 10 }, { price: 24, amount: 25 }, { price: 18, amount: 50 }, { price: 12, amount: 100 }]
      : [{ price: 46, amount: 10 }, { price: 28, amount: 25 }, { price: 22, amount: 50 }, { price: 16, amount: 100 }];
  saleTypeOptions = ['VP', 'VGG', 'VB'];
  languageOptions = ['FR', 'NL', 'FR & NL'];

  formatOptions = ['Standard (70 x 100cm en "V")', 'XL (100 x 140cm  en "V")', 'Sur mesure'];

  public get fraisMDN(): number {
    return projectID === 'default' ? 0 : projectID === 'nvn' ? 100 : 25;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { dialog: MatDialog },
    private store: Store<AppState>,
    private sendEmailService: SendEmailService,
    private logger: LoggerService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.store.select(getUserInfo).subscribe(u => (this.connectedUser = u));
    this.authService.isAgency.subscribe(
      ag => (this.saleTypeOptions = ag ? ['Vente', 'Location'] : ['VP', 'VGG', 'VB'])
    );
    this.store.select(getPanels).subscribe(u => (this.panelList = JSON.parse(JSON.stringify(u))));
    this.store.select(getPanelsInfo).subscribe(d => (this.description = d));
  }

  displayAddPanel() {
    this.displayAdd = true;
  }

  backToList() {
    this.displayAdd = false;
    this.editing = false;
  }

  addPanel() {
    const selectedFormat = this.formatOptions[this.selectedPanel.selectedFormat];
    const selectedSaleType = this.saleTypeOptions[this.selectedPanel.selectedSaleType];
    const selectedQuantity = this.quantityOptions[this.selectedPanel.quantity];
    const selectedUnitPrice =
      Math.round(
        (this.quantityOptionsExt.find(q => q.amount === selectedQuantity).price * 1.21 + Number.EPSILON) * 100
      ) / 100;
    const selectedLanguage = this.languageOptions[this.selectedPanel.selectedLanguage];
    const newPanel = new Panel(selectedFormat, selectedSaleType, selectedQuantity, selectedUnitPrice, selectedLanguage);
    this.panelList = [...this.panelList];
    console.log(newPanel);
    this.panelList.push(newPanel);
    this.selectedPanel = {
      selectedFormat: 0,
      selectedSaleType: 0,
      selectedLanguage: 0,
      quantity: 0
    };
    this.displayAdd = false;
  }

  setFormat() {
    this.selectedPanel.selectedSaleType = 0;
  }

  deletePanel(index) {
    this.panelList = [...this.panelList];
    this.panelList.splice(index, 1);
  }

  editPanel(panel: Panel, index: number) {
    this.selectedIndex = index;
    this.selectedPanel.quantity = this.quantityOptions.findIndex(o => o === panel.quantity);
    this.selectedPanel.selectedFormat = this.formatOptions.findIndex(o => o === panel.format);
    this.selectedPanel.selectedSaleType = this.saleTypeOptions.findIndex(o => o === panel.saleType);
    this.editing = true;
    this.displayAdd = true;
  }

  saveEditPanel() {
    this.editing = false;
    this.displayAdd = false;
    const editedPaper = this.panelList[this.selectedIndex];
    const selectedFormat = this.formatOptions[this.selectedPanel.selectedFormat];
    const selectedSaleType = this.saleTypeOptions[this.selectedPanel.selectedSaleType];
    const selectedUnitPrice = this.quantityOptionsExt[this.selectedPanel.selectedFormat].price;
    const selectedQuantity = this.quantityOptions[this.selectedPanel.quantity];
    const selectedLanguage = this.languageOptions[this.selectedPanel.selectedLanguage];

    editedPaper.saleType = selectedSaleType;
    editedPaper.format = selectedFormat;
    editedPaper.unitPrice = selectedUnitPrice;
    editedPaper.quantity = selectedQuantity;
    editedPaper.language = selectedLanguage;
    editedPaper.totalPrice = selectedUnitPrice * selectedQuantity;
  }

  cancel() {
    this.panelList = [];
    this.data.dialog.closeAll();
  }

  confirmPanels() {
    /* this.store.dispatch(new SetPanels(this.panelList));
    this.store.dispatch(new SetPanelsInfo(this.description));
    this.data.dialog.closeAll(); */

    const fees =
      projectID === 'default'
        ? ''
        : `<br/>
    Frais de livraison: ${this.fraisMDN * 1.21} €`;

    let totalPrice = this.fraisMDN * 1.21;
    let formattedMsg = `
    <table>
      <tr>
        <th>Format</th>
        <th>Type de vente</th>
        <th>Langue</th>
        <th>Quantité</th>
        <th>TVAC</th>
        <th>Prix</th>
      </tr>
    `;

    this.panelList.forEach(panel => {
      totalPrice += panel.totalPrice;
      formattedMsg += `
      <tr>
        <td>${panel.format}</td>
        <td>${panel.saleType}</td>
        <td>${panel.language}</td>
        <td>${panel.quantity}</td>
        <td>${panel.unitPrice.toFixed(2)} €</td>
        <td>${panel.totalPrice.toFixed(2)} €</td>
      </tr>
    `;
    });

    formattedMsg += `
    </table>
    ${fees}
    <br/>
    <strong>Prix total: ${totalPrice} €</strong>
    <br/>
    <h4>Information: </h4>
    ${this.description}
    `;

    const result = {
      email: this.connectedUser.email,
      userId: this.connectedUser.userid,
      firstName: this.connectedUser.firstname,
      lastName: this.connectedUser.lastname,
      subject: 'Commande panneaux',
      message: formattedMsg
    };

    this.sendEmailService.sendRequest(result).subscribe(
      val => {
        this.logger.log(`🚀`);
        this.logger.log('Commande panneaux envoyé avec succès', val);
        this.sent = true;
      },
      response => {
        this.logger.log('😰 POST Commande panneaux error', response);
        this.sent = false;
      }
    );
  }
  panelSelect() {
    if (this.selectedPanel.selectedFormat === 2) {
      this.dialog.open(PanelXlRequestDialogComponent, {
        panelClass: ['specific-request-dialog'],
        data: {
          dialog: this.data.dialog
        }
      });
      return;
    }
    this.quantityOptionsExt =
      this.selectedPanel.selectedFormat === 0
        ? [
            { price: 28, amount: 10 },
            { price: 24, amount: 25 },
            { price: 18, amount: 50 },
            { price: 12, amount: 100 }
          ]
        : [
            { price: 33 * 1.4, amount: 10 },
            { price: 20.4 * 1.4, amount: 25 },
            { price: 15.9 * 1.4, amount: 50 },
            { price: 11.55 * 1.4, amount: 100 }
          ];
  }
}
