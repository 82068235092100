<div class="container">
  <fa-icon icon="thumbs-up"></fa-icon>
  <div class="content">
    <p>
      {{ 'snackbar-pack-selected.title' | translate: param }}
      <!-- Le pack {{ packName }} a été sélectionné pour vous. -->
    </p>
    <p [innerHTML]="'snackbar-pack-selected.content' | translate: param"></p>
  </div>
</div>
